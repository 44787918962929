import React, { useEffect, useContext } from "react";
import { MainContext } from "../../contexts/MainContext.js";
import { Link, useLocation } from "react-router-dom";
import { Typography, Box, Button } from "@mui/material";
import DarkModeSwitch from "../buttons/DarkModeSwitch.js";

import styled from "styled-components";

const StyledBottomMenu = styled.nav`
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 56px);
  width: 100%;
  top: 56px;
  left: 0;
  text-align: left;
  padding: 1rem 2rem;
  background: rgba(196, 196, 196, 0.5);
  background: ${({ open }) =>
    open ? "rgba(196, 196, 196, 0.5);" : "transparent"};
  backdrop-filter: ${({ open }) => (open ? "blur(4px)" : "none")};
  z-index: ${({ open }) => (open ? "998" : "-1")};

  .bottom-menu-background {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    height: 300px;
    width: 100%;
    top: 100vh;
    left: 0;
    padding: 24px;
    border-radius: 4px 4px 0 0;
    background: ${({ theme }) => theme.backgroundLight};
    /* Set how much of the screen is covered by menu overlay */
    transform: ${({ open }) => (open ? "translateY(-412px)" : "translateY(0)")};
    transition: transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .-ms-text-underline-position .active-mobile {
    color: ${({ theme }) => theme.primary};
  }

  a {
    font-size: 1.125rem;
    font-family: ${({ theme }) => theme.fontPrimary};
    font-weight: 600;
    text-transform: none;
    margin: 10px 0 0 0;
    color: ${({ theme }) => theme.textDark};
    text-decoration: none;
    text-align: right;
    transition: color 0.3s linear;

    @media (max-width: ${({ theme }) => theme.mobile}) {
      font-size: 1.3rem;
      text-align: right;
    }

    &:hover {
      color: ${({ theme }) => theme.primary};
    }
  }

  .MuiButton-label {
    justify-content: flex-end;
  }

  .MuiButtonBase-root {
    font-size: 2rem;
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 1rem 0;
    letter-spacing: 0.5rem;
    color: ${({ theme }) => theme.primaryDark};
    text-decoration: none;
    text-align: right;
    transition: color 0.3s linear;

    @media (max-width: ${({ theme }) => theme.mobile}) {
      font-size: 1.25rem;
      text-align: right;
    }

    &:hover {
      color: ${({ theme }) => theme.primaryHover};
      background-color: transparent;
    }

    .li-container {
      display: flex;
      justify-content: center;
      align-items: center;
      max-height: 28px;
    }
  }
`;

const BottomMenu = (props) => {
  let location = useLocation();
  const { mainMenuOpen, setMainMenuOpen } = useContext(MainContext);

  return (
    <StyledBottomMenu
      open={mainMenuOpen}
      onClick={() => setMainMenuOpen(!mainMenuOpen)}
    >
      <div className="bottom-menu-background">
        {/* {props.menuItems.map((item, i) =>
          item.showMobile ? (
            <Link
              className={`link ${
                location.pathname === item.to ? "active-mobile" : null
              }`}
              to={item.to}
              onClick={() => setMainMenuOpen(!mainMenuOpen)}
            >
              {item.name}
            </Link>
          ) : null
        )} */}
        <Link
          className={`link`}
          target="_blank"
          to={{ pathname: "https://gth.com.au/off-hire-request/" }}
          onClick={() => setMainMenuOpen(!mainMenuOpen)}
        >
          Off-Hire Request
        </Link>
        <Link
          className={`link`}
          target="_blank"
          to={{ pathname: "https://gth.com.au/stand-down-request/" }}
          onClick={() => setMainMenuOpen(!mainMenuOpen)}
        >
          Stand Down Request
        </Link>
        <Link
          className={`link`}
          target="_blank"
          to={{ pathname: "https://gth.com.au/customer-credit-request/" }}
          onClick={() => setMainMenuOpen(!mainMenuOpen)}
        >
          Credit Request
        </Link>
      </div>
    </StyledBottomMenu>
  );
};
export default BottomMenu;
