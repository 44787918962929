import React, { useContext } from "react";
import { MainContext } from "../contexts/MainContext.js";
import ProductsList from "./ProductsList";
import Backdrop from "@mui/material/Backdrop";

import { motion } from "framer-motion";
import {
  pageVariantsModal,
  modalTransition,
  pageVariantsModalBG
} from "../transitions";

import Modal from "@mui/material/Modal";
import CloseButton from "./buttons/CloseButton";

import styled from "styled-components";

const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  & :focus {
    outline: none;
  }
  .modal-content-area {
    position: relative;
    height: ${(props) => (props.isDesktop ? "auto" : "100%")};
    max-height: ${(props) => (props.isDesktop ? "80vh" : "100%")};
    width: ${(props) => (props.isDesktop ? "480px" : "100%")};
    font-family: ${(props) => props.theme.fontPrimary};
    color: ${(props) => props.theme.textDark};
    border-radius: ${(props) => (props.isDesktop ? "4px" : "none")};
    transition: ${(props) => props.theme.allTransition};
    background-color: ${(props) => props.theme.backgroundGrey50};
    overflow-x: hidden;
  }
  .close-button-container {
  }
  .title-and-close {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & .MuiButtonBase-root .MuiIconButton-root {
    margin: 0;
  }
  .main-content {
    padding: ${(props) => (props.isDesktop ? " 8px 16px 24px" : "0")};
  }
  #simple-modal-title {
    margin: ${(props) => (props.isDesktop ? "0 8px" : "0 16px")};
  }
`;

export default function OptionalExtrasModal({ open, setOpen, modelId }) {
  const { isDesktop } = useContext(MainContext);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariantsModalBG}
      transition={modalTransition}
    >
      <StyledModal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        isDesktop={isDesktop}
        BackdropComponent={Backdrop}
      >
        <motion.div
          className="modal-content-area"
          initial="initial"
          animate="in"
          exit="out"
          variants={pageVariantsModal}
          transition={modalTransition}
        >
          <div className="main-content">
            <div className="title-and-close">
              <h2 id="simple-modal-title">Add optional extras below:</h2>
              <CloseButton onClick={handleClose} />
            </div>
            {/* subcategory prop below is the model */}
            <ProductsList
              category="optional_extras"
              subcategory={modelId}
              modelId={modelId}
              closeModal={handleClose}
            />
          </div>
        </motion.div>
      </StyledModal>
    </motion.div>
  );
}
