import React, { useState, useEffect, useContext } from "react";
import { MainContext } from "../contexts/MainContext";

import {
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";
import BookingSummaryList from "../components/BookingSummaryList";
import CartItemCard from "../components/CartItemCard";
import QuickQuoteDetailsSection from "../components/QuickQuoteDetailsSection";
import { useCart } from "react-use-cart";

import Spacer from "../components/Spacer";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ButtonLarge from "../components/buttons/ButtonLarge";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";

import { Helmet } from "react-helmet";

import { motion } from "framer-motion";
import { pageVariantsProgression, pageTransition } from "../transitions";

import styled from "styled-components";
import BackButton from "../components/buttons/BackButton";

const PageContainer = styled.div`
  position: relative;
  width: ${(props) => (props.isDesktop ? "480px" : "100%")};  
  height: 100%;
  margin: 0 auto;
  text-align: left;
  font-family: ${(props) => props.theme.fontPrimary};
  color: ${(props) => props.theme.textDark};
  /* background-color: ${(props) => props.theme.backgroundGrey50}; */
  background-color: ${(props) => props.theme.backgroundGrey50};
  transition: ${(props) => props.theme.allTransition};
  .content {
    height: 100%;
    padding: 12px 8px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .booking-card{
    background-color: ${(props) => props.theme.backgroundLight};
    padding: 6px 0 0 0;
    border-radius: 4px;  
    box-shadow: ${(props) => props.theme.cardBoxShadow};
  }
  .title-edit-row{
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%; 
    padding: 8px 16px;
  }
  .edit-cart-button{
    color: ${(props) => props.theme.grey900};;
  }
  .button-container{
    padding: 0 24px 24px;
  }

`;

export default function CheckoutPageQuickQuote(props) {
  const { isDesktop } = useContext(MainContext);
  let { path, url } = useRouteMatch();
  const { subcategory, filter } = useParams();
  const {
    isEmpty,
    cartTotal,
    totalUniqueItems,
    items,
    updateItemQuantity,
    removeItem,
    emptyCart
  } = useCart();

  // const CheckForExtras = (cartItem) => {
  //   return items.map((item) => (item.type === "optional_extras" ? item : null));
  // };

  return (
    <motion.div
      className="page-animation-div"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariantsProgression}
      transition={pageTransition}
    >
      <Helmet>
        <title>{`Confirm Quote Details | GTH Equipment`}</title>
      </Helmet>
      <PageContainer isDesktop={isDesktop}>
        <div className="content">
          <div className="booking-card">
            {isDesktop ? <div className="title-edit-row">
              <BackButton />
              <h2>Get a Quick Quote:</h2>

            </div>
              : null}
            {items.map((item, i) =>
              item.type !== "optional_extras" ? (
                <BookingSummaryList item={item} index={i} />
              ) : null
            )}
            {/* {items.map((item) =>
          item.type !== "optional_extras" ? <CartItemCard item={item} /> : null
        )} */}
          </div>
        </div>
        <div className="content">
          <QuickQuoteDetailsSection />
        </div>
        <div className="button-container">
          {/* <ButtonLarge text="Submit Booking" endIcon={<SendIcon />} /> */}
          {/* <ButtonLarge text="Log Cart" onClick={() => console.log(items)} /> */}

          <Spacer height="56px" />
        </div>
      </PageContainer>
    </motion.div >
  );
}
