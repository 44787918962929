import React from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import styled from "styled-components";

const StyledButton = styled(Button)`
  width: fit-content;
  max-width: 380px;
  height: 48px;
  background-color: ${(props) => props.theme.white};
  border-bottom: ${(props) =>
    props.value === props.category
      ? `solid 4px ${props.theme.primary}`
      : "none"};
  border-radius: 0;
  box-shadow: none;
  margin: 0px;
  padding: 20px; 
  font-family: ${(props) => props.theme.fontPrimary};
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => props.theme.grey900};
  white-space: nowrap;
  transform: scale(1);
  transition: 0.15s all ease-out;
  .MuiButton-label {
    text-decoration: none;
  }
  &:hover {
    background-color: ${(props) =>
      props.value === props.category
        ? props.theme.white
        : props.theme.primaryHover};
    /* border-bottom: ${(props) => `solid 4px ${props.theme.primary}`}; */
    box-shadow: none;
  }
  &:active {
    border-radius: 4px;
    transform: scale(1.05);
    transition: 0.1s all ease-out;
  }
`;

export default function ProductFamilyNavButton(props) {
  return (
    <StyledButton
      className="button-large"
      variant="contained"
      onClick={props.onClick}
      component={Link}
      to={`/${props.value}/all`}
      margin={props.margin ? props.margin : "16px 0 0 0"}
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      varient={props.varient} //dark or primary
      value={props.value}
      category={props.category}
    >
      {props.text}
    </StyledButton>
  );
}
