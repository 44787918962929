export const pageVariantsTopLevel = {
  initial: {
    opacity: 0,
    scale: 0.92
  },
  in: {
    opacity: 1,
    scale: 1
  },
  out: {
    opacity: 0,
    scale: 1
  }
};

export const pageVariantsSiblings = {
  initial: {
    opacity: 0,
    scale: 0.92
  },
  in: {
    opacity: 1,
    scale: 1
  },
  out: {
    opacity: 0,
    scale: 1
  }
};

export const pageVariantsProgression = {
  initial: {
    opacity: 0,
    x: 100,
    scale: 1
  },
  in: {
    opacity: 1,
    x: 0,
    scale: 1
  },
  out: {
    opacity: 0,
    x: -100,
    scale: 1
  }
};

export const pageVariantsModal = {
  initial: {
    y: "80vh",
    opacity: 1
  },
  in: {
    y: 0,
    opacity: 1
  },
  out: {
    y: 100,
    opacity: 0
  }
};

export const pageVariantsModalBG = {
  initial: {
    opacity: 0
  },
  in: {
    opacity: 1
  },
  out: {
    opacity: 0
  }
};

export const pageVariantsCardTranslate = {
  initial: {
    y: -100,
    opacity: 0
  },
  in: {
    y: 0,
    opacity: 1
  },
  out: {
    y: -100,
    opacity: 0
  }
};

export const textVariants = {
  initial: {
    y: -100,
    opacity: 0
  },
  in: {
    y: 0,
    opacity: 1
  },
  out: {
    y: -100,
    opacity: 0
  }
};

export const textTransition = {
  // duration: 3
  // transition: "linear"
  // type: "spring",
  type: "tween",
  // stiffness: 1000
  // ease: "linear"
  // ease: "easeIn"
  // ease: "circInOut",
  ease: [0, 0, 0.2, 1],
  duration: 0.2
};

export const pageTransition = {
  // duration: 3
  // transition: "linear"
  // type: "spring",
  type: "tween",
  // stiffness: 1000
  // ease: "linear"
  // ease: "easeIn"
  // ease: "circInOut",
  ease: [0, 0, 0.2, 1],
  duration: 0.2
};

export const modalTransition = {
  // duration: 3
  // transition: "linear"
  // type: "spring",
  type: "tween",
  // stiffness: 1000
  //ease: "linear",
  //ease: "easeIn",
  //ease: "circInOut",
  ease: "easeOut",
  duration: 0.05
};

export const cardTransition = {
  staggerChildren: 0.15,
  type: "tween",
  ease: [0.25, 0.46, 0.45, 0.94],
  duration: 0.02
};

// Staggered Product Items Animations
export const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      delay: 0,
      staggerChildren: 0.1,
      type: "tween",
      ease: "easeOut"
    }
  }
};

export const listItem = {
  hidden: { scale: 0.9, opacity: 0 },
  show: {
    scale: 1,
    opacity: 1,
    transition: {
      type: "tween",
      duration: 0.4,
      ease: "easeOut"
    }
  }
};
