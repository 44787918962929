import React, { useState, useEffect } from "react";
import { useParams, useRouteMatch, Link } from "react-router-dom";
import ProductFamilyNav from "../components/ProductFamilyNav";
import ProductFamilyTabs from "../components/ProductFamilyTabs";
import ProductsListDesktop from "../components/ProductsListDesktop";
import ProductFiltersDesktop from "../components/ProductFiltersDesktop";
import Button from "@mui/material/Button";

import { motion } from "framer-motion";
import { pageVariantsTopLevel, pageTransition } from "../transitions";
import { Helmet } from "react-helmet";

import { styled } from "@mui/material/styles";
import { lightTheme } from "../theme";
//import styled from "styled-components";
import SubCategoryPageDetails from "../components/SubcategoryPageDetails";


const PageContainer = styled('div')(
  () => `
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: left;
  font-family: ${lightTheme.fontPrimary};
  color: ${lightTheme.textDark};
  /* background-color: ${lightTheme.backgroundGrey50}; */
  background-color: ${lightTheme.backgroundGrey50};
  transition: ${lightTheme.allTransition};
  .header-image-container{
    height: 220px;
    background: black;
  }
  .header-image{
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    opacity: 0.5;
  }
  .header-image-text{
    position: absolute;
    top: 0px;
    width: 100%;
    height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${lightTheme.textLight};
  }
  .header-image-text h1{
    font-size: 40px;
    margin: 0 0 24px 0;
  }
  .products-and-filters{
    display: flex;
    justify-content: center;
    padding: 24px;
  }
  h2{
    white-space: nowrap;
  }
 .filters-and-license{
    /* position: -webkit-sticky; 
    position: sticky;
    top: 0; */
  }
  .filters-and-license-card{  
    position: relative;
    display: flex;
    flex-direction: column;
    height: fit-content;
    max-width: 320px;
    margin: 0 0 16px 0;
    padding: 24px;
    background-color: ${lightTheme.white};
    border-radius: 4px;
    box-shadow: ${lightTheme.cardBoxShadow};
    transition: 0.15s all ease-out;
    }
    .page-description-card{
    position: relative;
    display: flex;
    flex-direction: column;
    height: fit-content;
    max-width: 320px;
    margin: 0 0 16px 0;
    padding: 24px;
    background-color: ${lightTheme.white};
    border-radius: 4px;
    box-shadow: ${lightTheme.cardBoxShadow};
    transition: 0.15s all ease-out;
    }
    .license-p{
      margin:16px 0 0 0;
    }
    .read-more-button-container{
      display: flex;
      width: 100%;
      justify-content: flex-end;
      padding: 8px 0 0 0;
      margin:0 0 -16px 0;
    }
    .read-more-button{
      position: relative;
      width: fit-content;
      color: ${lightTheme.textDark} !important;
      color: white;
    }
`,);

export default function SubCategoryPageDesktop(props) {
  let { path, url } = useRouteMatch();
  const [pageTitle, setPageTitle] = useState("");
  const [dbQuery, setdbQuery] = useState("");

  const FormatPageTitle = () => {
    var string = props.category;
    var string2 = string.replace("_", " ");
    // console.log("Composed= " + string2);

    const words = string2.split(" ");

    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }

    const capitalised = words.join(" ");
    setPageTitle(capitalised);
  };

  useEffect(() => {
    FormatPageTitle();
  }, [props.category]);

  return (
    <motion.div
      className="page-animation-div"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariantsTopLevel}
      transition={pageTransition}
    >
      {/* <button onClick={() => console.log()}>Log 1</button> */}
      <Helmet>
        <title>{`Hire ${pageTitle} | Sydney | Quick Quote | GTH Equipment`}</title>
        <meta name="description" content={`${pageTitle} Hire Sydney`} />
      </Helmet>
      <PageContainer>
        {/* <ProductFamilyNav category={props.category} /> */}
        <div className="products-and-filters">
          <div className="filters-and-license">
            <div className="filters-and-license-card">
              <h2>Filter Products</h2>
              <ProductFiltersDesktop
                category={props.category}
                subcategory={props.subcategory}
              />
            </div>
            <div className="filters-and-license-card">
              <h2>License Requirements</h2>
              <p className="license-p">
                We are still building this section of the app. For information
                on the licensing requirements of a specific product, please
                refer to the relevant regulatory website for your state.
              </p>
              <div className="read-more-button-container">
                <Button
                  className="read-more-button"
                  component={Link}
                  to={`/${props.category}/license-requirements`}
                  aria-label="Read More About License Requirements"
                >
                  Read More
                </Button>
              </div>
            </div>
            <SubCategoryPageDetails
              pageTitle={pageTitle}
              category={props.category}
            />
          </div>
          <ProductsListDesktop
            category={props.category}
            subcategory={props.subcategory}
            model={props.model}
            categoryQueryQQ={props.categoryQueryQQ}
          />
        </div>
      </PageContainer>
    </motion.div>
  );
}
