import React, { Fragment, useContext } from "react";
import { MainContext } from "../contexts/MainContext.js";
import styled from "styled-components";

const SpacerDiv = styled.div`
  width: 100%;
  height: ${(props) => (props.height ? props.height : "56px")};
`;

export default function Spacer(props) {
  const { isDesktop } = useContext(MainContext);

  return (
    <Fragment>
      {props.disableOnDesktop && isDesktop ? null : (
        <SpacerDiv className="spacer" height={props.height} />
      )}
    </Fragment>
  );
}
