import React from "react";
import { useParams, useRouteMatch, Link, useHistory } from "react-router-dom";

import PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import ProductsListDesktop from "../components/ProductsListDesktop";
import ProductFiltersDesktop from "../components/ProductFiltersDesktop";
import Button from "@mui/material/Button";

import styled from "styled-components";

const TabsContainer = styled.div`
  .MuiAppBar-root {
    align-items: center;
    background-color: ${(props) => props.theme.white};
    box-shadow: none;
  }
  .MuiTab-root {
    font-family: Titillium Web;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    color: ${(props) => props.theme.textDark};
  }
  .MuiTab-textColorInherit {
    opacity: 1;
  }
  .MuiTabs-indicator,
  .PrivateTabIndicator-root-1 {
    background-color: ${(props) => props.theme.primary};
    height: 3.5px;
  }
  .header-image-container {
    height: 220px;
    background: black;
  }
  .header-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    opacity: 0.5;
  }
  .header-image-text {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.textLight};
  }
  .header-image-text h1 {
    font-size: 40px;
    margin: 0 0 24px 0;
  }
  .products-and-filters {
    display: flex;
    justify-content: center;
    padding: 24px;
  }
  h2 {
    white-space: nowrap;
  }
  .filters-and-license {
    /* position: -webkit-sticky; 
    position: sticky;
    top: 0; */
  }
  .filters-and-license-card {
    position: relative;
    display: flex;
    flex-direction: column;
    height: fit-content;
    max-width: 320px;
    margin: 0 0 16px 0;
    padding: 24px;
    background-color: ${(props) => props.theme.white};
    border-radius: 4px;
    box-shadow: ${(props) => props.theme.cardBoxShadow};
  }
  .license-p {
    margin: 16px 0 0 0;
  }
  .read-more-button-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 8px 0 0 0;
    margin: 0 0 -16px 0;
  }
  .read-more-button {
    position: relative;
    width: fit-content;
  }
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

export default function ProductFamilyTabs(props) {
  let history = useHistory();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <TabsContainer>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Documentation Tabs"
        >
          <Tab
            label="Telehandlers"
            {...a11yProps(0)}
            onClick={() => {
              history.push("/telehandlers/all");
            }}
          />
          <Tab
            label="Boom Lifts"
            {...a11yProps(1)}
            onClick={() => {
              history.push("/boom_lifts/all");
            }}
          />
          <Tab label="Scissor Lifts" {...a11yProps(2)} />
          <Tab label="Vertical Man Lifts" {...a11yProps(3)} />
          <Tab label="Forklifts" {...a11yProps(4)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        {/* <div className="products-and-filters">
          <div className="filters-and-license">
            <div className="filters-and-license-card">
              <h2>Filter Products</h2>
              <ProductFiltersDesktop
                category={props.category}
                subcategory={props.subcategory}
              />
            </div>
            <div className="filters-and-license-card">
              <h2>License Requirements</h2>
              <p className="license-p">
                We are still building this section of the app. For information
                on the licensing requirements of a specific product, please
                refer to the relevant regulatory website for your state.
              </p>
              <div className="read-more-button-container">
                <Button
                  className="read-more-button"
                  component={Link}
                  to={`/${props.category}/license-requirements`}
                >
                  Read More
                </Button>
              </div>
            </div>
          </div>
          <ProductsListDesktop
            category={props.category}
            subcategory={props.subcategory}
          />
        </div> */}
      </TabPanel>
      <TabPanel value={value} index={1}>
        Boom Lifts
      </TabPanel>
      <TabPanel value={value} index={2}>
        Scissor Lifts
      </TabPanel>
      <TabPanel value={value} index={3}>
        Vertical Man Lifts
      </TabPanel>
      <TabPanel value={value} index={4}>
        Forklifts
      </TabPanel>
    </TabsContainer>
  );
}
