import React, { useState, useContext } from "react";
import { MainContext } from "../../contexts/MainContext";
import { useHistory } from "react-router-dom";
import { FormControl } from "@mui/material";
import ButtonLarge from "../buttons/ButtonLarge";
import ButtonGoogle from "../buttons/GoogleButton";
import InputBoxed from "../InputBoxed";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from '@mui/material/Alert';

import ToggleLoginSignupDrawer from "../buttons/ToggleLoginSignupDrawer";

import firebase, {
  register,
  createUserMeta,
  auth,
  firestoreDB
} from "../../firebase";

import styled from "styled-components";

const SignupContainer = styled.div`
  .form-container {
    display: flex;
    max-width: 380px;
    width: 100%;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .MuiFormControl-root {
    width: 100%;
    max-width: 380px;
  }
  .body-text {
    font-size: 14px;
    font-weight: 600;
    font-family: ${(props) => props.theme.fontPrimary};
    color: ${(props) => props.theme.textDark};
    margin: 8px 0 0 0;
    background-color: ${(props) => props.theme.backgroundLight};
  }
  .link-text {
    text-align: right;
    font-size: 14px;
    font-weight: 600;
    font-family: ${(props) => props.theme.fontPrimary};
    color: ${(props) => props.theme.textDark};
    text-decoration: none;
    margin: 16px 0 0 0;
  }
  .forgot-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
`;

export default function SignUp(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyABN, setCompanyABN] = useState("");
  const [companyPosition, setCompanyPosition] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [checkFields, setCheckFields] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [error, setError] = useState("");
  const { Alert } = useContext(MainContext);
  const history = useHistory();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  const handleClick = () => {
    setOpenSnackbar(true);
  };

  const addUserToFirestore = async (result) => {
    if (!result.additionalUserInfo.isNewUser) return;

    props.setOpen(false);
    const { displayName, email, photoURL, uid } = result.user;
    const accountCreatedThroughGoogle = true;

    try {
      await createUserMeta(
        name,
        email,
        companyName,
        companyABN,
        companyPosition,
        phoneNumber,
        accountCreatedThroughGoogle
      );
      console.log("passed createUserMeta");
      // history.push("/account");
    } catch (error) {
      Alert(error.message);
    }

    // await firestoreDB
    //   .collection("users")
    //   .add({
    //     displayName,
    //     photoURL,
    //     id: uid,
    //     email,
    //     name,
    //     companyName,
    //     companyABN,
    //     companyPosition,
    //     phoneNumber
    //   })
    //   .then(() => {
    //     console.log("User added to database");
    //   })
    //   .catch((error) => {
    //     console.log("error adding user to the database", error);
    //   });
  };

  const GoogleSubmit = () => {
    googleSignUp();
  };

  const googleSignUp = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth
      .signInWithPopup(provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        //const token = result.credential.accessToken
        // The signed-in user info.
        // console.log(result)
        addUserToFirestore(result);
      })
      .catch((error) => {
        // Handle Errors here.
        //const errorCode = error.code
        //const errorMessage = error.message
        // The email of the user's account used.
        //const email = error.email
        // The firebase.auth.AuthCredential type that was used.
        //const credential = error.credential
        // ...
        console.log(error);
        console.log("failed");
      });
  };

  const onError = () => {
    setOpenSnackbar(true);
    setCheckFields(true);
  };

  return (
    <SignupContainer>
      <h2 className="heading-text">Login or register to proceed:</h2>
      <ToggleLoginSignupDrawer
        active={props.active}
        setActive={props.setActive}
      />
      <form
        className="form-container"
        onSubmit={(e) => e.preventDefault() && false}
      >
        <FormControl required>
          <InputBoxed
            label="Name"
            id="name"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            margin="24px 0 0 0"
            error={checkFields && !name ? true : false}
          />
        </FormControl>
        <FormControl required>
          <InputBoxed
            label="Contact Email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="24px 0 0 0"
            error={checkFields && !email ? true : false}
          />
        </FormControl>
        <FormControl required>
          <InputBoxed
            label="Password"
            id="password"
            name="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={checkFields && !password ? true : false}
          />
        </FormControl>
        <FormControl required>
          <InputBoxed
            label="Company Name"
            id="company-name"
            name="company-name"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            error={checkFields && !companyName ? true : false}
          />
        </FormControl>
        <FormControl required>
          <InputBoxed
            label="Company ABN"
            id="company-ABN"
            name="company-ABN"
            value={companyABN}
            onChange={(e) => setCompanyABN(e.target.value)}
            error={checkFields && !companyABN ? true : false}
          />
        </FormControl>
        <FormControl required>
          <InputBoxed
            label="Position in Company"
            id="position"
            name="position"
            value={companyPosition}
            onChange={(e) => setCompanyPosition(e.target.value)}
            margin="24px 0 0 0"
            error={checkFields && !companyPosition ? true : false}
          />
        </FormControl>
        <FormControl required>
          <InputBoxed
            label="Mobile Number"
            id="mobile-number"
            name="mobile-number"
            type="mobile-number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            error={checkFields && !phoneNumber ? true : false}
          />
        </FormControl>
        <ButtonLarge
          text="Create Account"
          onClick={() => {
            name &&
            email &&
            password &&
            companyName &&
            companyABN &&
            companyPosition &&
            phoneNumber
              ? onRegister()
              : onError();
          }}
          // onClick={onRegister}
          margin=" 24px 0 0 0"
        />
        <body className="body-text">or</body>
        <ButtonGoogle
          text="Continue with Google Account"
          onClick={() => GoogleSubmit()}
          margin=" 8px 0 0 0"
        />
        {/* <Button
          type="submit"
          fullWidth
          variant="contained"
          color="secondary"
          // component={Link}
          onClick={() => {
            props.toggleRegistered();
          }}
          className={classes.submit}
        >
          Login
        </Button> */}
      </form>
      {/* <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          Please fill in all fields.
        </Alert>
      </Snackbar> */}
    </SignupContainer>
  );

  async function onRegister() {
    props.setOpen(false);
    const accountCreatedThroughGoogle = false;
    console.log("loc");
    try {
      await register(name, email, password);
      console.log("passed register");
      await createUserMeta(
        name,
        email,
        companyName,
        companyABN,
        companyPosition,
        phoneNumber,
        accountCreatedThroughGoogle
      );
      console.log("passed createUserMeta");
      // history.push("/account");
    } catch (error) {
      Alert(error.message);
    }
  }
}
