import React, { useState } from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";

import styled from "styled-components";

const StyledButton = styled(Button)`
  white-space: nowrap;
  font-family: ${(props) => props.theme.fontPrimary};
  font-size: 1rem;
  font-weight: 600;
  color: ${(props) => props.theme.textDark};
  transition: 0.2s all ease-out;
  &:hover {
    color: ${(props) => props.theme.grey700};
    box-shadow: none;
    background-color: ${(props) => props.theme.backgroundLight};
  }
  .phone-cta-button-text {
    display: flex;
    align-items: center;
    box-shadow: none;
    background-color: ${(props) => props.theme.backgroundLight};
  }
  .phone-cta-button-text:hover {
    color: ${(props) => props.theme.grey600};
    box-shadow: none;
    background-color: ${(props) => props.theme.backgroundLight};
  }
  &.Mui-disabled {
    white-space: nowrap;
    font-family: ${(props) => props.theme.fontPrimary};
    font-size: 1rem;
    font-weight: 600;
    color: ${(props) => props.theme.textDark};
  }
`;

export default function RevealPhoneNumberButton(props) {
  const [buttonText, setButtonText] = useState("1300 608...");
  const [buttonClicked, setButtonClicked] = useState(false);

  const ToggleButtonText = () => {
    buttonText === "1300 608..."
      ? setButtonText("1300 608 608")
      : setButtonText("1300 608...");
    setButtonClicked(true); //disable the button after it has been clicked once
    window.dataLayer.push({ event: "phone_number_click_telehandler_landing_page" });
  };

  return (
    <div>
      <StyledButton
        id="phone-cta"
        onClick={ToggleButtonText}
        disabled={buttonClicked}
      >
        <span className="phone-cta-button-text">
          <PhoneEnabledIcon /> 
          {buttonText}
        </span>
      </StyledButton>

      {/* <a href="#" class="elementor-button-link elementor-button elementor-size-md reveal-number" role="button" id="phone-cta" onclick="revealPhoneNumber()">
            <div class="landing-page-cls">	<span class="elementor-button-content-wrapper" id="call-button-reveal-wrapper">
                <span class="elementor-button-icon elementor-align-icon-left">
                    <i aria-hidden="true" class="fas fa-phone"></i>			</span>
                <span id="text-hidden" class="elementor-button-text">1300 608...</span>
                <span id="text-to-reveal" class="elementor-button-text" >1300 608 608</span>
            </span>
            </div>
        </a> */}
    </div>
  );
}

//         .landing-page-cls #text-to-reveal{
// 	    display: none;
// 	    font-family: "Titillium Web", Sans-serif;
//     font-size: 16px;
//     font-weight: 600;
//     text-transform: uppercase;
//     line-height: 19px;
//     letter-spacing: 0.5px;
//     fill: #000000;
//     color: #000000;
// 	}
// 	.landing-page-cls #call-button-reveal-wrapper{
// 	     opacity:1;
//     tansition: 0.2s opacity ease-out;
// 	}
// 	#call-button-reveal-wrapper:hover{
//     opacity:0.8;
// 	}
//    .landing-page-cls #text-hidden{
// 	        font-family: "Titillium Web", Sans-serif;
//     font-size: 16px;
//     font-weight: 600;
//     text-transform: uppercase;
//     line-height: 19px;
//     letter-spacing: 0.5px;
//     fill: #000000;
//     color: #000000;
// 	    }
// 	.reveal-number#phone-cta{
// 	    background-color: white;
// 	    float: right;
// 	   opacity:1;
// 	   tansition: 0.2s opacity ease-out;
// 	}
//   .reveal-number#phone-cta:hover{
// 	    background-color: white;
// 	    float: right;
// 	 opacity:0.8;
// 	}
// 	.reveal-number .fa-phone, .reveal-number .elementor-button-icon{
// 	fill: #000000;
//     color: #000000;
// 	}
