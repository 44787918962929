import React, { useEffect, useContext, useRef } from "react";
import { MainContext } from "./contexts/MainContext.js";
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { createBrowserHistory } from "history";
// import ReactGA from "react-ga";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebase";
import { useQuery, QueryCache, ReactQueryCacheProvider } from "react-query";
import { ReactQueryDevtools } from "react-query-devtools";
import { AnimatePresence } from "framer-motion";
import SnackbarProvider from "react-simple-snackbar";
import { Helmet } from "react-helmet";

//Routes imports
import { Home, Page1, Page2, Page3 } from "./routes/DemoPages";
import EquipmentHomePage from "./routes/EquipmentHomePage";
import CartPage from "./routes/CartPage";
import CheckoutPageQuickQuote from "./routes/CheckoutPageQuickQuote";
import AccountPage from "./routes/AccountPage";
import OffHirePage from "./routes/OffHirePage";
import SubCategoryPage from "./routes/SubCategoryPage";
import CheckoutPage from "./routes/CheckoutPage.js";
import BookingConfirmationPage from "./routes/BookingConfirmationPage";
import QuickQuoteConfirmationPage from "./routes/QuickQuoteConfirmationPage";
import SingleProductPage from "./routes/SingleProductPage.js";
import ReactQueryTestPage from "./routes/ReactQueryTestPage";
import AboutThisAppPage from "./routes/AboutThisAppPage.js";
import InstallPage from "./routes/InstallPage.js";

//Component imports
import TopNav from "./components/TopNav";
import BottomNav from "./components/BottomNav";
import BottomMenu from "./components/burgerMenu/BottomMenu";
import Spacer from "./components/Spacer";
import AlertDialog from "./components/AlertDialog";
import { ScrollToTopControlller } from "./components/utilities/ScrollToTopController.js";
import SubCategoryPageHeader from "./components/SubCategoryPageHeader";

//Cache bursting related imports
// import packageJson from "../package.json";
// import { getBuildDate } from "./components/utilities/getBuildDate";
import withClearCache from "./ClearCache";

//Styles related imports
import "./styles.css";
import { styled } from "@mui/material/styles";
import { lightTheme, darkTheme } from "./theme";
import { GlobalStyles } from "./global";
import { ThemeProvider } from "styled-components";
// import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";

export const appHistory = createBrowserHistory();

const ClearCacheComponent = withClearCache(MainApp);

const StyledApp = styled('div')``;

// ATTENTION!!!!!! ------ Alternate the below commented lines on production builds to enable cache burst.
export default function App() {
  return <MainApp />; 
  //return <ClearCacheComponent />;
}

const routes = [
  // {
  //   mapToRouter: true,
  //   name: "Equipment",
  //   component: EquipmentHomePage,
  //   to: "/",
  //   showDesktop: false,
  //   showMobile: true,
  //   exact: true
  // },
  {
    mapToRouter: true,
    name: "Off Hire",
    component: OffHirePage,
    to: {pathname: "https://form.jotform.com/GTHGROUP/customer-off-hire-request" },
    showDesktop: true,
    showMobile: false,
  },
  {
    mapToRouter: true,
    name: "Page2",
    component: Page2,
    to: "/page2",
    showDesktop: false,
    showMobile: true,
  },
  {
    mapToRouter: false,
    name: "Account",
    component: AccountPage,
    to: "/account",
    showDesktop: false,
    showMobile: true,
  },
  {
    mapToRouter: true,
    name: "Cart",
    component: CartPage,
    to: "/cart",
    showDesktop: false,
    showMobile: false,
  },
  {
    mapToRouter: true,
    name: "Quick-Quote",
    component: CheckoutPageQuickQuote,
    to: "/quick-quote",
    showDesktop: false,
    showMobile: false,
  },
  {
    mapToRouter: true,
    name: "Confirm Details",
    component: CheckoutPage,
    to: "/confirm-details",
    showDesktop: false,
    showMobile: false,
  },
  {
    mapToRouter: true,
    name: "Request Confirmation",
    component: BookingConfirmationPage,
    to: "/request-confirmation",
    showDesktop: false,
    showMobile: false,
  },
  {
    mapToRouter: true,
    name: "Quick Quote Request Confirmation",
    component: QuickQuoteConfirmationPage,
    to: "/quick-quote-request-confirmation",
    showDesktop: false,
    showMobile: false,
  },
  {
    mapToRouter: true,
    name: "React Query Test",
    component: ReactQueryTestPage,
    to: "/telehandlers/react-query-test",
    showDesktop: false,
    showMobile: false,
  },
  {
    mapToRouter: true,
    name: "About This App",
    component: AboutThisAppPage,
    to: "/about",
    showDesktop: false,
    showMobile: false,
  },
  {
    mapToRouter: true,
    name: "Install This App",
    component: InstallPage,
    to: "/install",
    showDesktop: false,
    showMobile: false,
  },
];

// const themeMUI = createMuiTheme({
//   props: {
//     // Name of the component
//     MuiButtonBase: {
//       // The properties to apply
//       disableRipple: true, // No more ripple, on the whole application!
//     },
//   },
// });

function MainApp() {
  const history = useHistory();
  const location = useLocation();
  const { theme, setTheme, alertDialog, setAlertDialog, isDesktop } =
    useContext(MainContext);
  let { path, url } = useRouteMatch();
  const filteredRoutes = routes.filter((route) => route.mapToRouter);
  const queryCache = new QueryCache();

  // Store the Google UTM params to local storage
  const urlSearchParams = new URLSearchParams(location.search);
  
  console.log("urlSearchParams");
  console.log(urlSearchParams.toString());
  const test = urlSearchParams.get('utm_source')
  console.log("test");
  console.log(test);
  // console.log("location.search");
  // console.log(location.search);
  if (urlSearchParams.has('utm_term')) {
    localStorage.setItem('utm_term', urlSearchParams.get('utm_term'));
    console.log("urlSearchParams.get('utm_term')");
    console.log(urlSearchParams.get('utm_term'));
  }
  if (urlSearchParams.has('gclid')) {
    localStorage.setItem('gclid', urlSearchParams.get('gclid'));
    console.log("urlSearchParams.get('gclid')");
    console.log(urlSearchParams.get('gclid'));
  }
  if (urlSearchParams.has('utm_campaign')) {
    localStorage.setItem('utm_campaign', urlSearchParams.get('utm_campaign'));
    console.log("urlSearchParams.get('utm_campaign')");
    console.log(urlSearchParams.get('utm_campaign'));
  }

  // Detects and sets theme to dark or light according to users OS preferences
  // useEffect(() => {
  //   if (
  //     window.matchMedia &&
  //     window.matchMedia("(prefers-color-scheme: dark)").matches
  //   ) {
  //     // dark mode
  //     console.log("darkmode");
  //     setTheme("dark");
  //   }
  // }, []);

  // useEffect(() => {
  //   console.log(`${scrollPercentage}%`);
  // });

  //  // This line will trigger a Google Analytics pageview on a route change
  //   useEffect(() => {
  //     ReactGA.pageview(window.location.pathname + window.location.search);
  //   }, []);

  // // This line will trigger a Google Analytics pageview on a route change
  // useEffect(() => {
  //   window.dataLayer.push({
  //     event: "pageview",
  //   });
  // }, []);

  useEffect(() => {
    trackPageView();
    // console.log('document')
    // console.log(document.title)
  }, [location]);

  function trackPageView() {
    window.dataLayer.push({
      event: "virtualPageview",
      pageUrl: location.pathname,
      pageTitle: location.pathname,
    });
  }

  return (
    // // <Router history={appHistory}>
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      {/* <MuiThemeProvider theme={themeMUI}> */}
        <Helmet>
          <title>
            Quality Telehandlers and Access Equipment - GTH Equipment | App
          </title>
          <meta
            name="description"
            content="GTH Equipment specialises in the leasing and sales of new and used telehandlers, boom lifts, scissor lifts, and forklifts in Sydney NSW"
          />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="https://firebasestorage.googleapis.com/v0/b/gth-equipment.appspot.com/o/icons%2Fapple-touch-icon.png?alt=media&token=78eb12fd-e2e4-4839-9432-44e90a120400"
          />
        </Helmet>
        <SnackbarProvider>
          <ScrollToTopControlller />
          <GlobalStyles />
          <TopNav routes={routes} />
          <Spacer height="56px" />

          {isDesktop ? <SubCategoryPageHeader /> : null}
          {/* Logic for when to display the above header component is in the component - Look for the "mathc" variable */}
          {/* Need to add logic above to change the spacer size on desktop */}
          <ReactQueryCacheProvider queryCache={queryCache}>
            <StyledApp className="App">
              <AnimatePresence>
                <Switch>
                  {/* Non-Dynamic Route */}
                  <Route exact path="/">
                    {isDesktop ? (
                      <Redirect to="/telehandlers/all" />
                    ) : (
                      <EquipmentHomePage />
                    )}
                  </Route>
                  {/* Account needs to be defined below rather than in the object 
                above so that the react-router hooks work */}
                  <Route path="/account" component={AccountPage} />
                  {filteredRoutes.map((route, i) => (
                    <Route
                      exact={() => (route.exact ? "exact" : null)}
                      path={route.to}
                    >
                      <route.component />
                    </Route>
                  ))}

                  {/* Dynamic Subcategory Routes */}
                  <Route
                    path={[
                      "/:category/:subcategory",
                      // "/boom-lifts/:subcategory",
                      // "/scissor-lifts/:subcategory",
                      // "/vertical-lifts/:subcategory",
                      // "/forklifts/:subcategory"
                    ]}
                  >
                    <Route
                      path="/instant-quote/:category"
                      component={AccountPage}
                    />
                    <div>
                      <SubCategoryPage />
                    </div>
                  </Route>
                </Switch>
              </AnimatePresence>
            </StyledApp>
            {/* <ReactQueryDevtools initialIsOpen /> */}
          </ReactQueryCacheProvider>
          <AlertDialog
            alertDialog={alertDialog}
            setAlertDialog={setAlertDialog}
          />
          <BottomMenu menuItems={routes} />
          {isDesktop ? null : <BottomNav />}
        </SnackbarProvider>
      {/* </MuiThemeProvider> */}
    </ThemeProvider>
    // </Router>
  );
}
