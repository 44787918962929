import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import DocumentationTable from "./DocumentationTable";
import makeStyles from '@mui/styles/makeStyles';
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import GetAppIcon from "@mui/icons-material/GetApp";

import styled from "styled-components";

const TabsContainer = styled.div`
  width: 100%;
  .MuiAppBar-root {
    background-color: ${(props) => props.theme.white};
    box-shadow: none;
  }
  .MuiTab-root {
    font-family: Titillium Web;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    color: ${(props) => props.theme.textDark};
  }
  .MuiTab-textColorInherit {
    opacity: 1;
  }
  .MuiTabs-indicator,
  .PrivateTabIndicator-root-1 {
    background-color: ${(props) => props.theme.primary};
    height: 3.5px;
  }
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

export default function DocumentationTabs(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <TabsContainer className="tabs-container">
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Documentation Tabs"
        >
          <Tab label="Documentation" {...a11yProps(0)} />
          <Tab label="License Types" {...a11yProps(1)} />
          {/* <Tab label="Product Brochure" {...a11yProps(2)} /> */}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <DocumentationTable data={props.data} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        License Types - Coming Soon
      </TabPanel>
      {/* <TabPanel value={value} index={2}>
        Product Brochure Downloads - Coming Soon
      </TabPanel> */}
    </TabsContainer>
  );
}
