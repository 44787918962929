import React from "react";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Divider from "@mui/material/Divider";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import styled from "styled-components";

const StyledAccordionCard = styled.div`
  margin: 8px 0 0;
  --heightA: calc(100vh - 160px);
  --heightB: calc(var(--heightA) / 5);
  /* height: var(--heightB); */
  /* max-height: ${(props) =>
    props.expanded ? "1200px" : "var(--heightB)"}; */
  box-shadow: ${(props) => props.theme.cardBoxShadow};
  transition: 0.2s all ease-out;
  && .MuiPaper-elevation1 {
    box-shadow: none;
  }
  .MuiAccordionSummary-content {
    margin: 12px 0;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
  }
  .category-image {
    height: calc(var(--heightB) - 24px);
    max-height: 100px;
    margin: 0 0 0 8px;
    /* height: 12vh;
    max-height: 100px; */
  }
/*   
  @media screen and (max-height: 1000px) {
    .category-image {
      height: calc(16vh - 25px);
  }
}

  @media screen and (max-height: 768px) {
    .category-image {
      height:  calc(13.5vh - 22px);
  }
}
@media screen and (max-height: 640px) {
    .category-image {
      height:  calc(12vh - 22px);
  }
}
@media screen and (max-height: 567px) {
    .category-image {
      height: 7vh;
  }
} */
  .card-title {
    font-family: Titillium Web;
    font-style: normal;
    font-weight: 600;
    color: ${(props) => props.theme.textDark};
    text-align: right;
    text-transform: capitalize;
    height: fit-content;
  }
  .MuiIconButton-root {
    color: ${(props) => props.theme.textDark};
  }
  .subcategory-list {
    width: 100%;
  }
  .MuiListItem-gutters {
    padding: 8px 0;
  }
  .MuiListItemText-primary {
    font-family: Titillium Web;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: ${(props) => props.theme.textDark};
    text-align: left;
    text-transform: capitalize;
    height: fit-content;
  }
`;

export default function EquipmentCatCard({
  title,
  category,
  subcategories,
  imgSrc,
  imgAlt,
  fetchProductData,
  cmsCustomType,
  onClick,
  parentExpanded,
  setParentExpanded
}) {
  const [expanded, setExpanded] = React.useState(false);
  let { path, url } = useRouteMatch();

  const handleChange = (title) => (event, isExpanded) => {
    setParentExpanded(isExpanded ? title : false);
  };

  return (
    <StyledAccordionCard
      expanded={expanded}
      className="category-card"
      onClick={onClick}
    >
      <Accordion
        expanded={parentExpanded === title}
        onChange={handleChange(title)}
        className="category-card-inside"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          // aria-controls="panel1bh-content"
          // id="panel1bh-header"
        >
          <img className="category-image" src={imgSrc} alt={imgAlt} />
          <h2 className="card-title">{title}</h2>
        </AccordionSummary>
        <AccordionDetails>
          <List
            className="subcategory-list"
            component="nav"
            aria-label="subcategory-list"
          >
            {subcategories.map((subcategory, i) => (
              <div>
                <ListItem
                  button
                  component={Link}
                  to={`${category}/${subcategory.to}`}
                  onClick={() =>
                    fetchProductData(cmsCustomType, subcategory.name)
                  }
                >
                  <ListItemText primary={subcategory.name} />
                  <ChevronRightIcon />
                </ListItem>
                {/* No divider after the last list item */}
                {i < subcategories.length - 1 ? <Divider /> : null}
              </div>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </StyledAccordionCard>
  );
}
