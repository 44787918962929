import React, { useState } from "react";
import ReactDOMServer from "react-dom/server";

export default function RequestOffHireEmail({
  companyName,
  contactName,
  contactMobile,
  pickupLocation,
  productFamily,
  assetNumber,
  pickupInstructions,
  requestedOffHireDate,
  formattedDateAndTime
}) {
  const formattedAddress = encodeURIComponent(
    `${pickupLocation.streetAddress}, ${pickupLocation.city}, ${pickupLocation.postcode}`
  );
  const formattedCompanyName = encodeURIComponent(companyName);
  const formattedContactName = encodeURIComponent(contactName);
  const formattedAssetNumber = encodeURIComponent(assetNumber);

  return (
    <div style={{ margin: 0, padding: 0, backgroundColor: "#eee" }}>
      <center>
        <table
          align="center"
          border={0}
          cellPadding={0}
          cellSpacing={0}
          id="m_-2344630246995633201m_-1572471389833981368bodyTable"
          style={{
            borderCollapse: "collapse",
            padding: 0,
            backgroundColor: "#eee",
            height: "100%",
            margin: 0,
            width: "100%"
          }}
          width="100%"
        >
          <tbody>
            <tr>
              <td
                align="center"
                id="m_-2344630246995633201m_-1572471389833981368bodyCell"
                style={{
                  borderCollapse: "collapse",
                  paddingTop: 50,
                  paddingLeft: 20,
                  paddingBottom: 20,
                  paddingRight: 20,
                  borderTop: 0,
                  height: "100%",
                  margin: 0,
                  width: "100%"
                }}
                valign="top"
              >
                <table
                  border={2}
                  cellPadding={0}
                  cellSpacing={0}
                  id="m_-2344630246995633201m_-1572471389833981368templateContainer"
                  style={{
                    borderCollapse: "collapse",
                    border: "none",
                    backgroundColor: "#fff",
                    borderRadius: 4,
                    boxShadow: "0px 2px 4px #0000001A"
                  }}
                  width={600}
                >
                  <tbody>
                    <tr>
                      <td
                        id="m_-2344630246995633201m_-1572471389833981368templateContainerInner"
                        style={{ borderCollapse: "collapse", padding: 0 }}
                      >
                        <table
                          border={0}
                          cellPadding={0}
                          cellSpacing={0}
                          style={{ borderCollapse: "collapse" }}
                          width="100%"
                        >
                          <tbody>
                            <tr>
                              <td
                                align="center"
                                style={{ borderCollapse: "collapse" }}
                                valign="top"
                              >
                                <table
                                  border={0}
                                  cellPadding={0}
                                  cellSpacing={0}
                                  style={{ borderCollapse: "collapse" }}
                                  width="100%"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{ borderCollapse: "collapse" }}
                                        valign="top"
                                      />
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td
                                align="center"
                                style={{ borderCollapse: "collapse" }}
                                valign="top"
                              >
                                <table
                                  border={0}
                                  cellPadding={0}
                                  cellSpacing={0}
                                  style={{ borderCollapse: "collapse" }}
                                  width="100%"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{ borderCollapse: "collapse" }}
                                        valign="top"
                                      >
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 20
                                                }}
                                              >
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                      >
                                                        <span />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  padding: 9
                                                }}
                                                valign="top"
                                              >
                                                <table
                                                  align="left"
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse",
                                                          padding: 0,
                                                          paddingTop: 0,
                                                          paddingBottom: 0,
                                                          paddingLeft: 9,
                                                          paddingRight: 9,
                                                          textAlign: "center"
                                                        }}
                                                        valign="top"
                                                      >
                                                        <a
                                                          href="https://www.gth.com.au/"
                                                          style={{
                                                            wordWrap:
                                                              "break-word",
                                                            color: "#15c",
                                                            fontWeight:
                                                              "normal",
                                                            textDecoration:
                                                              "underline"
                                                          }}
                                                          target="_blank"
                                                          data-saferedirecturl="https://www.google.com/url?q=https://www.gthonline.com.au/&source=gmail&ust=1613109059842000&usg=AFQjCNH7CaHK95tIQRIvsljYmjd0LSenTg"
                                                        >
                                                          <img
                                                            align="center"
                                                            alt="GTH Equipment"
                                                            src="https://firebasestorage.googleapis.com/v0/b/gth-equipment.appspot.com/o/logos%2FGTH-Logo-Small.png?alt=media&token=d36a5146-6e2c-4704-905b-02e2f873a2e5"
                                                            style={{
                                                              border: 0,
                                                              height: "auto",
                                                              lineHeight:
                                                                "100%",
                                                              outline: "none",
                                                              textDecoration:
                                                                "none",
                                                              paddingBottom: 0,
                                                              display: "inline",
                                                              verticalAlign:
                                                                "bottom",
                                                              maxWidth: 250
                                                            }}
                                                            width={250}
                                                            className="CToWUd"
                                                          />
                                                        </a>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>

                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse"
                                                }}
                                                valign="top"
                                              >
                                                <table
                                                  align="left"
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse",
                                                          color: "#222",
                                                          fontFamily:
                                                            '"Helvetica Neue",Arial',
                                                          fontSize: "18px",
                                                          lineHeight: "100%",
                                                          textAlign: "center",
                                                          paddingTop: "9px",
                                                          paddingBottom: "9px",
                                                          paddingLeft: "18px",
                                                          paddingRight: "18px"
                                                        }}
                                                        valign="top"
                                                      >
                                                        <b>Off-Hire Request</b>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse"
                                                }}
                                                valign="top"
                                              >
                                                <table
                                                  align="left"
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse",
                                                          color: "#222",
                                                          fontFamily:
                                                            '"Helvetica Neue",Arial',
                                                          fontSize: "14px",
                                                          lineHeight: "100%",
                                                          textAlign: "center",
                                                          paddingTop: "9px",
                                                          paddingBottom: "9px",
                                                          paddingLeft: "18px",
                                                          paddingRight: "18px"
                                                        }}
                                                        valign="top"
                                                      >
                                                        <b>
                                                          {formattedDateAndTime}
                                                        </b>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>

                                        {/* Dividing Line */}
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 5,
                                                  paddingBottom: 5,
                                                  paddingLeft: 5,
                                                  paddingRight: 5
                                                }}
                                              >
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse",
                                                    borderTopWidth: 1,
                                                    borderTopStyle: "none",
                                                    borderTopColor: "#e8e8e8"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                      >
                                                        <span />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{
                                            borderCollapse: "collapse",
                                            backgroundColor: "#eee"
                                          }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 9,
                                                  paddingBottom: 9,
                                                  paddingLeft: 18,
                                                  paddingRight: 18
                                                }}
                                                valign="top"
                                              >
                                                <table
                                                  align="left"
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                        valign="top"
                                                      >
                                                        <table
                                                          align="left"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={150}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <p
                                                                  style={{
                                                                    margin: 0,
                                                                    paddingBottom: 0,
                                                                    fontWeight: 600
                                                                  }}
                                                                >
                                                                  <b>
                                                                    Quote
                                                                    Request
                                                                    Details
                                                                  </b>
                                                                </p>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                        <table
                                                          align="right"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={407}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              />
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        {/* Dividing Line */}
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 5,
                                                  paddingBottom: 5,
                                                  paddingLeft: 5,
                                                  paddingRight: 5
                                                }}
                                              >
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse",
                                                    borderTopWidth: 1,
                                                    borderTopStyle: "none",
                                                    borderTopColor: "#e8e8e8"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                      >
                                                        <span />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 9,
                                                  paddingBottom: 9,
                                                  paddingLeft: 18,
                                                  paddingRight: 18
                                                }}
                                                valign="top"
                                              >
                                                <table
                                                  align="left"
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                        valign="top"
                                                      >
                                                        <table
                                                          align="left"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={150}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <p
                                                                  style={{
                                                                    margin: 0,
                                                                    paddingBottom: 0
                                                                  }}
                                                                >
                                                                  Company Name
                                                                </p>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                        <table
                                                          align="right"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={407}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  fontWeight: 600,
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <a
                                                                  target="_blank"
                                                                  rel="noopener noreferrer"
                                                                  href={`https://gthrentals.my.salesforce.com/_ui/search/ui/UnifiedSearchResults?str=${formattedCompanyName}`}
                                                                >
                                                                  {companyName}
                                                                </a>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        {/* Dividing Line */}
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 5,
                                                  paddingBottom: 5,
                                                  paddingLeft: 5,
                                                  paddingRight: 5
                                                }}
                                              >
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse",
                                                    borderTopWidth: 1,
                                                    borderTopStyle: "solid",
                                                    borderTopColor: "#e8e8e8"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                      >
                                                        <span />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 9,
                                                  paddingBottom: 9,
                                                  paddingLeft: 18,
                                                  paddingRight: 18
                                                }}
                                                valign="top"
                                              >
                                                <table
                                                  align="left"
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                        valign="top"
                                                      >
                                                        <table
                                                          align="left"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={150}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <p
                                                                  style={{
                                                                    margin: 0,
                                                                    paddingBottom: 0
                                                                  }}
                                                                >
                                                                  Contact Name
                                                                </p>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                        <table
                                                          align="right"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={407}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  fontWeight: 600,
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <a
                                                                  target="_blank"
                                                                  rel="noopener noreferrer"
                                                                  href={`https://gthrentals.my.salesforce.com/_ui/search/ui/UnifiedSearchResults?str=${formattedContactName}`}
                                                                >
                                                                  {contactName}
                                                                </a>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        {/* Dividing Line */}
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 5,
                                                  paddingBottom: 5,
                                                  paddingLeft: 5,
                                                  paddingRight: 5
                                                }}
                                              >
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse",
                                                    borderTopWidth: 1,
                                                    borderTopStyle: "solid",
                                                    borderTopColor: "#e8e8e8"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                      >
                                                        <span />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 9,
                                                  paddingBottom: 9,
                                                  paddingLeft: 18,
                                                  paddingRight: 18
                                                }}
                                                valign="top"
                                              >
                                                <table
                                                  align="left"
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                        valign="top"
                                                      >
                                                        <table
                                                          align="left"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={150}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <p
                                                                  style={{
                                                                    margin: 0,
                                                                    paddingBottom: 0
                                                                  }}
                                                                >
                                                                  Contact Phone
                                                                </p>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                        <table
                                                          align="right"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={407}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  fontWeight: 600,
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <a
                                                                  href={`tel:${contactMobile}`}
                                                                >
                                                                  {
                                                                    contactMobile
                                                                  }
                                                                </a>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        {/* Dividing Line */}
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 5,
                                                  paddingBottom: 5,
                                                  paddingLeft: 5,
                                                  paddingRight: 5
                                                }}
                                              >
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse",
                                                    borderTopWidth: 1,
                                                    borderTopStyle: "solid",
                                                    borderTopColor: "#e8e8e8"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                      >
                                                        <span />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 9,
                                                  paddingBottom: 9,
                                                  paddingLeft: 18,
                                                  paddingRight: 18
                                                }}
                                                valign="top"
                                              >
                                                <table
                                                  align="left"
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                        valign="top"
                                                      >
                                                        <table
                                                          align="left"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={150}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <p
                                                                  style={{
                                                                    margin: 0,
                                                                    paddingBottom: 0
                                                                  }}
                                                                >
                                                                  Product Family
                                                                </p>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                        <table
                                                          align="right"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={407}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  fontWeight: 600,
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                {productFamily}
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        {/* Dividing Line */}
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 5,
                                                  paddingBottom: 5,
                                                  paddingLeft: 5,
                                                  paddingRight: 5
                                                }}
                                              >
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse",
                                                    borderTopWidth: 1,
                                                    borderTopStyle: "solid",
                                                    borderTopColor: "#e8e8e8"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                      >
                                                        <span />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 9,
                                                  paddingBottom: 9,
                                                  paddingLeft: 18,
                                                  paddingRight: 18
                                                }}
                                                valign="top"
                                              >
                                                <table
                                                  align="left"
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                        valign="top"
                                                      >
                                                        <table
                                                          align="left"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={150}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <p
                                                                  style={{
                                                                    margin: 0,
                                                                    paddingBottom: 0
                                                                  }}
                                                                >
                                                                  Asset Number
                                                                </p>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                        <table
                                                          align="right"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={407}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  fontWeight: 600,
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <a
                                                                  target="_blank"
                                                                  rel="noopener noreferrer"
                                                                  href={`https://gthrentals.my.salesforce.com/_ui/search/ui/UnifiedSearchResults?str=${formattedAssetNumber}`}
                                                                >
                                                                  {assetNumber}
                                                                </a>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 5,
                                                  paddingBottom: 5,
                                                  paddingLeft: 5,
                                                  paddingRight: 5
                                                }}
                                              >
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse",
                                                    borderTopWidth: 1,
                                                    borderTopStyle: "solid",
                                                    borderTopColor: "#e8e8e8"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                      >
                                                        <span />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 9,
                                                  paddingBottom: 9,
                                                  paddingLeft: 18,
                                                  paddingRight: 18
                                                }}
                                                valign="top"
                                              >
                                                <table
                                                  align="left"
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                        valign="top"
                                                      >
                                                        <table
                                                          align="left"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={150}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <p
                                                                  style={{
                                                                    margin: 0,
                                                                    paddingBottom: 0
                                                                  }}
                                                                >
                                                                  Requested
                                                                  Off-Hire Date
                                                                </p>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                        <table
                                                          align="right"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={407}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  fontWeight: 600,
                                                                  rborderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                {
                                                                  requestedOffHireDate
                                                                }
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 5,
                                                  paddingBottom: 5,
                                                  paddingLeft: 5,
                                                  paddingRight: 5
                                                }}
                                              >
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse",
                                                    borderTopWidth: 1,
                                                    borderTopStyle: "solid",
                                                    borderTopColor: "#e8e8e8"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                      >
                                                        <span />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 9,
                                                  paddingBottom: 9,
                                                  paddingLeft: 18,
                                                  paddingRight: 18
                                                }}
                                                valign="top"
                                              >
                                                <table
                                                  align="left"
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                        valign="top"
                                                      >
                                                        <table
                                                          align="left"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={150}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <p
                                                                  style={{
                                                                    margin: 0,
                                                                    paddingBottom: 0
                                                                  }}
                                                                >
                                                                  Pickup
                                                                  Instructions
                                                                </p>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                        <table
                                                          align="right"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={407}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  fontWeight: 600,
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                {
                                                                  pickupInstructions
                                                                }
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 5,
                                                  paddingBottom: 5,
                                                  paddingLeft: 5,
                                                  paddingRight: 5
                                                }}
                                              >
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse",
                                                    borderTopWidth: 1,
                                                    borderTopStyle: "none",
                                                    borderTopColor: "#e8e8e8"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                      >
                                                        <span />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>

                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{
                                            borderCollapse: "collapse",
                                            backgroundColor: "#eee"
                                          }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 9,
                                                  paddingBottom: 9,
                                                  paddingLeft: 18,
                                                  paddingRight: 18
                                                }}
                                                valign="top"
                                              >
                                                <table
                                                  align="left"
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                        valign="top"
                                                      >
                                                        <table
                                                          align="left"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={150}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <p
                                                                  style={{
                                                                    margin: 0,
                                                                    paddingBottom: 0
                                                                  }}
                                                                >
                                                                  <b>
                                                                    Pickup
                                                                    Location
                                                                  </b>
                                                                </p>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                        <table
                                                          align="right"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={407}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              />
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>

                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 5,
                                                  paddingBottom: 5,
                                                  paddingLeft: 5,
                                                  paddingRight: 5
                                                }}
                                              >
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse",
                                                    borderTopWidth: 1,
                                                    borderTopStyle: "none",
                                                    borderTopColor: "#e8e8e8"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                      >
                                                        <span />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 9,
                                                  paddingBottom: 9,
                                                  paddingLeft: 18,
                                                  paddingRight: 18
                                                }}
                                                valign="top"
                                              >
                                                <table
                                                  align="left"
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                        valign="top"
                                                      >
                                                        <table
                                                          align="left"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={150}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <p
                                                                  style={{
                                                                    margin: 0,
                                                                    paddingBottom: 0
                                                                  }}
                                                                >
                                                                  Pickup Address
                                                                </p>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                        <table
                                                          align="right"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={407}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  fontWeight: 600,
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <a
                                                                  target="_blank"
                                                                  rel="noopener noreferrer"
                                                                  href={`https://www.google.com/maps/search/?api=1&query=${formattedAddress}`}
                                                                >
                                                                  {`${pickupLocation.streetAddress}, ${pickupLocation.city}, ${pickupLocation.postcode}`}
                                                                </a>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 5,
                                                  paddingBottom: 5,
                                                  paddingLeft: 5,
                                                  paddingRight: 5
                                                }}
                                              >
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse",
                                                    borderTopWidth: 1,
                                                    borderTopStyle: "solid",
                                                    borderTopColor: "#e8e8e8"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                      >
                                                        <span />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 9,
                                                  paddingBottom: 9,
                                                  paddingLeft: 18,
                                                  paddingRight: 18
                                                }}
                                                valign="top"
                                              >
                                                <table
                                                  align="left"
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                        valign="top"
                                                      >
                                                        <table
                                                          align="left"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={150}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <p
                                                                  style={{
                                                                    margin: 0,
                                                                    paddingBottom: 0
                                                                  }}
                                                                >
                                                                  Contact
                                                                  Instructions
                                                                </p>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                        <table
                                                          align="right"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={407}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  fontWeight: 600,
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                {
                                                                  pickupLocation.contactInstructions
                                                                }
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 5,
                                                  paddingBottom: 5,
                                                  paddingLeft: 5,
                                                  paddingRight: 5
                                                }}
                                              >
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse",
                                                    borderTopWidth: 1,
                                                    borderTopStyle: "solid",
                                                    borderTopColor: "#e8e8e8"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                      >
                                                        <span />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 9,
                                                  paddingBottom: 9,
                                                  paddingLeft: 18,
                                                  paddingRight: 18
                                                }}
                                                valign="top"
                                              >
                                                <table
                                                  align="left"
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                        valign="top"
                                                      >
                                                        <table
                                                          align="left"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={150}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <p
                                                                  style={{
                                                                    margin: 0,
                                                                    paddingBottom: 0
                                                                  }}
                                                                >
                                                                  Location
                                                                  Requirements
                                                                </p>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                        <table
                                                          align="right"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={407}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  fontWeight: 600,
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                {
                                                                  pickupLocation.locationRequirements
                                                                }
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 5,
                                                  paddingBottom: 5,
                                                  paddingLeft: 5,
                                                  paddingRight: 5
                                                }}
                                              >
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse",
                                                    borderTopWidth: 1,
                                                    borderTopStyle: "none",
                                                    borderTopColor: "#e8e8e8"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                      >
                                                        <span />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </center>
    </div>
  );
}
