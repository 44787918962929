import React, { useState, useEffect } from "react";
import {
  Route,
  Switch,
  Redirect,
  useRouteMatch,
  useHistory
} from "react-router-dom";
import firebase, {
  logout,
  auth,
  updateDBEntry,
  removeLocationFromDB,
  createNewBooking
} from "../firebase";
import { useSnackbar } from "react-simple-snackbar";
import { snackbarOptions } from "../theme";

import SignIn from "../components/authentication/SignIn";
import SignUp from "../components/authentication/SignUp";
import ButtonLargeSubmit from "../components/buttons/ButtonLargeSubmit";
import ButtonLarge from "../components/buttons/ButtonLarge";
import { FormControl } from "@mui/material";
import InputBoxed from "../components/InputBoxed";
import InputBoxedSelect from "../components/InputBoxedSelect";
import InputBoxedSelectLocations from "../components/InputBoxedSelectLocations";
import ButtonLargeInverted from "../components/buttons/ButtonLargeInverted";
import Spacer from "../components/Spacer";
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddLocationDrawer from "../components/authentication/AddLocationDrawer";
import SendIcon from "@mui/icons-material/Send";

import AuthDrawer from "../components/authentication/AuthDrawer";

import { useAuthState } from "react-firebase-hooks/auth";
import { useDocument } from "react-firebase-hooks/firestore";
import { useCart } from "react-use-cart";

import styled from "styled-components";
import OptionalExtrasModal from "./OptionalExtrasModal";

const PageContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  padding: 0 0 12px 0;
  text-align: left;
  font-family: ${(props) => props.theme.fontPrimary};
  color: ${(props) => props.theme.textDark};
  box-shadow: ${(props) => props.theme.cardBoxShadow};
  background-color: ${(props) => props.theme.backgroundLight};
  border-radius: 4px;
  transition: ${(props) => props.theme.allTransition};
  .booking-form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 16px;
  }
  .li-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 28px;
  }
`;

// the below form component should be moved to its own file
function BookingDetailsForm() {
  const [openSnackbar] = useSnackbar(snackbarOptions);
  const [submitStatus, setSubmitStatus] = useState(""); //"", "submitting", "success" or "error"
  const [addLocationOpen, setAddLocationOpen] = useState(false);
  const [userData, setUserData] = useState("");
  const [checkFields, setCheckFields] = useState(false);

  const [companyName, setCompanyName] = useState("");
  const [companyABN, setCompanyABN] = useState("");
  const [purchaseOrder, setPurchaseOrder] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactPosition, setContactPosition] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [locations, setLocations] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [deliveryInstructions, setDeliveryInstructions] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [deliveryTime, setDeliveryTime] = useState("");
  const [offHireDate, setOffHireDate] = useState("");

  const history = useHistory();
  const [user] = useAuthState(auth);
  const { items, emptyCart } = useCart();

  const ResetState = () => {
    // setCompanyName("");
    // setContactName("");
    // setContactPhone("");
    // setSelectedLocation("");
    // setSelectedFamily("");
    // setAssetNumber("");
    // setPickupInstructions("");
    // setOffHireDate("");
  };

  const [value, loading, error] = useDocument(
    firebase.firestore().collection("users").doc(user.uid),
    {
      snapshotListenOptions: { includeMetadataChanges: true }
    }
  );

  useEffect(() => {
    if (value) {
      setUserData(value.data());
    } else return null;
  }, [value]);

  useEffect(() => {
    UpdateLocalState();
  }, [userData]);

  const MapLocations = () => {
    if (userData.locations) {
      const temp = userData.locations.map((location) => ({
        value: location,
        label: `${location.streetAddress}, ${location.city}, ${location.postcode} `
      }));
      const temp2 = temp.unshift({ value: "", label: "" });
      // setPickupLocations(temp);
      setLocations(temp);
    }
  };

  const UpdateLocalState = () => {
    setCompanyName(userData.companyName);
    setContactName(userData.name);
    setContactPhone(userData.phoneNumber);
    setCompanyABN(userData.companyABN);
    setContactPosition(userData.companyPosition);
    setContactEmail(userData.email);
    MapLocations();
    console.log(userData);
  };

  const onError = () => {
    console.log("onError called");
    setCheckFields(true);
    openSnackbar("Please complete all of the highlighted fields");
  };

  const FilterOptionalExtras = (modelId) => {
    const optionalExtras = items.map((item) =>
      item.type === "optional_extras" && modelId === item.childOf
        ? {
            family: item.type,
            model: item.id,
            data: item.data,
            optionalExtrafor: item.childOf,
            quantity: item.quantity
          }
        : null
    );
    const optionalExtrasFiltered = optionalExtras.filter(
      (optionalExtra) => optionalExtra != null
    );
    return optionalExtrasFiltered;
  };

  const FormattedCartContents = () => {
    const formatedCartContents = items.map((item) =>
      item.type !== "optional_extras"
        ? {
            family: item.type,
            manufacturer: item.data.manufacturer,
            model: item.id,
            data: item.data,
            quantity: item.quantity,
            optionalExtras: FilterOptionalExtras(item.id)
          }
        : null
    );
    const filteredCartContents = formatedCartContents.filter(
      (formatedCartContent) => formatedCartContent != null
    );
    // console.log(filteredCartContents);
    return filteredCartContents;
  };

  return (
    <div className="booking-form">
      <h2>Please confirm your details:</h2>
      {/* <p>Please enter the details below to request off hire of equipment:</p> */}
      <FormControl required>
        <InputBoxed
          label="Company Name"
          id="company-name"
          name="company-name"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          error={checkFields && !companyName ? true : false}
        />
      </FormControl>
      <FormControl required>
        <InputBoxed
          label="ABN"
          id="company-ABN"
          name="company-ABN"
          value={companyABN}
          onChange={(e) => setCompanyABN(e.target.value)}
          error={checkFields && !companyABN ? true : false}
        />
      </FormControl>{" "}
      <FormControl required>
        <InputBoxed
          label="Purchase Order"
          id="purchase-order"
          name="purchase-order"
          value={purchaseOrder}
          onChange={(e) => setPurchaseOrder(e.target.value)}
          error={checkFields && !purchaseOrder ? true : false}
        />
      </FormControl>
      <FormControl required>
        <InputBoxed
          label="Contact Name"
          id="contact-name"
          name="contact-name"
          value={contactName}
          onChange={(e) => setContactName(e.target.value)}
          error={checkFields && !contactName ? true : false}
        />
      </FormControl>
      <FormControl required>
        <InputBoxed
          label="Contact Position"
          id="contact-position"
          name="contact-position"
          value={contactPosition}
          onChange={(e) => setContactPosition(e.target.value)}
          error={checkFields && !contactPosition ? true : false}
        />
      </FormControl>
      <FormControl required>
        <InputBoxed
          label="Contact Mobile"
          id="contact-phone"
          name="contact-phone"
          value={contactPhone}
          onChange={(e) => setContactPhone(e.target.value)}
          error={checkFields && !contactPhone ? true : false}
        />
      </FormControl>
      <FormControl required>
        <InputBoxed
          label="Contact Email"
          id="contact-email"
          name="contact-email"
          value={contactEmail}
          onChange={(e) => setContactEmail(e.target.value)}
          error={checkFields && !contactEmail ? true : false}
        />
      </FormControl>
      {locations !== "" ? (
        <FormControl required>
          <InputBoxedSelectLocations
            label="Select Delivery Location"
            id="select-location"
            name="select-location"
            value={selectedLocation}
            //objects cannot be used as the value prop, so setting index number of the array
            // as the value and using that to pass the correct address on Submit
            //(pickupLocations[selectedLocation].value)
            onChange={(e) => setSelectedLocation(e.target.value)}
            options={locations}
            error={checkFields && !selectedLocation ? true : false}
          />
        </FormControl>
      ) : (
        <p>Loading Locations</p>
      )}
      <Button
        className="add-location-button"
        startIcon={<AddCircleOutlineIcon fontSize="inherit" />}
        // onClick={() => console.log(pickupLocations)}
        onClick={() => setAddLocationOpen(true)}
      >
        Add Location
      </Button>
      {/* <Button
        className="add-location-button"
        startIcon={<AddCircleOutlineIcon fontSize="inherit" />}
        // onClick={() => console.log(pickupLocations)}
        onClick={() => console.log(pickupLocations[selectedLocation].value)}
      >
        log selectedlocation
      </Button> */}
      <FormControl required>
        <InputBoxed
          label="Special Delivery Instructions"
          id="delivery-instructions"
          name="delivery-instructions"
          value={deliveryInstructions}
          onChange={(e) => setDeliveryInstructions(e.target.value)}
          multiline
          rows={3}
        />
      </FormControl>
      <FormControl required>
        <InputBoxed
          label="Requested Delivery Date"
          id="delivery-date"
          name="delivery-date"
          value={deliveryDate}
          type="date"
          InputLabelProps={{
            shrink: true
          }}
          min="2020-12-15"
          max="2030-12-15"
          onChange={(e) => setDeliveryDate(e.target.value)}
          error={checkFields && !deliveryDate ? true : false}
        />
      </FormControl>
      <FormControl required>
        <InputBoxed
          label="Preferred Delivery Time"
          id="delivery-time"
          name="delivery-time"
          value={deliveryTime}
          onChange={(e) => setDeliveryTime(e.target.value)}
          error={checkFields && !deliveryTime ? true : false}
        />
      </FormControl>
      <FormControl required>
        <InputBoxed
          label="Contract End Date (approximate)"
          id="contract-end-date"
          name="contract-end-date"
          value={offHireDate}
          type="date"
          InputLabelProps={{
            shrink: true
          }}
          min="2020-12-15"
          max="2030-12-15"
          onChange={(e) => setOffHireDate(e.target.value)}
          error={checkFields && !offHireDate ? true : false}
        />
      </FormControl>
      <ButtonLargeSubmit
        text="Submit Request"
        varient="primary"
        endIcon={<SendIcon />}
        submitStatus={submitStatus}
        // onClick={() => onSubmitOffHire()}
        onClick={() => {
          companyName &&
          companyABN &&
          purchaseOrder &&
          contactName &&
          contactPosition &&
          contactPhone &&
          contactEmail &&
          selectedLocation &&
          deliveryDate &&
          deliveryTime &&
          offHireDate
            ? onSubmitNewBooking()
            : onError();
        }}
      />
      {/* <button onClick={() => FormattedCartContents()}>log</button> */}
      {/* <button onClick={() => console.log(items)}>log</button> */}
      <AddLocationDrawer open={addLocationOpen} setOpen={setAddLocationOpen} />
    </div>
  );

  // <div>
  //         {data.results.map((item, i) => (
  //           <ProductSingleCard doc={item} />
  //         ))}
  //         <Spacer />
  //       </div>

  async function onSubmitNewBooking() {
    const cartContents = FormattedCartContents();
    setSubmitStatus("submitting");
    try {
      await createNewBooking(
        companyName,
        companyABN,
        purchaseOrder,
        contactName,
        contactPosition,
        contactPhone,
        contactEmail,
        locations[selectedLocation].value,
        deliveryInstructions,
        deliveryDate + " (YYYY-MM-DD)",
        deliveryTime,
        offHireDate + " (YYYY-MM-DD)",
        cartContents
      );

      setSubmitStatus("success");
      ResetState();
      console.log("passed createNewBooking");
      emptyCart();
      history.push("/request-confirmation");
    } catch (error) {
      setSubmitStatus("error");
      console.log(error.message);
    }
  }
}

export default function BookingDetailsSection() {
  const [user] = useAuthState(auth);
  const [authDrawerOpen, setAuthDrawerOpen] = useState(false);

  let { path, url } = useRouteMatch();

  return (
    <PageContainer>
      {user ? (
        <BookingDetailsForm />
      ) : (
        <div className="content">
          <h2>
            Please login or create an account before submitting a quote request.
          </h2>
          {/* <ButtonLarge text="Login or Register" varient="dark" />
          <ButtonLargeInverted text="Create an account" /> */}

          <ButtonLarge
            text="Login or Register"
            varient="dark"
            onClick={() => setAuthDrawerOpen(true)}
          />

          {/* <Switch>
              <Route path={`${path}/signin`} component={SignIn} />
              <Route path={`${path}/signup`} component={SignUp} />
              <Route path={path}>
                {user ? null : <Redirect push to={`${path}/signin`} />}
              </Route>
            </Switch> */}
          <AuthDrawer open={authDrawerOpen} setOpen={setAuthDrawerOpen} />
        </div>
      )}
    </PageContainer>
  );
}
