import React, { useState, useEffect } from "react";
import {
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";
import BookingSummaryListItem from "../components/BookingSummaryListItem";
import { useCart } from "react-use-cart";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import Spacer from "../components/Spacer";
import Button from "@mui/material/Button";
import ButtonLarge from "../components/buttons/ButtonLarge";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import styled from "styled-components";
import { Divider } from "@mui/material";

const PageContainer = styled.div`
  position: relative; 
  width: 100%;
  height: 100%;
  text-align: left;
  font-family: ${(props) => props.theme.fontPrimary};
  color: ${(props) => props.theme.textDark};
  /* background-color: ${(props) => props.theme.backgroundGrey50}; */

  transition: ${(props) => props.theme.allTransition};
  .booking-list {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 16px;

  }
  .button-container{
    padding: 0 24px 24px;
  }
 
  .add-extras-button .MuiButton-label{
    margin: 8px 0 0 0 ;
  }
  .add-extras-button .MuiButton-label{
    font-size: 0.75rem;
    font-weight: 700;
  }
`;

export default function BookingSummaryList(props) {
  let { path, url } = useRouteMatch();
  const { subcategory, filter } = useParams();
  const {
    isEmpty,
    cartTotal,
    totalUniqueItems,
    items,
    updateItemQuantity,
    removeItem,
    emptyCart
  } = useCart();

  const CheckForExtras = (cartItem) => {
    return items.map((item) => (item.type === "optional_extras" ? item : null));
  };

  return (
    <PageContainer>
      <div className="booking-list">
        {/* Main Product Card */}
        <BookingSummaryListItem item={props.item} />
        {/* Map Extras */}
        {items.map((item, i) =>
          item.type === "optional_extras" && item.childOf === props.item.id ? (
            <BookingSummaryListItem item={item} index={i} />
          ) : null
        )}
      </div>
    </PageContainer>
  );
}
