import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import styled from "styled-components";

const StyledButton = styled(Button)`
  width: ${(props) => (props.width ? props.width : "100%")};
  max-width: 480px;
  height: 48px;
  background-color: ${(props) =>
    props.varient === "primary" ? props.theme.primary : props.theme.grey900};
  border-radius: 0px;
  box-shadow: none;
  margin: ${(props) => props.margin};
  padding: 12px 24px;
  font-family: ${(props) => props.theme.fontPrimary};
  font-size: ${(props) => props.theme.buttonFontSize};
  font-weight: 700;
  color: ${(props) => props.theme.white};
  transform: scale(1);
  transition: 0.2s all ease-out;
  .MuiButton-label {
    text-decoration: none;
  }
  &:hover {
    background: ${(props) =>
      props.varient === "primary"
        ? props.theme.primaryDark
        : props.theme.primary};
    box-shadow: none;
  }
  &:active {
    background-color: ${(props) =>
      props.varient === "primary" ? props.theme.primary : props.theme.grey900};
    transform: scale(1.05);
    transition: 0.1s all ease-out;
  }
  @media screen and (max-width: 767px) {
    font-size: ${(props) => props.theme.buttonFontSize};
    margin: ${(props) => props.margin};
  }
`;

export default function ButtonLarge(props) {
  const [addToCartButtonText, setAddToCartButtonText] = useState(
    "Add To Quote"
  );
  const [itemInCart, setItemInCart] = useState(false);

  const AddToCartButtonText = () => {
    if (props.items.some((e) => e.id === props.doc.uid)) {
      setAddToCartButtonText(`View in Cart`);
      setItemInCart(true);
    } else {
      setAddToCartButtonText("Add to Quote");
      setItemInCart(false);
    }
  };

  useEffect(() => {
    if (props.items) {
      AddToCartButtonText();
    }
  }, [props.items]);

  return (
    <StyledButton
      className="button-large"
      variant="contained"
      onClick={itemInCart ? null : props.onClick}
      component={itemInCart || props.linkTo ? Link : "null"}
      to={itemInCart ? "/cart" : props.linkTo}
      margin={props.margin ? props.margin : "16px 0 0 0"}
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      varient={props.varient} //dark or primary
      width={props.width}
      type={props.type}
    >
      {props.type === "AddToCart" ? addToCartButtonText : props.text}
    </StyledButton>
  );
}
