import React from "react";
import ProductFamilyNavButton from "./buttons/ProductFamilyNavButton";

import styled from "styled-components";

const StyledProductFamilyNav = styled.div`
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.white};
  z-index: 500;
`;

export default function ProductFamilyNav(props) {
  return (
    <StyledProductFamilyNav>
      <ProductFamilyNavButton
        text="Telehandlers"
        category={props.category}
        value="telehandlers"
      />
      <ProductFamilyNavButton
        text="Boom Lifts"
        category={props.category}
        value="boom_lifts"
      />
      <ProductFamilyNavButton
        text="Scissor Lifts"
        category={props.category}
        value="scissor_lifts"
      />
      <ProductFamilyNavButton
        text="Vertical Man Lifts"
        category={props.category}
        value="vertical_lifts"
      />
      <ProductFamilyNavButton
        text="ForkLifts"
        category={props.category}
        value="forklifts"
      />
    </StyledProductFamilyNav>
  );
}
