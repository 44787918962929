import React from "react";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useCart } from "react-use-cart";

import styled from "styled-components";

const StyledCartIcon = styled.div`
  color: ${(props) => props.theme.textDark};
  margin: 6px 10px 0 0;
  .MuiBadge-badge {
    background-color: ${(props) => props.theme.primary};
    color: white;
    z-index: 500;
    /* transform: translateY(-12px); */
  }
`;

export default function CartIconWithBadge() {
  const { totalItems } = useCart();

  return (
    <StyledCartIcon>
      <Badge badgeContent={totalItems}>
        <ShoppingCartIcon />
      </Badge>
    </StyledCartIcon>
  );
}
