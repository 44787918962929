import React from "react";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useCart } from "react-use-cart";

import styled from "styled-components";

const StyledAccountIconButton = styled(IconButton)`
  color: ${(props) => props.theme.textDark};
  margin: 6px 10px 0 0;
  .MuiBadge-badge {
    background-color: ${(props) => props.theme.primary};
    color: white;
    z-index: 500;
  }
`;

export default function AccountButton() {
  const { totalItems } = useCart();

  return (
    <StyledAccountIconButton
      aria-label="Account"
      component={Link}
      to="/account"
    >
      <AccountCircleIcon />
    </StyledAccountIconButton>
  );
}
