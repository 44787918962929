import React, { useContext } from "react";
import { MainContext } from "../contexts/MainContext";
import { Helmet } from "react-helmet";

import "../styles.css";

import styled from "styled-components";

const PageContainer = styled.div`
  text-align: left;
  height: 100%;
  font-family: ${(props) => props.theme.fontPrimary};
  color: ${(props) => props.theme.textDark};
  /* background-color: ${(props) => props.theme.backgroundGrey50}; */
  background-color: ${(props) => props.theme.backgroundLight};
  transition: ${(props) => props.theme.allTransition};
  .content {
    height: calc(100vh - 112px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: ${(props) => (props.isDesktop ? "center" : "flex-start")};
    padding: 24px;
  }
  .li-container {
    display: flex;
    justify-content: center;
    align-items: center;  
    max-height: 28px;
  }
  .heading{
    margin: 0 0 8px 0;
  }
  
`;

export default function QuickQuoteConfirmationPage() {
  const { isDesktop } = useContext(MainContext);
  return (
    <PageContainer isDesktop={isDesktop}>
      <Helmet>
        <title>{`Quote Request Sent | GTH Equipment`}</title>
      </Helmet>
      <div className="content">
        <h1 className="heading">Thank you for your enquiry!</h1>
        <p>Unfortunately live pricing information for the product you selected is not currently available.</p>
        <p>A member of our team will be in touch with you shortly.</p>
        <p>Alternatively, call us on <a href="tel: 1300608608">1300 608 608</a> for pricing information.</p>
      </div>
    </PageContainer>
  );
}
