import React from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";

import styled from "styled-components";

const StyledBreadcrumbs = styled(Breadcrumbs)`
  .MuiTypography-body1 {
    margin: 16px 0;
    font-family: Titillium Web;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    color: #9f9f9f;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0;
  }
  .breadcrumb {
    font-family: Titillium Web;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    color: #9f9f9f;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.1s color ease-out;
    letter-spacing: 0;
  }
  .breadcrumb:hover {
    color: ${(props) => props.theme.textDark};
  }
`;

export default function BreadcrumbsComponent(props) {
  return (
    <StyledBreadcrumbs aria-label="breadcrumbs">
      <Link className="breadcrumb" to={`/`}>
        Hire
      </Link>
      <Link className="breadcrumb" to={`/${props.category}/all`}>
        {props.productFamily}
      </Link>
      <Link
        className="breadcrumb"
        to={`/${props.category}/${props.subcategory}`}
      >
        {props.subcategory}
      </Link>
      <Typography>{props.model}</Typography>
    </StyledBreadcrumbs>
  );
}
