import styled, { css } from "styled-components";

export const slideTransitionEnter = `
&.page-enter {
  opacity: 0;
  transform: scale(0.92);
}
&.page-enter-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1) 0.1s;
}
&.page-exit {
  opacity: 1;
}
&.page-exit-active {
  opacity: 0;
  animation: opacity 0.1s cubic-bezier(0.4, 0, 1, 1) 0s;
}
`;
