import React from "react";
import ReactDOM from "react-dom";
import MainContextProvider from "./contexts/MainContext.js";
import UserContextProvider from "./contexts/UserContext.js";
import WebFont from "webfontloader";
// import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import Favicon from "react-favicon";
import StylesProvider from '@mui/styles/StylesProvider';
import { BrowserRouter } from "react-router-dom";
import { CartProvider } from "react-use-cart";
import MediaQuery from "react-responsive";

import App from "./App";
import * as serviceWorker from "./serviceWorker";


// ReactGA.initialize("UA-176070772-xx**", {
//   debug: false,
//   titleCase: false,
//   gaOptions: {
//     userId: 123
//   }
// });

const tagManagerArgs = {
  gtmId: "GTM-W7TDFRZ"
  // dataLayer: {
  //   userId: "001",
  //   userProject: "project"
  // }
};

TagManager.initialize(tagManagerArgs);

WebFont.load({
  google: {
    families: [
      "Titillium Web:300,400,500,600,700,800,900",
      "Raleway:300,400,500,600,700,800",
      "Open Sans:300,400,600,800",
      "sans-serif"
    ]
  }
});

const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    <StylesProvider injectFirst>
    <BrowserRouter>
      <MainContextProvider>
      <UserContextProvider>
        <Favicon url="https://firebasestorage.googleapis.com/v0/b/gth-equipment.appspot.com/o/icons%2Ffavicon.ico?alt=media&token=a51c061e-a314-4d35-9f84-a806e7381ff9" />
          <CartProvider>
            <MediaQuery maxWidth={927}>
              <App />
            </MediaQuery>
            <MediaQuery minWidth={928}>
              <App />
              {/* <DesktopAppTemp /> */}
            </MediaQuery>
          </CartProvider>
       </UserContextProvider>
      </MainContextProvider>
      </BrowserRouter>
    </StylesProvider>
  </React.StrictMode>,
  rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
