import React from "react";
import { useHistory } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import styled from "styled-components";

const StyledDiv = styled.div`
  margin: ${(props) => props.margin};
  && svg {
    fill: ${(props) => props.theme.textDark};
  }
`;

export default function BackButton(props) {
  const history = useHistory();

  const Back = () => {
    console.log(history);
    history.length > 2 ? history.goBack() : history.push(props.noHistoryPath);
  };

  const LinkTo = () => {
    history.push(props.to);
  };

  return (
    <StyledDiv className="back-button" margin={props.margin}>
      <IconButton
        aria-label="back"
        onClick={() => (props.to ? LinkTo() : Back())}
        size="large">
        <KeyboardBackspaceIcon />
      </IconButton>
    </StyledDiv>
  );
}
