import React, { useState, useEffect } from "react";
import {
  Route,
  Switch,
  Redirect,
  useRouteMatch,
  useHistory
} from "react-router-dom";
import firebase, {
  logout,
  auth,
  updateDBEntry,
  removeLocationFromDB,
  createQuickQuote
} from "../firebase";
import { useSnackbar } from "react-simple-snackbar";
import { snackbarOptions } from "../theme";

import SignIn from "./authentication/SignIn";
import SignUp from "./authentication/SignUp";
import ButtonLargeSubmit from "./buttons/ButtonLargeSubmit";
import ButtonLarge from "./buttons/ButtonLarge";
import { FormControl } from "@mui/material";
import InputBoxed from "./InputBoxed";
import InputBoxedSelect from "./InputBoxedSelect";
import InputBoxedSelectLocations from "./InputBoxedSelectLocations";
import ButtonLargeInverted from "./buttons/ButtonLargeInverted";
import Spacer from "./Spacer";
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddLocationDrawer from "./authentication/AddLocationDrawer";
import SendIcon from "@mui/icons-material/Send";

import AuthDrawer from "./authentication/AuthDrawer";

import { useAuthState } from "react-firebase-hooks/auth";
import { useDocument } from "react-firebase-hooks/firestore";
import { useCart } from "react-use-cart";

import styled from "styled-components";
import OptionalExtrasModal from "./OptionalExtrasModal";

const PageContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  padding: 0 0 12px 0;
  margin: 8px 0 0 0;
  text-align: left;
  font-family: ${(props) => props.theme.fontPrimary};
  color: ${(props) => props.theme.textDark};
  box-shadow: ${(props) => props.theme.cardBoxShadow};
  background-color: ${(props) => props.theme.backgroundLight};
  border-radius: 4px;
  transition: ${(props) => props.theme.allTransition};
  .booking-form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 16px;
  }
  .li-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 28px;
  }
`;

// the below form component should be moved to its own file
function QuickQuoteDetailsForm() {
  const [openSnackbar] = useSnackbar(snackbarOptions);
  const [submitStatus, setSubmitStatus] = useState(""); //"", "submitting", "success" or "error"
  const [addLocationOpen, setAddLocationOpen] = useState(false);
  const [userData, setUserData] = useState("");
  const [checkFields, setCheckFields] = useState(false);

  const [companyName, setCompanyName] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [deliveryPostcode, setDeliveryPostcode] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [offHireDate, setOffHireDate] = useState("");

  const history = useHistory();
  const [user] = useAuthState(auth);
  const { items, emptyCart } = useCart();

  const ResetState = () => {
    // setCompanyName("");
    // setContactName("");
    // setContactPhone("");
    // setSelectedLocation("");
    // setSelectedFamily("");
    // setAssetNumber("");
    // setPickupInstructions("");
    // setOffHireDate("");
  };

  useEffect(() => {
    UpdateLocalState();
  }, [userData]);

  const UpdateLocalState = () => {
    setCompanyName(userData.companyName);
    setContactName(userData.name);
    setContactPhone(userData.phoneNumber);
    setContactEmail(userData.email);
    console.log(userData);
  };

  const onError = () => {
    console.log("onError called");
    setCheckFields(true);
    openSnackbar("Please complete all of the highlighted fields");
  };

  const FilterOptionalExtras = (modelId) => {
    const optionalExtras = items.map((item) =>
      item.type === "optional_extras" && modelId === item.childOf
        ? {
            family: item.type,
            model: item.id,
            data: item.data,
            optionalExtrafor: item.childOf,
            quantity: item.quantity
          }
        : null
    );
    const optionalExtrasFiltered = optionalExtras.filter(
      (optionalExtra) => optionalExtra != null
    );
    return optionalExtrasFiltered;
  };

  const FormattedCartContents = () => {
    const formatedCartContents = items.map((item) =>
      item.type !== "optional_extras"
        ? {
            family: item.type,
            manufacturer: item.data.manufacturer,
            model: item.id,
            data: item.data,
            quantity: item.quantity,
            optionalExtras: FilterOptionalExtras(item.id)
          }
        : null
    );
    const filteredCartContents = formatedCartContents.filter(
      (formatedCartContent) => formatedCartContent != null
    );
    // console.log(filteredCartContents);
    return filteredCartContents;
  };

  return (
    <div className="booking-form">
      <h2>Please confirm your details:</h2>
      {/* <p>Please enter the details below to request off hire of equipment:</p> */}
      <FormControl required>
        <InputBoxed
          label="Company Name"
          id="company-name"
          name="company-name"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          error={checkFields && !companyName ? true : false}
        />
      </FormControl>
      <FormControl required>
        <InputBoxed
          label="Contact Name"
          id="contact-name"
          name="contact-name"
          value={contactName}
          onChange={(e) => setContactName(e.target.value)}
          error={checkFields && !contactName ? true : false}
        />
      </FormControl>
      <FormControl required>
        <InputBoxed
          label="Contact Mobile"
          id="contact-phone"
          name="contact-phone"
          value={contactPhone}
          onChange={(e) => setContactPhone(e.target.value)}
          error={checkFields && !contactPhone ? true : false}
        />
      </FormControl>
      <FormControl required>
        <InputBoxed
          label="Contact Email"
          id="contact-email"
          name="contact-email"
          value={contactEmail}
          onChange={(e) => setContactEmail(e.target.value)}
          error={checkFields && !contactEmail ? true : false}
        />
      </FormControl>
      <FormControl required>
        <InputBoxed
          label="Delivery Postcode"
          id="delivery-postcode"
          name="delivery-postcode"
          value={deliveryPostcode}
          onChange={(e) => setDeliveryPostcode(e.target.value)}
          error={checkFields && !deliveryPostcode ? true : false}
        />
      </FormControl>
      <FormControl required>
        <InputBoxed
          label="Hire Start Date (approximate)"
          id="delivery-date"
          name="delivery-date"
          value={deliveryDate}
          type="date"
          InputLabelProps={{
            shrink: true
          }}
          min="2020-12-15"
          max="2030-12-15"
          onChange={(e) => setDeliveryDate(e.target.value)}
          error={checkFields && !deliveryDate ? true : false}
        />
      </FormControl>
      <FormControl required>
        <InputBoxed
          label="Hire End Date (approximate)"
          id="contract-end-date"
          name="contract-end-date"
          value={offHireDate}
          type="date"
          InputLabelProps={{
            shrink: true
          }}
          min="2020-12-15"
          max="2030-12-15"
          onChange={(e) => setOffHireDate(e.target.value)}
          error={checkFields && !offHireDate ? true : false}
        />
      </FormControl>
      <ButtonLargeSubmit
        text="Get Quick Quote"
        varient="primary"
        endIcon={<SendIcon />}
        submitStatus={submitStatus}
        // onClick={() => onSubmitOffHire()}
        onClick={() => {
          companyName &&
          contactName &&
          contactPhone &&
          contactEmail &&
          deliveryPostcode &&
          deliveryDate &&
          offHireDate
            ? onSubmitNewBooking()
            : onError();
        }}
      />
      {/* <button onClick={() => FormattedCartContents()}>log</button> */}
      {/* <button onClick={() => console.log(items)}>log</button> */}
      <AddLocationDrawer open={addLocationOpen} setOpen={setAddLocationOpen} />
    </div>
  );

  // <div>
  //         {data.results.map((item, i) => (
  //           <ProductSingleCard doc={item} />
  //         ))}
  //         <Spacer />
  //       </div>

  async function onSubmitNewBooking() {
    const cartContents = FormattedCartContents();
    setSubmitStatus("submitting");
    window.dataLayer.push({ event: "get_quote_form_submission" });
    try {
      await createQuickQuote(
        companyName,
        contactName,
        contactPhone,
        contactEmail,
        deliveryPostcode,
        deliveryDate + " (YYYY-MM-DD)",
        offHireDate + " (YYYY-MM-DD)",
        cartContents
      );

      setSubmitStatus("success");
      ResetState();
      console.log("passed createNewBooking");
      emptyCart();
      history.push("/quick-quote-request-confirmation");
    } catch (error) {
      setSubmitStatus("error");
      console.log(error.message);
    }
  }
}

export default function QuickQuoteDetailsSection() {

  return (
    <PageContainer>
        <QuickQuoteDetailsForm />
    </PageContainer>
  );
}
