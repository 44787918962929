import React, { useState, useContext, useEffect } from "react";
import { MainContext } from "../contexts/MainContext.js";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import styled from "styled-components";

const StyledProductCarousel = styled(Carousel)`
  /* background-color: ${(props) => props.theme.backgroundLight};
  border-radius: 4px;
  box-shadow: ${(props) => props.theme.cardBoxShadow}; */
  .carousel .slide{
    background-color: ${(props) => props.theme.backgroundLight};
  }
`;

export default function ProductImageGallery({ gallery }) {
  const { currentProduct, setCurrentProduct } = useContext(MainContext);

  useEffect(() => {}, []);

  return (
    <StyledProductCarousel
      className="product-gallery"
      showStatus={false}
      // width="calc(100%-100px)"
    >
      {gallery
        ? gallery.items.map((item, i) => (
            <div>
              <img src={item.gallery_image.url} alt={item.gallery_image.alt} />
            </div>
          ))
        : null}
      {/* <video width="320" height="240" controls>
        <source src="https://youtu.be/GMcmzBZo2_4" type="video/ogg" />
      </video> */}
    </StyledProductCarousel>
  );
}
