import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import styled from "styled-components";

const StyledTableContainer = styled(TableContainer)`
  .MuiTableCell-root {
    padding: 16px 0;
  }
  .table-field {
    font-family: Titillium Web;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: ${(props) => props.theme.textDark};
  }
  .table-value {
    font-family: Titillium Web;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: ${(props) => props.theme.textDark};
  }
`;

function createData(field, value) {
  return { field, value };
}

export default function SpecificationsTable(props) {
  const [rows, setRows] = useState([]);
  //How am I going to map all the relevant fields, for all 5 families?
  // Maybe I just create rowsTelehandlers, rowsBoomLifts etc?

  const setRowsTelehandlers = () => {
    setRows([
      createData("Product Type", props.data.product_type[0].text),
      createData("Fuel Type", props.data.sub_product_family),
      createData("Maximum Lift Height", props.data.maximum_lift_height + "m"),
      createData(
        "Maximum Lift Capacity",
        props.data.maximum_lift_capacity + "kg"
      ),
      createData(
        "Lift Capacity at Full Height",
        props.data.lift_capacity_at_full_height + "kg"
      ),
      createData(
        "Maximum Forward Reach",
        props.data.maximum_forward_reach + "m"
      ),
      createData(
        "Lift Capacity at Full Reach",
        props.data.lift_capacity_at_full_reach + "kg"
      ),
      createData("Overall Length", props.data.overall_length + "m"),
      createData("Overall Width", props.data.overall_width + "m"),
      createData("Overall Height", props.data.overall_height + "m"),
      createData("Ground Clearance", props.data.ground_clearance + "m"),
      createData(
        "Width with Stabilizers Down",
        props.data.width_with_stabilizers_down
          ? props.data.width_with_stabilizers_down + "m"
          : "N/A"
      ),
      createData(
        "Overall Weight with Forks",
        props.data.overall_weight_with_forks + "kg"
      )
    ]);
  };

  const setRowsBoomLifts = () => {
    setRows([
      createData("Product Type", props.data.product_type[0].text),
      createData("Fuel Type", props.data.sub_product_family),
      createData(
        "Non-Marking Tyres",
        `${props.data.non_marking_tyres ? "Yes" : "No"}`
      ),
      createData("Platform Height", props.data.platform_height + "m"),
      createData("Working Height", props.data.working_height + "m"),
      createData("Overall Length", props.data.overall_length + "m"),
      createData("Overall Width", props.data.overall_width + "m"),
      createData("Stowed Height", props.data.stowed_height + "m"),
      createData("Platform Capacity", props.data.platform_capacity + "kg"),
      createData("Up & Over Clearance", props.data.up_and_over_clearance + "m"),
      createData("Horizontal Outreach", props.data.horizontal_outreach + "m"),
      createData("Platform Size", props.data.platform_size[0].text),
      createData("Overall Weight", props.data.overall_weight + "kg")
    ]);
  };

  const setRowsScissorLifts = () => {
    setRows([
      createData("Product Type", props.data.product_type[0].text),
      createData("Fuel Type", props.data.sub_product_family),
      createData(
        "Non-Marking Tyres",
        `${props.data.non_marking_tyres ? "Yes" : "No"}`
      ),
      createData("Platform Height", props.data.platform_height + "m"),
      createData("Working Height", props.data.working_height + "m"),
      createData("Overall Length", props.data.overall_length + "m"),
      createData("Overall Width", props.data.overall_width + "m"),
      createData("Platform Capacity", props.data.platform_capacity + "kg"),
      createData(
        "Platform Extension",
        `${
          props.data.platform_extension
            ? props.data.platform_extension + "m"
            : "N/A"
        }`
      ),
      createData("Platform Size", props.data.platform_size[0].text),
      createData("Overall Weight", props.data.overall_weight + "kg"),
      createData("Indoor Use", `${props.data.indoor_use ? "Yes" : "No"}`),
      createData("Outdoor Use", `${props.data.outdoor_use ? "Yes" : "No"}`),
      createData(
        "Indoor # Occupants",
        `${
          props.data.indoor_use_number_of_occupants
            ? props.data.indoor_use_number_of_occupants
            : "N/A"
        }`
      ),
      createData(
        "Outdoor # Occupants",
        `${
          props.data.outdoor_use_number_of_occupants
            ? props.data.outdoor_use_number_of_occupants
            : "N/A"
        }`
      )
    ]);
  };

  const setRowsVerticalLifts = () => {
    setRows([
      createData("Product Type", props.data.product_type[0].text),
      createData(
        "Fuel Type",
        props.data.sub_product_family === "push-around"
          ? "Push Around"
          : props.data.sub_product_family
      ),
      createData(
        "Non-Marking Tyres",
        `${props.data.non_marking_tyres ? "Yes" : "No"}`
      ),
      createData("Platform Height", props.data.platform_height + "m"),
      createData("Working Height", props.data.working_height + "m"),
      createData("Overall Length", props.data.overall_length + "m"),
      createData("Overall Width", props.data.overall_width + "m"),
      createData("Platform Capacity", props.data.platform_capacity + "kg"),
      createData(
        "Platform Extension",
        `${
          props.data.platform_extension
            ? props.data.platform_extension + "m"
            : "N/A"
        }`
      ),
      createData("Platform Size", props.data.platform_size[0].text),
      createData("Overall Weight", props.data.overall_weight + "kg"),
      createData("Indoor Use", `${props.data.indoor_use ? "Yes" : "No"}`),
      createData("Outdoor Use", `${props.data.outdoor_use ? "Yes" : "No"}`),
      createData(
        "Indoor # Occupants",
        `${
          props.data.indoor_use_number_of_occupants
            ? props.data.indoor_use_number_of_occupants
            : "N/A"
        }`
      ),
      createData(
        "Outdoor # Occupants",
        `${
          props.data.outdoor_use_number_of_occupants
            ? props.data.outdoor_use_number_of_occupants
            : "N/A"
        }`
      )
    ]);
  };

  const setRowsForklifts = () => {
    setRows([
      createData("Product Type", props.data.product_type[0].text),
      createData("Fuel Type", props.data.sub_product_family),
      createData("Maximum Lift Height", props.data.maximum_lift_height + "m"),
      createData(
        "Maximum Lift Capacity",
        props.data.maximum_lift_capacity + "kg"
      ),
      createData(
        "Lift Capacity at Full Height",
        props.data.lift_capacity_at_full_height + "kg"
      ),
      createData("Overall Length", props.data.overall_length + "m"),
      createData("Overall Width", props.data.overall_width + "m"),
      createData("Overall Height", props.data.overall_height + "m"),
      createData(
        "Overall Weight with Forks",
        props.data.overall_weight_with_forks + "kg"
      )
    ]);
  };

  useEffect(() => {
    switch (props.subcategory) {
      case "telehandlers":
        setRowsTelehandlers();
        break;
      case "boom_lifts":
        setRowsBoomLifts();
        break;
      case "scissor_lifts":
        setRowsScissorLifts();
        break;
      case "vertical_lifts":
        setRowsVerticalLifts();
        break;
      case "forklifts":
        setRowsForklifts();
        break;
      default:
      // code block
    }
  }, [props.subcategory]);

  return (
    <StyledTableContainer className="specs-table">
      <Table aria-label="simple table">
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.field}>
              <TableCell className="table-field" component="th" scope="row">
                {row.field}
              </TableCell>
              <TableCell className="table-value" align="right">
                {row.value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
}
