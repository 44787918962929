import React from "react";
import TextField from "@mui/material/TextField";

import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import styled from "styled-components";

const StyledQuantityContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: ${(props) => props.theme.fontPrimary};
  .boxed-input {
    max-width: 32px;
    max-height: 32px;
    margin: 0 8px;
  }
  .MuiFormLabel-root {
    color: ${(props) => props.theme.grey900};
    font-size: 14px;
    font-weight: 500;
    text-transform: none;
  }
  .MuiOutlinedInput-input {
    padding: 4px;
    text-align: center;
  }
  .MuiOutlinedInput-root {
    border-radius: 0px;
  }
  .boxed-input .MuiInputBase-root {
    font-family: ${(props) => props.theme.fontPrimary};
    font-weight: 700;
    font-size: 16px;
  }
  .MuiInputBase-root.Mui-disabled {
    color: ${(props) => props.theme.grey900};
  }
  .lower-quantity.Mui-disabled {
    color: ${(props) => props.theme.grey400};
  }
`;

export default function QuantityControl(props) {
  return (
    <StyledQuantityContainer>
      <IconButton
        aria-label="lower quantity"
        className="lower-quantity"
        size="small"
        onClick={() =>
          props.updateItemQuantity(props.item.id, props.item.quantity - 1)
        }
        disabled={props.quantity === 1 ? true : null}
      >
        <RemoveIcon fontSize="inherit" />
      </IconButton>
      <TextField
        label={props.label}
        variant="outlined"
        id={props.id}
        className="boxed-input"
        name={props.name}
        autoComplete="off"
        type={props.type ? props.type : null}
        value={props.quantity}
        // value={props.value}
        onChange={props.onChange}
        error={props.error}
        helperText={props.helperText}
        disabled
      />
      <IconButton
        aria-label="increase quantity"
        size="small"
        onClick={() =>
          props.updateItemQuantity(props.item.id, props.item.quantity + 1)
        }
      >
        <AddIcon fontSize="inherit" />
      </IconButton>
    </StyledQuantityContainer>
  );
}
