import React, { useEffect, useContext, useState } from "react";
import { MainContext } from "../contexts/MainContext.js";
import { Link, useLocation, useParams } from "react-router-dom";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import CartIconWithBadge from "./CartIconWithBadge";
import HomeIcon from "@mui/icons-material/Home";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import HideOnScroll from "./utilities/HideOnScroll";
import MenuIcon from "@mui/icons-material/Menu";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import TouchAppIcon from '@mui/icons-material/TouchApp';
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SVG from "react-inlinesvg";

import styled from "styled-components";

const StyledBottomNavigation = styled(BottomNavigation)`
  position: fixed;
  bottom: 0px;
  width: 100vw;
  overflow: hidden;
  z-index: 1000;
  box-shadow: 0px -2px 4px #0000000c;
  background-color: ${(props) => props.theme.backgroundLight};
  transition: ${(props) => props.theme.backgroundTransition};
  overflow: visible;
  a:after {
    content: "";
    position: absolute;
    width: 28px;
    height: 4px;
    background-color: transparent;
    bottom: 6px;
    transition: ${(props) => props.theme.backgroundTransition};
  }
  /* Menu Item Indicator */
  a.Mui-selected:after {
    content: "";
    position: absolute;
    width: 28px;
    height: 4px;
    bottom: 6px;
    /* background-color: ${(props) =>
      props.theme.primary}; Uncomment to enable indicator */
    transition: ${(props) => props.theme.backgroundTransition};
  }
  .MuiBottomNavigationAction-root {
    color: ${(props) => props.theme.textDark};
    transition: ${(props) => props.theme.colorTransition};
  }
  .Mui-selected.MuiBottomNavigationAction-root {
    color: ${(props) => props.theme.primary};
    transition: ${(props) => props.theme.colorTransition};
  }
  .MuiBottomNavigationAction-label{
    color: ${(props) => props.theme.textDark};
    font-family: ${(props) => props.theme.fontPrimary};
    font-weight: 700;
    text-transform: uppercase;
  }
 .MuiBottomNavigationAction-label.Mui-selected{
    color: ${(props) => props.theme.primary};
    font-family: ${(props) => props.theme.fontPrimary};
  }
  .telehandler-icon{
    max-height: 24px;
    fill: ${(props) => props.theme.primary};
  }
  .telehandler-icon .st0{
    fill: ${(props) => props.theme.textDark};
  }
  .Mui-selected .telehandler-icon .st0{
    fill: ${(props) => props.theme.primary};
  }
`;

export default function BottomNav() {
  const {
    mainMenuOpen,
    setMainMenuOpen,
    hiddenBottomNav,
    setHiddenBottomNav,
    singleProduct,
    isDesktop,
    isLandingPage
  } = useContext(MainContext);
  const [value, setValue] = useState(0);
  let location = useLocation();

  //This syncs the BottomNav to the current URL
  useEffect(() => {
    setValue(location.pathname);
  }, [location.pathname]);

  //Hide BottomNav on certain pages
  // useEffect(() => {
  //   if (location.pathname === "/page1") {
  //     setHiddenBottomNav(true);
  //   } else setHiddenBottomNav(false);
  // }, [singleProduct, location.pathname]);

  return (
    <div>
      {/* {!hiddenBottomNav ? ( */}
      {!isDesktop && !isLandingPage ? (
        <HideOnScroll direction="up">
          <StyledBottomNavigation
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            showLabels
            className="bottom-nav"
          >
            <BottomNavigationAction
              label="Equipment"
              value="/"
              icon={
                <SVG
                  className="telehandler-icon"
                  src="/svg/telehandler-icon.svg"
                  preserveAspectRatio="xMidYMid meet"
                />
              }
              component={Link}
              to="/"
            />
            <BottomNavigationAction
              label="Self Service"
              value="/off-hire"
              icon={<TouchAppIcon />}
              onClick={() => setMainMenuOpen(!mainMenuOpen)}
            />
            <BottomNavigationAction
              label="Cart"
              value="/cart"
              icon={<CartIconWithBadge />}
              component={Link}
              to="/cart"
            />
            {/* <BottomNavigationAction
              label="Page 2"
              value="/page2"
              icon={<RadioButtonCheckedIcon />}
              component={Link}
              to="/page2"
            /> */}
            {/* <BottomNavigationAction
              label="Menu"
              value="menu"
              icon={<MenuIcon />}
              onClick={() => setMainMenuOpen(!mainMenuOpen)}
            /> */}
            {/* <BottomNavigationAction
              label="Account"
              value="Account"
              icon={<AccountCircleIcon />}
              component={Link}
              to="/account"
            /> */}
            {/* <BottomNavigationAction
              label="Cart"
              value="Cart"
              icon={<ShoppingCartIcon />}
              onClick={() => setMainMenuOpen(!mainMenuOpen)}
            /> */}
          </StyledBottomNavigation>
        </HideOnScroll>
      ) : null}
    </div>
  );
}
