import React, { useState, useEffect, useContext } from "react";
import { MainContext } from "../contexts/MainContext";
import { logout } from "../firebase";
import {
  Route,
  Switch,
  useHistory,
  Redirect,
  useLocation
} from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import SVG from "react-inlinesvg";
import { Button, Typography } from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";

import { motion } from "framer-motion";
import { pageVariantsTopLevel, pageTransition } from "../transitions";

import { Helmet } from "react-helmet";

//Routes imports
import AccountDetails from "../components/authentication/AccountDetails";
import SignIn from "../components/authentication/SignIn";
import SignUp from "../components/authentication/SignUp";
import ForgotPassword from "../components/authentication/ForgotPassword";

//Component imports
import ButtonLarge from "../components/buttons/ButtonLarge";
import Spacer from "../components/Spacer";

//Styles related imports
import styled from "styled-components";
import slideTransitionEnter from "../animations";

const AccountPageContainer = styled.div`
  width: auto;
  /* height: calc(100vh - 112px); */
  position: relative;
  padding: ${(props) => (props.isDesktop ? "0 0 24px 0" : "24px")};
  text-align: left;
  font-family: ${(props) => props.theme.fontPrimary};
  color: ${(props) => props.theme.textDark};
  background-color: ${(props) =>
    props.isDesktop
      ? props.theme.backgroundGrey50
      : props.theme.backgroundLight};
  transition: ${(props) => props.theme.allTransition};
  .auth-container {
    padding: ${(props) => (props.isDesktop ? "24px" : "0")};
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: start;
    max-width: ${(props) => (props.isDesktop ? "500px" : "380px")};
    margin: 0 auto;
    background-color: ${(props) => props.theme.backgroundLight};
    border-radius: ${(props) => (props.isDesktop ? "4px" : "0")};
    box-shadow: ${(props) =>
      props.isDesktop ? "0px 2px 10px rgba(0, 0, 0, 0.1)" : "none"};
  }
  .account-container {
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: start;
    max-width: 380px;
    margin: 0 auto;
  }
`;

export default function AccountPage(props) {
  const { isDesktop } = useContext(MainContext);
  const [user, loading, error] = useAuthState(auth);
  const history = useHistory();
  const location = useLocation();

  return (
    <motion.div
      className="page-animation-div"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariantsTopLevel}
      transition={pageTransition}
    >
      <Helmet>
        <title>{`Account | GTH Equipment`}</title>
        <meta
          name="description"
          content={`View and update your account details.`}
        />
      </Helmet>
      <AccountPageContainer isDesktop={isDesktop}>
        <div className="auth-container">
          <Switch>
            <Route path={`${props.match.path}/signin`} component={SignIn} />
            <Route path={`${props.match.path}/signup`} component={SignUp} />
            <Route
              path={`${props.match.path}/reset-password`}
              component={ForgotPassword}
            />
            <Route path={props.match.path}>
              {user ? <AccountDetails /> : <SignIn />}
            </Route>
            {/* <Route path={props.match.path}>
            {user ? <AccountDetails /> : <Redirect push to="/account/signin" />}
          </Route> */}
          </Switch>
        </div>
        {/* <Spacer /> */}
      </AccountPageContainer>
    </motion.div>
  );
}
