import React, { useState, useEffect } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import Button from "@mui/material/Button";

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import styled from "styled-components";

const StyledButtonGroup = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  max-width: 380px;
  border: none;
  padding: 0;
  margin: 16px 0 0 0;
  transition: 0.2s all ease-out;
`;

const StyledButton = styled(Button)`
  width: 100%;
  height: 48px;
  padding: 0;
  border: none;
  border-radius: 0px;
  border-bottom: ${(props) =>
    props.location.pathname === props.to
      ? `2px solid ${props.theme.grey900}`
      : "2px solid transparent"};
  background-color: ${(props) => props.theme.white};
  opacity: 1;
  font-family: ${(props) => props.theme.fontPrimary};
  font-size: ${(props) => props.theme.buttonFontSize};
  font-weight: 700;
  color: ${(props) => props.theme.grey900};
  text-align: center;
  transition: 0.2s all ease-out;

  :hover {
    color: ${(props) => props.theme.grey600};
    background-color: ${(props) => props.theme.white};
    /* border-bottom: 1px solid ${(props) => props.theme.grey900}; */
    font-weight: 700;
  }
`;

export default function ToggleLoginSignup(props) {
  const [toggle1State, setToggle1State] = useState(true);
  const [toggle2State, setToggle2State] = useState(false);

  const location = useLocation();

  return (
    <StyledButtonGroup aria-label="Login or Create Account">
      <StyledButton
        className="toggle-button"
        value={toggle1State}
        aria-label="login"
        onClick={() => console.log(location.pathname)}
        component={Link}
        to={props.route1}
        location={location}
      >
        <span>Login</span>
      </StyledButton>
      <StyledButton
        className="toggle-button"
        value={toggle2State}
        aria-label="create account"
        component={Link}
        to={props.route2}
        location={location}
      >
        Create Account
      </StyledButton>
    </StyledButtonGroup>
  );
}
