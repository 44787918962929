import React, { useState, useEffect, useContext } from "react";
import { MainContext } from "../contexts/MainContext";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import firebase, {
  logout,
  auth,
  updateDBEntry,
  removeLocationFromDB,
  createOffHire
} from "../firebase";
import { useSnackbar } from "react-simple-snackbar";
import { snackbarOptions } from "../theme";

import SignIn from "../components/authentication/SignIn";
import SignUp from "../components/authentication/SignUp";
import ButtonLargeSubmit from "../components/buttons/ButtonLargeSubmit";
import ButtonLarge from "../components/buttons/ButtonLarge";
import { FormControl } from "@mui/material";
import InputBoxed from "../components/InputBoxed";
import InputBoxedSelect from "../components/InputBoxedSelect";
import InputBoxedSelectLocations from "../components/InputBoxedSelectLocations";
import ButtonLargeInverted from "../components/buttons/ButtonLargeInverted";
import Spacer from "../components/Spacer";
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddLocationDrawer from "../components/authentication/AddLocationDrawer";
import AuthDrawer from "../components/authentication/AuthDrawer";

import { motion } from "framer-motion";
import { pageVariantsTopLevel, pageTransition } from "../transitions";

import { useAuthState } from "react-firebase-hooks/auth";
import { useDocument } from "react-firebase-hooks/firestore";

import styled, { createGlobalStyle } from "styled-components";

const OverrideGlobalStyle = createGlobalStyle`
  .App {
    background-color: ${(props) => props.theme.backgroundLight};
    height: calc(100vh - 72px);
  }
`;

const PageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: ${(props) => (props.user ? "auto" : "calc(100vh - 112px)")};
  text-align: left;
  font-family: ${(props) => props.theme.fontPrimary};
  color: ${(props) => props.theme.textDark};
  /* background-color: ${(props) => props.theme.backgroundGrey50}; */
  background-color: ${(props) => props.theme.backgroundLight};
  transition: ${(props) => props.theme.allTransition};

  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 24px;
    margin: 24px auto;
    width: ${(props) => (props.isDesktop ? "380px" : "auto")};
    background-color: ${(props) => props.theme.backgroundLight};
  }
  .li-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 28px;
  }

`;

// the below form component should be moved to its own file
function OffHireForm() {
  const [openSnackbar] = useSnackbar(snackbarOptions);

  const [submitStatus, setSubmitStatus] = useState(""); //"", "submitting", "success" or "error"
  const [addLocationOpen, setAddLocationOpen] = useState(false);
  const [userData, setUserData] = useState("");
  const [checkFields, setCheckFields] = useState(false);

  const [companyName, setCompanyName] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [locations, setLocations] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedFamily, setSelectedFamily] = useState("");
  const [assetNumber, setAssetNumber] = useState("");
  const [pickupInstructions, setPickupInstructions] = useState("");
  const [offHireDate, setOffHireDate] = useState("");

  const [user] = useAuthState(auth);

  const ResetState = () => {
    setCompanyName("");
    setContactName("");
    setContactPhone("");
    setSelectedLocation("");
    setSelectedFamily("");
    setAssetNumber("");
    setPickupInstructions("");
    setOffHireDate("");
  };

  const [value, loading, error] = useDocument(
    firebase.firestore().collection("users").doc(user.uid),
    {
      snapshotListenOptions: { includeMetadataChanges: true }
    }
  );

  useEffect(() => {
    if (value) {
      setUserData(value.data());
    } else return null;
  }, [value]);

  useEffect(() => {
    UpdateLocalState();
  }, [userData]);

  const MapLocations = () => {
    if (userData.locations) {
      const temp = userData.locations.map((location) => ({
        value: location,
        label: `${location.streetAddress}, ${location.city}, ${location.postcode} `
      }));
      const temp2 = temp.unshift({ value: "", label: "" });
      // setPickupLocations(temp);
      setLocations(temp);
    }
  };

  const UpdateLocalState = () => {
    if (userData) {
      setCompanyName(userData.companyName);
      setContactName(userData.name);
      setContactPhone(userData.phoneNumber);
      MapLocations();
    }

    // console.log(userData.locations);
  };

  const onError = () => {
    setCheckFields(true);
    openSnackbar("Please complete all of the highlighted fields", 5000);
  };

  return (
    <div className="content">
      <h2>No longer need the equipment?</h2>
      <p>Please enter the details below to request off hire of equipment:</p>
      <FormControl required>
        <InputBoxed
          label="Company Name"
          InputLabelProps={{
            shrink: true
          }}
          id="company-name"
          name="company-name"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          error={checkFields && !companyName ? true : false}
        />
      </FormControl>
      <FormControl required>
        <InputBoxed
          label="Contact Name"
          InputLabelProps={{
            shrink: true
          }}
          id="contact-name"
          name="contact-name"
          value={contactName}
          onChange={(e) => setContactName(e.target.value)}
          error={checkFields && !contactName ? true : false}
        />
      </FormControl>
      <FormControl required>
        <InputBoxed
          label="Contact Mobile"
          InputLabelProps={{
            shrink: true
          }}
          id="contact-phone"
          name="contact-phone"
          value={contactPhone}
          onChange={(e) => setContactPhone(e.target.value)}
          error={checkFields && !contactPhone ? true : false}
        />
      </FormControl>
      {locations !== "" ? (
        <FormControl required>
          <InputBoxedSelectLocations
            label="Select Pickup Location"
            id="select-location"
            name="select-location"
            value={selectedLocation}
            //objects cannot be used as the value prop, so setting index number of the array
            // as the value and using that to pass the correct address on Submit
            //(pickupLocations[selectedLocation].value)
            onChange={(e) => setSelectedLocation(e.target.value)}
            options={locations}
            error={checkFields && !selectedLocation ? true : false}
          />
        </FormControl>
      ) : (
        <p>Please add a pickup location by clicking the button below:</p>
      )}
      <Button
        className="add-location-button"
        startIcon={<AddCircleOutlineIcon fontSize="inherit" />}
        // onClick={() => console.log(pickupLocations)}
        onClick={() => setAddLocationOpen(true)}
      >
        Add Location
      </Button>
      {/* <Button
        className="add-location-button"
        startIcon={<AddCircleOutlineIcon fontSize="inherit" />}
        // onClick={() => console.log(pickupLocations)}
        onClick={() => console.log(pickupLocations[selectedLocation].value)}
      >
        log selectedlocation
      </Button> */}
      <FormControl required>
        <InputBoxedSelect
          label="Select Product Family"
          id="select-product-family"
          name="select-product-family"
          value={selectedFamily}
          onChange={(e) => setSelectedFamily(e.target.value)}
          options={[
            { value: "", label: "" },
            { value: "Telehandlers", label: "Telehandlers" },
            { value: "Boom Lifts", label: "Boom Lifts" },
            { value: "Scissor Lifts", label: "Scissor Lifts" },
            { value: "Vertical Lifts", label: "Vertical Lifts" },
            { value: "Forklifts", label: "ForkLifts" }
          ]}
          error={checkFields && !selectedFamily ? true : false}
        />
      </FormControl>
      <FormControl required>
        <InputBoxed
          label="Asset Number"
          // helperText="Found on Equipment or Contract"
          placeholder="Found on Equipment or Contract"
          InputLabelProps={{
            shrink: true
          }}
          id="asset-number"
          name="asset-number"
          value={assetNumber}
          onChange={(e) => setAssetNumber(e.target.value)}
          error={checkFields && !assetNumber ? true : false}
        />
      </FormControl>
      <FormControl required>
        <InputBoxed
          label="Special Pickup Instructions"
          id="pickup-instructions"
          name="pickup-instructions"
          value={pickupInstructions}
          onChange={(e) => setPickupInstructions(e.target.value)}
          multiline
          rows={3}
        />
      </FormControl>
      <FormControl required>
        <InputBoxed
          label="Requested Off Hire Date"
          id="company-name"
          name="company-name"
          value={offHireDate}
          type="date"
          InputLabelProps={{
            shrink: true
          }}
          min="2020-12-15"
          max="2030-12-15"
          onChange={(e) => setOffHireDate(e.target.value)}
          error={checkFields && !offHireDate ? true : false}
        />
      </FormControl>
      <ButtonLargeSubmit
        text="Submit Request"
        varient="primary"
        submitStatus={submitStatus}
        // onClick={() => onSubmitOffHire()}
        onClick={() => {
          companyName &&
          contactName &&
          contactPhone &&
          selectedLocation &&
          selectedFamily &&
          assetNumber &&
          offHireDate
            ? onSubmitOffHire()
            : onError();
        }}
      />
      <AddLocationDrawer open={addLocationOpen} setOpen={setAddLocationOpen} />
    </div>
  );

  async function onSubmitOffHire() {
    setSubmitStatus("submitting");
    try {
      await createOffHire(
        companyName,
        contactName,
        contactPhone,
        locations[selectedLocation].value,
        selectedFamily,
        assetNumber,
        pickupInstructions,
        offHireDate + " (YYYY-MM-DD)"
      );
      setSubmitStatus("success");
      ResetState();
      console.log("passed createOffHire");
    } catch (error) {
      setSubmitStatus("error");
      console.log(error.message);
    }
  }
}

export default function OffHirePage() {
  const [user] = useAuthState(auth);
  const [authDrawerOpen, setAuthDrawerOpen] = useState(false);
  const { isDesktop } = useContext(MainContext);

  let { path, url } = useRouteMatch();

  return (
    <motion.div
      className="page-animation-div"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariantsTopLevel}
      transition={pageTransition}
    >
      <OverrideGlobalStyle />
      <PageContainer user={user} isDesktop={isDesktop}>
        {user ? (
          <div>
            <OffHireForm />
            <Spacer />
          </div>
        ) : (
          <div className="content">
            <h2>
              Please login or create an account before submitting an Off Hire
              request.
            </h2>
            {/* <ButtonLarge text="Login or Register" varient="dark" />
          <ButtonLargeInverted text="Create an account" /> */}
            <ButtonLarge
              text="Login or Register"
              varient="dark"
              onClick={() => setAuthDrawerOpen(true)}
            />

            {/* <Switch>
              <Route path={`${path}/signin`} component={SignIn} />
              <Route path={`${path}/signup`} component={SignUp} />
              <Route path={path}>
                {user ? null : <Redirect push to={`${path}/signin`} />}
              </Route>
            </Switch> */}
            <AuthDrawer open={authDrawerOpen} setOpen={setAuthDrawerOpen} />
          </div>
        )}
      </PageContainer>
    </motion.div>
  );
}
