import React from "react";
import { client } from "../../prismic-configuration";
import Prismic from "prismic-javascript";

import { useQuery } from "react-query";

//I dont think react-query lets me use on function of all of this, I need to call useQuery from the relevant components I think

export const useGetProducts = (
  category,
  subcategory,
  model,
  currentProduct
) => {
  //This section of code modifys the format of subcategory
  //to match the format of sub_product_family in the CMS
  const key = `${category}+${subcategory}+${model}`;
  const formattedSubCat = subcategory.replaceAll(" ", "_");
  // console.log(formattedSubCat);
  const formattedSubCatCapitalized =
    formattedSubCat.charAt(0).toUpperCase() + formattedSubCat.slice(1);
  // console.log(formattedSubCatCapitalized);
  //---------
  // const GetOptionalExtras = async (ids) => {
  //   const response1 = await client.query(
  //     Prismic.Predicates.at("document.id", ids)
  //   );
  //   if (response1) {
  //     // var ids = CMSModel[0].data.optional_extras_group.map(
  //     //   (item, i) => item.optional_extras_1.id
  //     // );
  //     // setOptionalExtrasIds(ids);
  //     console.log(response1);

  //     return response1;
  //   }
  // };

  async function getOptionalExtras(ids) {
    console.log("getOptionalExtras called");
    const response1 = await client.query(
      Prismic.Predicates.in("document.id", ids)
    );
    if (response1) {
      console.log("response1");
      console.log(response1);
      return response1;
    }
  }

  return useQuery(["product", key], async () => {
    // the below is a huge mess of nested if/else statements, they shoud abstracted to their own functions with appropriate names to make it more readable
    if (!model) {
      // no model value provided either:

      // if subcategory equals "optional_extras" fetch filtered products
      if (category === "optional_extras") {
        console.log("fetchdata ran at optional extras level");
        const response = await client.query(
          Prismic.Predicates.at("my.telehandlers.uid", `${subcategory}`)
        );
        if (response) {
          console.log("fetchdata complete at optional extras level");
          console.log(response);
          const extras = response.results[0].data.optional_extras_group;
          // var ids = response.results.map((item, i) => item.id);
          var ids = extras.map((item, i) => item.optional_extras_1.id);
          console.log(ids);
          // return extras.then(() => getOptionalExtras(ids));
          return getOptionalExtras(ids);
        }
      }

      // if subcategory equals anything other than "all" fetch filtered products === (Diesel, Hybrid, Electric, Gas etc)
      if (subcategory !== "all") {
        // const response = await client.query(
        //   Prismic.Predicates.at(
        //     `my.${category}.sub_product_family`,
        //     `${formattedSubCatCapitalized}`
        //   )
        // );
        // if (response) {
        //   console.log("fetchdata ran in reactquerytestpage.js 1");
        //   return response;

        // if category equals telehandler or forklifts sort by maximum_lift_capacity
        if (category === "telehandlers" || category === "forklifts") {
          const response = await client.query(
            Prismic.Predicates.at(
              `my.${category}.sub_product_family`,
              `${formattedSubCatCapitalized}`
            ),
            {
              pageSize: 50,
              orderings: `[ my.${category}.maximum_lift_capacity, my.${category}.uid ]`
            }
          );
          if (response) {
            console.log("fetchdata ran in useGetProducts.js #1");
            console.log(response);
            return response;
          }
          // if category equals vertical_lifts and subcategory equals push-around
        } else if (
          category === "vertical_lifts" &&
          subcategory === "push-around"
        ) {
          console.log("located 1" + subcategory);
          const response = await client.query(
            Prismic.Predicates.at(
              `my.${category}.sub_product_family`,
              `${subcategory}`
            ),
            {
              pageSize: 50,
              orderings: `[ my.${category}.maximum_lift_capacity, my.${category}.uid ]`
            }
          );
          if (response) {
            console.log(subcategory);
            console.log("fetchdata ran in useGetProducts.js #1");
            console.log(response);
            return response;
          }
         // if category equals scissor lifts - run 2 queries to look for dual subcategory listings
        } else if (
          category === "scissor_lifts" &&
          subcategory === "tracked"
        ) {
          console.log("located 1" + subcategory);
          const response = await client.query(
            Prismic.Predicates.at(
              `my.${category}.sub_product_family_2`,
              `${formattedSubCatCapitalized}`
              ),
            {
              pageSize: 50,
              orderings: `[my.${category}.platform_height, my.${category}.uid]`
            }
          );
          if (response) {
            console.log(subcategory);
            console.log("fetchdata ran in useGetProducts.js #1");
            console.log(response);
            return response;
          }
        } else {
          // if category equals anything other than telehandler or forklifts sort by platform_height
          const response = await client.query(
            Prismic.Predicates.at(
              `my.${category}.sub_product_family`,
              `${formattedSubCatCapitalized}`
            ),
            {
              pageSize: 50,
              orderings: `[my.${category}.platform_height, my.${category}.uid]`
            }
          );
          if (response) {
            console.log(subcategory);
            console.log("fetchdata ran in useGetProducts.js #1");
            console.log(response);
            return response;
          }
        }
      }

      // if subcategory equals "all"
      else {
        // if category equals telehandler or forklifts sort by maximum_lift_capacity
        if (category === "telehandlers" || category === "forklifts") {
          const response = await client.query(
            Prismic.Predicates.at("document.type", category),
            {
              pageSize: 50,
              orderings: `[ my.${category}.maximum_lift_capacity, my.${category}.uid ]`
            }
          );
          if (response) {
            console.log("fetchdata ran in useGetProducts.js #3");
            console.log(response);
            return response;
          }
        } else {
          // if category equals anything other than telehandler or forklifts sort by platform_height
          const response = await client.query(
            Prismic.Predicates.at("document.type", category),
            {
              pageSize: 50,
              orderings: `[my.${category}.platform_height, my.${category}.uid]`
            }
          );
          if (response) {
            console.log("fetchdata ran in useGetProducts.js #4");
            console.log(response);
            return response;
          }
        }
      }
    }
    //---- Only query the API if there is no local data for the model
    else if (currentProduct) {
      console.log("using local data in useGetProducts.js #5");
      return currentProduct;
    } else if (model && !currentProduct) {
      // model value provided fetch the single product data
      console.log(currentProduct);
      console.log("fetchdata ran in useGetProducts.js #6 - changed");
      const response = await client.getByUID(category, model);
      if (response) {
        return response;
      }
    } else {
      // return an error - to be improved
      return "error in useGetProducts";
    }
  });
};

export default useGetProducts;
