import React, { useState } from "react";
import ReactDOMServer from "react-dom/server";

export default function RequestQuickQuoteEmail({
    companyName,
    contactName,
    contactPhone,
    contactEmail,
    deliveryPostcode,
    deliveryDate,
    offHireDate,
    formattedCartContents,
  formattedDateAndTime
}) {
  const formattedCompanyName = encodeURIComponent(companyName);
  const formattedCompanyABN = encodeURIComponent(null);
  const formattedContactName = encodeURIComponent(contactName);

  return (
    <div style={{ margin: 0, padding: 0, backgroundColor: "#eee" }}>
      <center>
        <table
          align="center"
          border={0}
          cellPadding={0}
          cellSpacing={0}
          id="m_-2344630246995633201m_-1572471389833981368bodyTable"
          style={{
            borderCollapse: "collapse",
            padding: 0,
            backgroundColor: "#eee",
            height: "100%",
            margin: 0,
            width: "100%"
          }}
          width="100%"
        >
          <tbody>
            <tr>
              <td
                align="center"
                id="m_-2344630246995633201m_-1572471389833981368bodyCell"
                style={{
                  borderCollapse: "collapse",
                  paddingTop: 50,
                  paddingLeft: 20,
                  paddingBottom: 20,
                  paddingRight: 20,
                  borderTop: 0,
                  height: "100%",
                  margin: 0,
                  width: "100%"
                }}
                valign="top"
              >
                <table
                  border={2}
                  cellPadding={0}
                  cellSpacing={0}
                  id="m_-2344630246995633201m_-1572471389833981368templateContainer"
                  style={{
                    borderCollapse: "collapse",
                    border: "none",
                    backgroundColor: "#fff",
                    borderRadius: 4,
                    boxShadow: "0px 2px 4px #0000001A"
                  }}
                  width={600}
                >
                  <tbody>
                    <tr>
                      <td
                        id="m_-2344630246995633201m_-1572471389833981368templateContainerInner"
                        style={{ borderCollapse: "collapse", padding: 0 }}
                      >
                        <table
                          border={0}
                          cellPadding={0}
                          cellSpacing={0}
                          style={{ borderCollapse: "collapse" }}
                          width="100%"
                        >
                          <tbody>
                            <tr>
                              <td
                                align="center"
                                style={{ borderCollapse: "collapse" }}
                                valign="top"
                              >
                                <table
                                  border={0}
                                  cellPadding={0}
                                  cellSpacing={0}
                                  style={{ borderCollapse: "collapse" }}
                                  width="100%"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{ borderCollapse: "collapse" }}
                                        valign="top"
                                      />
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td
                                align="center"
                                style={{ borderCollapse: "collapse" }}
                                valign="top"
                              >
                                <table
                                  border={0}
                                  cellPadding={0}
                                  cellSpacing={0}
                                  style={{ borderCollapse: "collapse" }}
                                  width="100%"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{ borderCollapse: "collapse" }}
                                        valign="top"
                                      >
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 20
                                                }}
                                              >
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                      >
                                                        <span />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  padding: 9
                                                }}
                                                valign="top"
                                              >
                                                <table
                                                  align="left"
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse",
                                                          padding: 0,
                                                          paddingTop: 0,
                                                          paddingBottom: 0,
                                                          paddingLeft: 9,
                                                          paddingRight: 9,
                                                          textAlign: "center"
                                                        }}
                                                        valign="top"
                                                      >
                                                        <a
                                                          href="https://www.gth.com.au/"
                                                          style={{
                                                            wordWrap:
                                                              "break-word",
                                                            color: "#15c",
                                                            fontWeight:
                                                              "normal",
                                                            textDecoration:
                                                              "underline"
                                                          }}
                                                          target="_blank"
                                                          data-saferedirecturl="https://www.google.com/url?q=https://www.gthonline.com.au/&source=gmail&ust=1613109059842000&usg=AFQjCNH7CaHK95tIQRIvsljYmjd0LSenTg"
                                                        >
                                                          <img
                                                            align="center"
                                                            alt="GTH Equipment"
                                                            src="https://firebasestorage.googleapis.com/v0/b/gth-equipment.appspot.com/o/logos%2FGTH-Logo-Small.png?alt=media&token=d36a5146-6e2c-4704-905b-02e2f873a2e5"
                                                            style={{
                                                              border: 0,
                                                              height: "auto",
                                                              lineHeight:
                                                                "100%",
                                                              outline: "none",
                                                              textDecoration:
                                                                "none",
                                                              paddingBottom: 0,
                                                              display: "inline",
                                                              verticalAlign:
                                                                "bottom",
                                                              maxWidth: 250
                                                            }}
                                                            width={250}
                                                            className="CToWUd"
                                                          />
                                                        </a>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>

                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse"
                                                }}
                                                valign="top"
                                              >
                                                <table
                                                  align="left"
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse",
                                                          color: "#222",
                                                          fontFamily:
                                                            '"Helvetica Neue",Arial',
                                                          fontSize: "18px",
                                                          lineHeight: "100%",
                                                          textAlign: "center",
                                                          paddingTop: "9px",
                                                          paddingBottom: "9px",
                                                          paddingLeft: "18px",
                                                          paddingRight: "18px"
                                                        }}
                                                        valign="top"
                                                      >
                                                        <b>Quote Request</b>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse"
                                                }}
                                                valign="top"
                                              >
                                                <table
                                                  align="left"
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse",
                                                          color: "#222",
                                                          fontFamily:
                                                            '"Helvetica Neue",Arial',
                                                          fontSize: "14px",
                                                          lineHeight: "100%",
                                                          textAlign: "center",
                                                          paddingTop: "9px",
                                                          paddingBottom: "9px",
                                                          paddingLeft: "18px",
                                                          paddingRight: "18px"
                                                        }}
                                                        valign="top"
                                                      >
                                                        <b>
                                                          {formattedDateAndTime}
                                                        </b>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>

                                        {/* Dividing Line */}
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 5,
                                                  paddingBottom: 5,
                                                  paddingLeft: 5,
                                                  paddingRight: 5
                                                }}
                                              >
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse",
                                                    borderTopWidth: 1,
                                                    borderTopStyle: "none",
                                                    borderTopColor: "#e8e8e8"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                      >
                                                        <span />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{
                                            borderCollapse: "collapse",
                                            backgroundColor: "#eee"
                                          }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 9,
                                                  paddingBottom: 9,
                                                  paddingLeft: 18,
                                                  paddingRight: 18
                                                }}
                                                valign="top"
                                              >
                                                <table
                                                  align="left"
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                        valign="top"
                                                      >
                                                        <table
                                                          align="left"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={150}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <p
                                                                  style={{
                                                                    margin: 0,
                                                                    paddingBottom: 0,
                                                                    fontWeight: 600
                                                                  }}
                                                                >
                                                                  <b>
                                                                    Quote
                                                                    Request
                                                                    Details
                                                                  </b>
                                                                </p>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                        <table
                                                          align="right"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={407}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              />
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        {/* Dividing Line */}
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 5,
                                                  paddingBottom: 5,
                                                  paddingLeft: 5,
                                                  paddingRight: 5
                                                }}
                                              >
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse",
                                                    borderTopWidth: 1,
                                                    borderTopStyle: "none",
                                                    borderTopColor: "#e8e8e8"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                      >
                                                        <span />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 9,
                                                  paddingBottom: 9,
                                                  paddingLeft: 18,
                                                  paddingRight: 18
                                                }}
                                                valign="top"
                                              >
                                                <table
                                                  align="left"
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                        valign="top"
                                                      >
                                                        <table
                                                          align="left"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={150}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <p
                                                                  style={{
                                                                    margin: 0,
                                                                    paddingBottom: 0
                                                                  }}
                                                                >
                                                                  Company Name
                                                                </p>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                        <table
                                                          align="right"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={407}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  fontWeight: 600,
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <a
                                                                  target="_blank"
                                                                  rel="noopener noreferrer"
                                                                  href={`https://gthrentals.my.salesforce.com/_ui/search/ui/UnifiedSearchResults?str=${formattedCompanyName}`}
                                                                >
                                                                  {companyName}
                                                                </a>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        {/* Dividing Line */}
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 5,
                                                  paddingBottom: 5,
                                                  paddingLeft: 5,
                                                  paddingRight: 5
                                                }}
                                              >
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse",
                                                    borderTopWidth: 1,
                                                    borderTopStyle: "solid",
                                                    borderTopColor: "#e8e8e8"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                      >
                                                        <span />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 9,
                                                  paddingBottom: 9,
                                                  paddingLeft: 18,
                                                  paddingRight: 18
                                                }}
                                                valign="top"
                                              >
                                                <table
                                                  align="left"
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                        valign="top"
                                                      >
                                                        <table
                                                          align="left"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={150}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <p
                                                                  style={{
                                                                    margin: 0,
                                                                    paddingBottom: 0
                                                                  }}
                                                                >
                                                                  Company ABN
                                                                </p>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                        <table
                                                          align="right"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={407}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  fontWeight: 600,
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <a
                                                                  target="_blank"
                                                                  rel="noopener noreferrer"
                                                                  href={`https://gthrentals.my.salesforce.com/_ui/search/ui/UnifiedSearchResults?str=${formattedCompanyABN}`}
                                                                >
                                                                  {null}
                                                                </a>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        {/* Dividing Line */}
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 5,
                                                  paddingBottom: 5,
                                                  paddingLeft: 5,
                                                  paddingRight: 5
                                                }}
                                              >
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse",
                                                    borderTopWidth: 1,
                                                    borderTopStyle: "solid",
                                                    borderTopColor: "#e8e8e8"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                      >
                                                        <span />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 9,
                                                  paddingBottom: 9,
                                                  paddingLeft: 18,
                                                  paddingRight: 18
                                                }}
                                                valign="top"
                                              >
                                                <table
                                                  align="left"
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                        valign="top"
                                                      >
                                                        <table
                                                          align="left"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={150}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <p
                                                                  style={{
                                                                    margin: 0,
                                                                    paddingBottom: 0
                                                                  }}
                                                                >
                                                                  Purchase Order
                                                                </p>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                        <table
                                                          align="right"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={407}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  fontWeight: 600,
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                {null}
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        {/* Dividing Line */}
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 5,
                                                  paddingBottom: 5,
                                                  paddingLeft: 5,
                                                  paddingRight: 5
                                                }}
                                              >
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse",
                                                    borderTopWidth: 1,
                                                    borderTopStyle: "solid",
                                                    borderTopColor: "#e8e8e8"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                      >
                                                        <span />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 9,
                                                  paddingBottom: 9,
                                                  paddingLeft: 18,
                                                  paddingRight: 18
                                                }}
                                                valign="top"
                                              >
                                                <table
                                                  align="left"
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                        valign="top"
                                                      >
                                                        <table
                                                          align="left"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={150}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <p
                                                                  style={{
                                                                    margin: 0,
                                                                    paddingBottom: 0
                                                                  }}
                                                                >
                                                                  Contact Name
                                                                </p>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                        <table
                                                          align="right"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={407}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  fontWeight: 600,
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <a
                                                                  target="_blank"
                                                                  rel="noopener noreferrer"
                                                                  href={`https://gthrentals.my.salesforce.com/_ui/search/ui/UnifiedSearchResults?str=${formattedContactName}`}
                                                                >
                                                                  {contactName}
                                                                </a>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        {/* Dividing Line */}
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 5,
                                                  paddingBottom: 5,
                                                  paddingLeft: 5,
                                                  paddingRight: 5
                                                }}
                                              >
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse",
                                                    borderTopWidth: 1,
                                                    borderTopStyle: "solid",
                                                    borderTopColor: "#e8e8e8"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                      >
                                                        <span />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 9,
                                                  paddingBottom: 9,
                                                  paddingLeft: 18,
                                                  paddingRight: 18
                                                }}
                                                valign="top"
                                              >
                                                <table
                                                  align="left"
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                        valign="top"
                                                      >
                                                        <table
                                                          align="left"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={150}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <p
                                                                  style={{
                                                                    margin: 0,
                                                                    paddingBottom: 0
                                                                  }}
                                                                >
                                                                  Contact
                                                                  Position
                                                                </p>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                        <table
                                                          align="right"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={407}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  fontWeight: 600,
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                {
                                                                  null
                                                                }
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        {/* Dividing Line */}
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 5,
                                                  paddingBottom: 5,
                                                  paddingLeft: 5,
                                                  paddingRight: 5
                                                }}
                                              >
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse",
                                                    borderTopWidth: 1,
                                                    borderTopStyle: "solid",
                                                    borderTopColor: "#e8e8e8"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                      >
                                                        <span />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 9,
                                                  paddingBottom: 9,
                                                  paddingLeft: 18,
                                                  paddingRight: 18
                                                }}
                                                valign="top"
                                              >
                                                <table
                                                  align="left"
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                        valign="top"
                                                      >
                                                        <table
                                                          align="left"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={150}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <p
                                                                  style={{
                                                                    margin: 0,
                                                                    paddingBottom: 0
                                                                  }}
                                                                >
                                                                  Contact Phone
                                                                </p>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                        <table
                                                          align="right"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={407}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  fontWeight: 600,
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <a
                                                                  href={`tel:${contactPhone}`}
                                                                >
                                                                  {contactPhone}
                                                                </a>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        {/* Dividing Line */}
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 5,
                                                  paddingBottom: 5,
                                                  paddingLeft: 5,
                                                  paddingRight: 5
                                                }}
                                              >
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse",
                                                    borderTopWidth: 1,
                                                    borderTopStyle: "solid",
                                                    borderTopColor: "#e8e8e8"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                      >
                                                        <span />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 9,
                                                  paddingBottom: 9,
                                                  paddingLeft: 18,
                                                  paddingRight: 18
                                                }}
                                                valign="top"
                                              >
                                                <table
                                                  align="left"
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                        valign="top"
                                                      >
                                                        <table
                                                          align="left"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={150}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <p
                                                                  style={{
                                                                    margin: 0,
                                                                    paddingBottom: 0
                                                                  }}
                                                                >
                                                                  Contact Email
                                                                </p>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                        <table
                                                          align="right"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={407}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  fontWeight: 600,
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <a
                                                                  href={`mailto:${contactEmail}`}
                                                                >
                                                                  {contactEmail}
                                                                </a>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        {/* Dividing Line */}
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 5,
                                                  paddingBottom: 5,
                                                  paddingLeft: 5,
                                                  paddingRight: 5
                                                }}
                                              >
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse",
                                                    borderTopWidth: 1,
                                                    borderTopStyle: "solid",
                                                    borderTopColor: "#e8e8e8"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                      >
                                                        <span />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 9,
                                                  paddingBottom: 9,
                                                  paddingLeft: 18,
                                                  paddingRight: 18
                                                }}
                                                valign="top"
                                              >
                                                <table
                                                  align="left"
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                        valign="top"
                                                      >
                                                        <table
                                                          align="left"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={150}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <p
                                                                  style={{
                                                                    margin: 0,
                                                                    paddingBottom: 0
                                                                  }}
                                                                >
                                                                  Delivery
                                                                  Instructions
                                                                </p>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                        <table
                                                          align="right"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={407}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  fontWeight: 600,
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                {
                                                                  null
                                                                }
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 5,
                                                  paddingBottom: 5,
                                                  paddingLeft: 5,
                                                  paddingRight: 5
                                                }}
                                              >
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse",
                                                    borderTopWidth: 1,
                                                    borderTopStyle: "solid",
                                                    borderTopColor: "#e8e8e8"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                      >
                                                        <span />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 9,
                                                  paddingBottom: 9,
                                                  paddingLeft: 18,
                                                  paddingRight: 18
                                                }}
                                                valign="top"
                                              >
                                                <table
                                                  align="left"
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                        valign="top"
                                                      >
                                                        <table
                                                          align="left"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={150}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <p
                                                                  style={{
                                                                    margin: 0,
                                                                    paddingBottom: 0
                                                                  }}
                                                                >
                                                                  Delivery Date
                                                                </p>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                        <table
                                                          align="right"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={407}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  fontWeight: 600,
                                                                  rborderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                {deliveryDate}
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 5,
                                                  paddingBottom: 5,
                                                  paddingLeft: 5,
                                                  paddingRight: 5
                                                }}
                                              >
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse",
                                                    borderTopWidth: 1,
                                                    borderTopStyle: "solid",
                                                    borderTopColor: "#e8e8e8"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                      >
                                                        <span />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 9,
                                                  paddingBottom: 9,
                                                  paddingLeft: 18,
                                                  paddingRight: 18
                                                }}
                                                valign="top"
                                              >
                                                <table
                                                  align="left"
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                        valign="top"
                                                      >
                                                        <table
                                                          align="left"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={150}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <p
                                                                  style={{
                                                                    margin: 0,
                                                                    paddingBottom: 0
                                                                  }}
                                                                >
                                                                  Delivery Time
                                                                </p>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                        <table
                                                          align="right"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={407}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  fontWeight: 600,
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                {null}
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 5,
                                                  paddingBottom: 5,
                                                  paddingLeft: 5,
                                                  paddingRight: 5
                                                }}
                                              >
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse",
                                                    borderTopWidth: 1,
                                                    borderTopStyle: "solid",
                                                    borderTopColor: "#e8e8e8"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                      >
                                                        <span />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 9,
                                                  paddingBottom: 9,
                                                  paddingLeft: 18,
                                                  paddingRight: 18
                                                }}
                                                valign="top"
                                              >
                                                <table
                                                  align="left"
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                        valign="top"
                                                      >
                                                        <table
                                                          align="left"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={150}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <p
                                                                  style={{
                                                                    margin: 0,
                                                                    paddingBottom: 0
                                                                  }}
                                                                >
                                                                  Off-Hire Date
                                                                </p>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                        <table
                                                          align="right"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={407}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  fontWeight: 600,
                                                                  rborderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                {offHireDate}
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 5,
                                                  paddingBottom: 5,
                                                  paddingLeft: 5,
                                                  paddingRight: 5
                                                }}
                                              >
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse",
                                                    borderTopWidth: 1,
                                                    borderTopStyle: "none",
                                                    borderTopColor: "#e8e8e8"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                      >
                                                        <span />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{
                                            borderCollapse: "collapse",
                                            backgroundColor: "#eee"
                                          }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 9,
                                                  paddingBottom: 9,
                                                  paddingLeft: 18,
                                                  paddingRight: 18
                                                }}
                                                valign="top"
                                              >
                                                <table
                                                  align="left"
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                        valign="top"
                                                      >
                                                        <table
                                                          align="left"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={150}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <p
                                                                  style={{
                                                                    margin: 0,
                                                                    paddingBottom: 0
                                                                  }}
                                                                >
                                                                  <b>
                                                                    Delivery
                                                                    Location
                                                                  </b>
                                                                </p>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                        <table
                                                          align="right"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={407}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              />
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 5,
                                                  paddingBottom: 5,
                                                  paddingLeft: 5,
                                                  paddingRight: 5
                                                }}
                                              >
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse",
                                                    borderTopWidth: 1,
                                                    borderTopStyle: "none",
                                                    borderTopColor: "#e8e8e8"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                      >
                                                        <span />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 9,
                                                  paddingBottom: 9,
                                                  paddingLeft: 18,
                                                  paddingRight: 18
                                                }}
                                                valign="top"
                                              >
                                                <table
                                                  align="left"
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                        valign="top"
                                                      >
                                                        <table
                                                          align="left"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={150}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <p
                                                                  style={{
                                                                    margin: 0,
                                                                    paddingBottom: 0
                                                                  }}
                                                                >
                                                                  Contact at
                                                                  Location
                                                                </p>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                        <table
                                                          align="right"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={407}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  fontWeight: 600,
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                {
                                                                  null
                                                                }
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 5,
                                                  paddingBottom: 5,
                                                  paddingLeft: 5,
                                                  paddingRight: 5
                                                }}
                                              >
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse",
                                                    borderTopWidth: 1,
                                                    borderTopStyle: "solid",
                                                    borderTopColor: "#e8e8e8"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                      >
                                                        <span />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 9,
                                                  paddingBottom: 9,
                                                  paddingLeft: 18,
                                                  paddingRight: 18
                                                }}
                                                valign="top"
                                              >
                                                <table
                                                  align="left"
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                        valign="top"
                                                      >
                                                        <table
                                                          align="left"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={150}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <p
                                                                  style={{
                                                                    margin: 0,
                                                                    paddingBottom: 0
                                                                  }}
                                                                >
                                                                  Contact Phone
                                                                </p>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                        <table
                                                          align="right"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={407}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  fontWeight: 600,
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <a
                                                                  href={`tel:${null}`}
                                                                >
                                                                  {
                                                                    null
                                                                  }
                                                                </a>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 5,
                                                  paddingBottom: 5,
                                                  paddingLeft: 5,
                                                  paddingRight: 5
                                                }}
                                              >
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse",
                                                    borderTopWidth: 1,
                                                    borderTopStyle: "solid",
                                                    borderTopColor: "#e8e8e8"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                      >
                                                        <span />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 9,
                                                  paddingBottom: 9,
                                                  paddingLeft: 18,
                                                  paddingRight: 18
                                                }}
                                                valign="top"
                                              >
                                                <table
                                                  align="left"
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                        valign="top"
                                                      >
                                                        <table
                                                          align="left"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={150}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <p
                                                                  style={{
                                                                    margin: 0,
                                                                    paddingBottom: 0
                                                                  }}
                                                                >
                                                                  Delivery
                                                                  Address
                                                                </p>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                        <table
                                                          align="right"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={407}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  fontWeight: 600,
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                             
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 5,
                                                  paddingBottom: 5,
                                                  paddingLeft: 5,
                                                  paddingRight: 5
                                                }}
                                              >
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse",
                                                    borderTopWidth: 1,
                                                    borderTopStyle: "solid",
                                                    borderTopColor: "#e8e8e8"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                      >
                                                        <span />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 9,
                                                  paddingBottom: 9,
                                                  paddingLeft: 18,
                                                  paddingRight: 18
                                                }}
                                                valign="top"
                                              >
                                                <table
                                                  align="left"
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                        valign="top"
                                                      >
                                                        <table
                                                          align="left"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={150}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <p
                                                                  style={{
                                                                    margin: 0,
                                                                    paddingBottom: 0
                                                                  }}
                                                                >
                                                                  Contact
                                                                  Instructions
                                                                </p>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                        <table
                                                          align="right"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={407}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  fontWeight: 600,
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                {
                                                                  null
                                                                }
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 5,
                                                  paddingBottom: 5,
                                                  paddingLeft: 5,
                                                  paddingRight: 5
                                                }}
                                              >
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse",
                                                    borderTopWidth: 1,
                                                    borderTopStyle: "solid",
                                                    borderTopColor: "#e8e8e8"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                      >
                                                        <span />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 9,
                                                  paddingBottom: 9,
                                                  paddingLeft: 18,
                                                  paddingRight: 18
                                                }}
                                                valign="top"
                                              >
                                                <table
                                                  align="left"
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                        valign="top"
                                                      >
                                                        <table
                                                          align="left"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={150}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <p
                                                                  style={{
                                                                    margin: 0,
                                                                    paddingBottom: 0
                                                                  }}
                                                                >
                                                                  Location
                                                                  Requirements
                                                                </p>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                        <table
                                                          align="right"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={407}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  fontWeight: 600,
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                {
                                                                 null
                                                                }
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 5,
                                                  paddingBottom: 5,
                                                  paddingLeft: 5,
                                                  paddingRight: 5
                                                }}
                                              >
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse",
                                                    borderTopWidth: 1,
                                                    borderTopStyle: "none",
                                                    borderTopColor: "#e8e8e8"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                      >
                                                        <span />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{
                                            borderCollapse: "collapse",
                                            backgroundColor: "#eee"
                                          }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 9,
                                                  paddingBottom: 9,
                                                  paddingLeft: 18,
                                                  paddingRight: 18
                                                }}
                                                valign="top"
                                              >
                                                <table
                                                  align="left"
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                        valign="top"
                                                      >
                                                        <table
                                                          align="left"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={150}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              >
                                                                <p
                                                                  style={{
                                                                    margin: 0,
                                                                    paddingBottom: 0
                                                                  }}
                                                                >
                                                                  <b>
                                                                    Required
                                                                    Equipment
                                                                  </b>
                                                                </p>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                        <table
                                                          align="right"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width={407}
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  borderCollapse:
                                                                    "collapse",
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="top"
                                                              />
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        {/*
                                        <table
                                          border={0}
                                          cellPadding={0}
                                          cellSpacing={0}
                                          style={{ borderCollapse: "collapse" }}
                                          width="100%"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style={{
                                                  borderCollapse: "collapse",
                                                  paddingTop: 5,
                                                  paddingBottom: 5,
                                                  paddingLeft: 5,
                                                  paddingRight: 5
                                                }}
                                              >
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse",
                                                    borderTopWidth: 1,
                                                    borderTopStyle: "none",
                                                    borderTopColor: "#e8e8e8"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse"
                                                        }}
                                                      >
                                                        <span />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        */}
                                        {/* Booking Contents */}
                                        {formattedCartContents.map(
                                          (item, index) => {
                                            return (
                                              <div>
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse",
                                                          paddingTop: 9,
                                                          paddingBottom: 9,
                                                          paddingLeft: 18,
                                                          paddingRight: 18
                                                        }}
                                                        valign="middle"
                                                      >
                                                        <table
                                                          align="left"
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse"
                                                          }}
                                                          width="100%"
                                                        >
                                                          <thead>
                                                            <tr style={{}}>
                                                              <th
                                                                style={{
                                                                  width: 120,
                                                                  maxWidth: 120,
                                                                  padding: 0
                                                                }}
                                                                valign="middle"
                                                              >
                                                                <img
                                                                  style={{
                                                                    maxWidth: 120
                                                                  }}
                                                                  alt="product"
                                                                  src={
                                                                    item.data
                                                                      .product_image
                                                                      .url
                                                                  }
                                                                />
                                                              </th>
                                                              <th
                                                                style={{
                                                                  paddingLeft: 24,
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "left"
                                                                }}
                                                                valign="middle"
                                                              >
                                                                {`${item.manufacturer} - ${item.data.model[0].text}`}
                                                              </th>
                                                              <th
                                                                style={{
                                                                  color: "#222",
                                                                  fontFamily:
                                                                    '"Helvetica Neue",Arial',
                                                                  fontSize: 14,
                                                                  lineHeight:
                                                                    "100%",
                                                                  textAlign:
                                                                    "right",
                                                                  paddingTop: 4,
                                                                  fontWeight:
                                                                    "bold",
                                                                  paddingRight: 24
                                                                }}
                                                                valign="middle"
                                                              >
                                                                Qty:{" "}
                                                                {item.quantity}
                                                              </th>
                                                            </tr>
                                                          </thead>
                                                          <tbody />
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                                <table
                                                  border={0}
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{
                                                    borderCollapse: "collapse"
                                                  }}
                                                  width="100%"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          borderCollapse:
                                                            "collapse",
                                                          paddingTop: 5,
                                                          paddingBottom: 5,
                                                          paddingLeft: 5,
                                                          paddingRight: 5
                                                        }}
                                                      >
                                                        <table
                                                          border={0}
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{
                                                            borderCollapse:
                                                              "collapse",
                                                            borderTopWidth: 1,
                                                            borderTopStyle:
                                                              "solid",
                                                            borderTopColor:
                                                              "#e8e8e8"
                                                          }}
                                                          width="100%"
                                                        >
                                                          <tbody>
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  borderCollapse:
                                                                    "collapse"
                                                                }}
                                                              >
                                                                <span />
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                                {item.optionalExtras
                                                  ? item.optionalExtras.map(
                                                      (item, index) => {
                                                        return (
                                                          // <div key={index}>
                                                          //   <h4>
                                                          //     Optional Extra Item #
                                                          //     {index + 1}
                                                          //   </h4>
                                                          //   <p>
                                                          //     <strong>
                                                          //       Family
                                                          //     </strong>
                                                          //     : {item.family}
                                                          //   </p>
                                                          //   <p>
                                                          //     <strong>Model</strong>
                                                          //     : {item.model}
                                                          //   </p>
                                                          //   <p>
                                                          //     <strong>
                                                          //       Optional Extra For
                                                          //     </strong>
                                                          //     : {item.childOf}
                                                          //   </p>
                                                          //   <p>
                                                          //     <strong>
                                                          //       Quantity
                                                          //     </strong>
                                                          //     : {item.quantity}
                                                          //   </p>
                                                          // </div>
                                                          <div>
                                                            <table
                                                              border={0}
                                                              cellPadding={0}
                                                              cellSpacing={0}
                                                              style={{
                                                                borderCollapse:
                                                                  "collapse"
                                                              }}
                                                              width="100%"
                                                            >
                                                              <tbody>
                                                                <tr>
                                                                  <td
                                                                    style={{
                                                                      borderCollapse:
                                                                        "collapse",
                                                                      paddingTop: 9,
                                                                      paddingBottom: 9,
                                                                      paddingLeft: 18,
                                                                      paddingRight: 18
                                                                    }}
                                                                    valign="middle"
                                                                  >
                                                                    <table
                                                                      align="left"
                                                                      border={0}
                                                                      cellPadding={
                                                                        0
                                                                      }
                                                                      cellSpacing={
                                                                        0
                                                                      }
                                                                      style={{
                                                                        borderCollapse:
                                                                          "collapse"
                                                                      }}
                                                                      width="100%"
                                                                    >
                                                                      <thead>
                                                                        <tr
                                                                          style={{}}
                                                                        >
                                                                          <th
                                                                            style={{
                                                                              width: 80,
                                                                              maxWidth: 80,
                                                                              padding:
                                                                                "0px 20px"
                                                                            }}
                                                                            valign="middle"
                                                                          >
                                                                            <img
                                                                              style={{
                                                                                maxWidth: 80
                                                                              }}
                                                                              alt="optional-extra"
                                                                              src={
                                                                                item
                                                                                  .data
                                                                                  .product_image
                                                                                  .url
                                                                              }
                                                                            />
                                                                          </th>
                                                                          <th
                                                                            style={{
                                                                              paddingLeft: 24,
                                                                              color:
                                                                                "#222",
                                                                              fontFamily:
                                                                                '"Helvetica Neue",Arial',
                                                                              fontSize: 14,
                                                                              lineHeight:
                                                                                "100%",
                                                                              textAlign:
                                                                                "left"
                                                                            }}
                                                                            valign="middle"
                                                                          >
                                                                            {`Add-on: ${item.model}`}
                                                                          </th>
                                                                          <th
                                                                            style={{
                                                                              color:
                                                                                "#222",
                                                                              fontFamily:
                                                                                '"Helvetica Neue",Arial',
                                                                              fontSize: 14,
                                                                              lineHeight:
                                                                                "100%",
                                                                              textAlign:
                                                                                "right",
                                                                              paddingTop: 4,
                                                                              fontWeight:
                                                                                "bold",
                                                                              paddingRight: 24
                                                                            }}
                                                                            valign="middle"
                                                                          >
                                                                            {`Qty: ${item.quantity}`}
                                                                          </th>
                                                                        </tr>
                                                                      </thead>
                                                                      <tbody />
                                                                    </table>
                                                                  </td>
                                                                </tr>
                                                              </tbody>
                                                            </table>
                                                            <table
                                                              border={0}
                                                              cellPadding={0}
                                                              cellSpacing={0}
                                                              style={{
                                                                borderCollapse:
                                                                  "collapse"
                                                              }}
                                                              width="100%"
                                                            >
                                                              <tbody>
                                                                <tr>
                                                                  <td
                                                                    style={{
                                                                      borderCollapse:
                                                                        "collapse",
                                                                      paddingTop: 5,
                                                                      paddingBottom: 5,
                                                                      paddingLeft: 5,
                                                                      paddingRight: 5
                                                                    }}
                                                                  >
                                                                    <table
                                                                      border={0}
                                                                      cellPadding={
                                                                        0
                                                                      }
                                                                      cellSpacing={
                                                                        0
                                                                      }
                                                                      style={{
                                                                        borderCollapse:
                                                                          "collapse",
                                                                        borderTopWidth: 1,
                                                                        borderTopStyle:
                                                                          "solid",
                                                                        borderTopColor:
                                                                          "#e8e8e8"
                                                                      }}
                                                                      width="100%"
                                                                    >
                                                                      <tbody>
                                                                        <tr>
                                                                          <td
                                                                            style={{
                                                                              borderCollapse:
                                                                                "collapse"
                                                                            }}
                                                                          >
                                                                            <span />
                                                                          </td>
                                                                        </tr>
                                                                      </tbody>
                                                                    </table>
                                                                  </td>
                                                                </tr>
                                                              </tbody>
                                                            </table>
                                                            <table
                                                              border={0}
                                                              cellPadding={0}
                                                              cellSpacing={0}
                                                              style={{
                                                                borderCollapse:
                                                                  "collapse"
                                                              }}
                                                              width="100%"
                                                            >
                                                              {/* <tbody>
                                                                <tr>
                                                                  <td
                                                                    style={{
                                                                      borderCollapse:
                                                                        "collapse",
                                                                      paddingTop: 20
                                                                    }}
                                                                  >
                                                                    <table
                                                                      border={0}
                                                                      cellPadding={
                                                                        0
                                                                      }
                                                                      cellSpacing={
                                                                        0
                                                                      }
                                                                      style={{
                                                                        borderCollapse:
                                                                          "collapse"
                                                                      }}
                                                                      width="100%"
                                                                    >
                                                                      <tbody>
                                                                        <tr>
                                                                          <td
                                                                            style={{
                                                                              borderCollapse:
                                                                                "collapse"
                                                                            }}
                                                                          >
                                                                            <span />
                                                                          </td>
                                                                        </tr>
                                                                      </tbody>
                                                                    </table>
                                                                  </td>
                                                                </tr>
                                                              </tbody> */}
                                                            </table>
                                                          </div>
                                                        );
                                                      }
                                                    )
                                                  : null}
                                              </div>
                                            );
                                          }
                                        )}
                                        {/* End of Map Function */}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </center>
    </div>
  );
}
