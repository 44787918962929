import React, { useState, useContext } from "react";
import { MainContext } from "../../contexts/MainContext";
import { Link, withRouter } from "react-router-dom";
import InputBoxed from "../InputBoxed";
import { FormControl } from "@mui/material";
import ButtonLarge from "../buttons/ButtonLarge";

import BackButton from "../buttons/BackButton";

import { auth } from "../../firebase";

import styled from "styled-components";

const ResetPasswordContainer = styled.div`
  height: 100%;
  margin: 0 auto;
  width: ${(props) => (props.isDesktop ? "380px" : "auto")};
  .form-container {
    display: flex;
    max-width: 380px;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    flex-direction: column;
    justify-content: start;
    align-items: center;
  }
  .MuiFormControl-root {
    width: 100%;
    max-width: 380px;
  }
  .body-text {
    font-size: 14px;
    font-weight: 600;
    font-family: ${(props) => props.theme.fontPrimary};
    color: ${(props) => props.theme.textDark};
    margin: 8px 0 0 0;
  }
  .link-text {
    text-align: right;
    font-size: 14px;
    font-weight: 600;
    font-family: ${(props) => props.theme.fontPrimary};
    color: ${(props) => props.theme.textDark};
    margin: 16px 0 0 0;
  }
`;

export default function ForgotPassword(props) {
  const [emailAddress, setEmailAddress] = useState("");
  const { Alert, isDesktop } = useContext(MainContext);

  const SubmitPasswordReset = () => {
    auth
      .sendPasswordResetEmail(emailAddress)
      .then(function () {
        Alert(
          "Please check your email to proceed with resetting your password."
        );
      })
      .catch(function (error) {
        Alert(error.message);
      });
  };

  return (
    <ResetPasswordContainer
      className="forgot-form-container"
      isDesktop={isDesktop}
    >
      <h1>Enter your email address to reset your password.</h1>
      <form
        className="form-container"
        onSubmit={(e) => e.preventDefault() && false}
      >
        <FormControl required>
          <InputBoxed
            label="Email"
            id="email"
            name="Email"
            type="email"
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
          />
        </FormControl>
        <ButtonLarge
          text="Send Reset Email"
          onClick={() => SubmitPasswordReset()}
          margin=" 24px 0 0 0"
        />
        <BackButton margin="24px 0 0 0" noHistoryPath="/account/signin" />
      </form>
    </ResetPasswordContainer>
  );
}
