import React, { useState, useContext } from "react";
import { MainContext } from "../contexts/MainContext.js";
import { useCart } from "react-use-cart";

import { Link, Route, useParams, useRouteMatch } from "react-router-dom";

import SingleProductPage from "../routes/SingleProductPage";
import OnCardButton from "./buttons/OnCardButton";

import styled from "styled-components";

const StyledProductCard = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px;
  background-color: ${(props) => props.theme.backgroundLight};
  border-radius: 4px;
  box-shadow: ${(props) => props.theme.cardBoxShadow};
  .card-row1 {
    display: flex;
    padding: 0 8px;
  }
  .model-family {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 0 0 16px;
  }
  .product-image {
    height: 20vh;
    width: auto;
    max-height: 100px;
  }
  .card-title {
    font-weight: 600;
    color: ${(props) => props.theme.textDark};
    text-transform: capitalize;
    height: fit-content;
  }
  .card-family {
  }
  .oe-description {
    padding: 8px 16px;
    /* max-height: 100px; */
    text-overflow: ellipsis;
  }
  .MuiIconButton-root {
    color: ${(props) => props.theme.textDark};
  }
  .card-buttons {
  }
  .MuiListItem-gutters {
    padding: 8px 0;
  }
  .MuiListItemText-primary {
    font-family: Titillium Web;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: ${(props) => props.theme.textDark};
    text-align: left;
    text-transform: capitalize;
    height: fit-content;
  }
`;

export default function OptionalExtraCard({ doc, subcategory, closeModal }) {
  const { items, addItem } = useCart();
  const { url, path } = useRouteMatch();

  const AddToCart = (doc) => {
    const cartItem = {
      id: doc.uid,
      // linkId: model,
      type: doc.type,
      price: 0,
      data: doc.data,
      childOf: subcategory
    };
    addItem(cartItem);
    closeModal();
  };

  const TruncateString = function (str, length, ending) {
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  };

  return (
    <StyledProductCard className="optional-extra-card">
      <div className="card-row1">
        <img
          className="product-image"
          src={doc.data.product_image.url}
          alt={doc.data.product_title[0].text}
        />
        <div className="model-family">
          <h2 className="card-title">{doc.data.product_title[0].text}</h2>
          <p className="card-family">{doc.data.product_family}</p>
        </div>
      </div>
      <p className="oe-description">
        {doc.data.product_description[0]
          ? TruncateString(doc.data.product_description[0].text, 100)
          : null}
      </p>
      <div className="card-buttons">
        {/* <OnCardButton
          text="Log"
          onClick={() => console.log(doc)}
        /> */}
        <OnCardButton
          text="Specifications"
          linkTo={`/optional_extras/${subcategory}/${doc.uid}`}
          // onClick={SetProduct}
          // onClick={() => console.log(doc)}
        />
        <OnCardButton
          type="AddToCart"
          doc={doc}
          items={items}
          isCTA={true}
          onClick={() => AddToCart(doc)}
        />
      </div>
    </StyledProductCard>
  );
}
