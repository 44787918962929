import React, { useState, useContext } from "react";
import { MainContext } from "../../contexts/MainContext";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import SignInDrawer from "./SignInDrawer";
import SignUpDrawer from "./SignUpDrawer";
import ForgotPassword from "./ForgotPassword";

import styled from "styled-components";
import Spacer from "../Spacer";

const StyledSwipeableDrawer = styled(SwipeableDrawer)`
  width: 100%;
  height: 100%;
  position: relative;

  text-align: left;
  font-family: ${(props) => props.theme.fontPrimary};
  color: ${(props) => props.theme.textDark};
  background-color: ${(props) => props.theme.backgroundLight};
  transition: ${(props) => props.theme.allTransition};

  .MuiDrawer-paper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: ${(props) => props.theme.backgroundLight};
  }
  .top-bar-drawer {
    position: fixed;
    display: flex;
    justify-content: center;
    height: 56px;
    width: 100vw;
    top: 0;
    background-color: ${(props) => props.theme.backgroundLight};
    box-shadow: 0px 2px 4px #0000000c;
    overflow: visible;
    transition: ${(props) => props.theme.backgroundTransition};
  }
  .close-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .page-title-top {
    color: ${(props) => props.theme.textDark};
    font-size: 1rem;
    text-transform: capitalize;
    padding: 0 0 0 48px;
  }
  .close-drawer-button {
    color: ${(props) => props.theme.textDark};
  }
  .auth-container {
    position: relative;
    height: fit-content;
    width: 100%;
    margin: 56px 0 0 0;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    max-width: 380px;
    margin: 0 auto;
    width: ${(props) => (props.isDesktop ? "380px" : "auto")};
  }
`;

export default function AddLocationDrawer(props) {
  const [active, setActive] = useState("signIn"); //signIn, signUp, or forgotPassword
  const { isDesktop } = useContext(MainContext);

  return (
    <StyledSwipeableDrawer
      anchor="bottom"
      open={props.open}
      onClose={""}
      onOpen={""}
      isDesktop={isDesktop}
    >
      <AppBar className="top-bar-drawer" position="static">
        <div className="close-title-container">
          <h1 className="page-title-top">Login to Continue</h1>
          <IconButton
            className="close-drawer-button"
            aria-label="close"
            onClick={() => props.setOpen(false)}
            size="large">
            <CloseIcon />
          </IconButton>
        </div>
      </AppBar>
      <div className="auth-container">
        {active === "signIn" ? (
          <SignInDrawer
            active={active}
            setActive={setActive}
            setOpen={props.setOpen}
          />
        ) : null}
        {active === "signUp" ? (
          <SignUpDrawer
            active={active}
            setActive={setActive}
            setOpen={props.setOpen}
          />
        ) : null}
        {active === "forgotPassword" ? (
          <ForgotPassword
            active={active}
            setActive={setActive}
            setOpen={props.setOpen}
          />
        ) : null}
      </div>
    </StyledSwipeableDrawer>
  );
}
