import React, { useState, useContext, useEffect } from "react";
import { MainContext } from "../contexts/MainContext.js";
import { useCart } from "react-use-cart";

import { useRouteMatch, useHistory } from "react-router-dom";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import QuantityControls from "./QuantityControl";

import { BiSubdirectoryRight } from "react-icons/bi";

import styled from "styled-components";

const StyledBookingSummaryListItem = styled.div`
  padding: 8px 0;
  width: 100%;
  .card-row1 {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding: ${(props) =>
      props.item.type !== "optional_extras" ? "0" : "0 0 0 24px"};
  }
  .wrapper-for-divider {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 0 0 16px;
  }
  .item-details-container {
    display: flex;
    width: 100%;
  }
  .model-family {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  .optional-extra-icon {
    height: 30px;
    width: 30px;
    color: ${(props) => props.theme.grey600};
  }
  .product-image {
    height: auto;
    max-height: ${(props) =>
      props.item.type !== "optional_extras" ? "50px" : "32px"};
    width: auto;
    /* max-width: 26%; */
  }
  .card-title {
    font-size: ${(props) =>
      props.item.type !== "optional_extras" ? "1rem" : "0.875rem"};
    font-weight: 600;
    color: ${(props) => props.theme.textDark};
    text-transform: none;
    height: fit-content;
  }
  .card-family {
    text-transform: capitalize;
    font-size: ${(props) =>
      props.item.type !== "optional_extras" ? "0.875rem" : "0.75rem"};
  }
  .quantity {
    justify-self: flex-end;
    display: flex;
    align-items: center;
    width: fit-content;
    white-space: nowrap;
  }
  .quantity p {
    font-size: 1rem;
    font-weight: 600;
  }
  .remove-button {
    font-family: ${(props) => props.theme.fontPrimary};
    font-weight: 700;
    font-size: 14px;
  }
  .featured-spec {
    display: flex;
    flex-direction: column;
  }
  .MuiIconButton-root {
    color: ${(props) => props.theme.textDark};
  }
  .product-cart.remove-quantity.MuiButton-label {
    font-family: ${(props) => props.theme.fontPrimary};
  }
  .add-extras-button-container {
    display: flex;
    justify-content: center;
    height: 40px;
  }
  .add-extras-button {
    width: 100%;
    font-family: ${(props) => props.theme.fontPrimary};
    font-weight: 700;
    font-size: 16px;
  }
  .MuiListItem-gutters {
    padding: 8px 0;
  }
  .MuiListItemText-primary {
    font-family: Titillium Web;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: ${(props) => props.theme.textDark};
    text-align: left;
    text-transform: capitalize;
    height: fit-content;
  }
  .styled-divider {
    margin: 16px 0 0 0;
    background-color: ${(props) => props.theme.backgroundGrey50};
  }
`;

export default function BookingSummaryListItem({
  keySpec1,
  keySpec2,
  keySpec3,
  doc,
  item,
  index
}) {
  const { singleProduct, setSingleProduct, setOptionalExtrasFor, isLandingPage } = useContext(
    MainContext
  );
  const {
    isEmpty,
    cartTotal,
    totalUniqueItems,
    items,
    updateItemQuantity,
    removeItem,
    emptyCart,
    addItem,
    totalItems
  } = useCart();
  const [extras, setExtras] = useState("");
  let history = useHistory();
  const { url, path } = useRouteMatch();

  const SetProduct = () => {
    setSingleProduct(doc);
    console.log(doc);
  };

  useEffect(() => {
    setExtras(items.map((i) => (i.linkId === item.id ? i : "")));
  }, []);

  const RemoveUnderscore = (str1) => {
    return [str1.replace("_", " ")];
  };

  return (
    <StyledBookingSummaryListItem
      className="product-card"
      onClick={() => (!isLandingPage ? history.push(`${item.type}/all/${item.id}`) : null)}
      item={item}
    >
      <div className="card-row1">
        {item.type === "optional_extras" ? (
          <BiSubdirectoryRight className="optional-extra-icon" />
        ) : null}
        <img
          className="product-image"
          src={item.data.product_image.url}
          alt={item.data.product_image.imgAlt}
        />
        <div className="wrapper-for-divider">
          <div className="item-details-container">
            <div className="model-family">
              <h2 className="card-title">
                {item.data.manufacturer} {item.data.model[0].text}
              </h2>
              <p className="card-family">
                {item.data.product_family
                  ? item.data.product_family
                  : RemoveUnderscore(item.type)}
              </p>
            </div>
            <div className="quantity">
              <p className="card-family">×{item.quantity}</p>
              {/* <p className="card-family">Qty: {item.quantity}</p> */}
            </div>
          </div>
          {/* <button onClick={() => console.log(index)}>log index</button> */}

         {!isLandingPage ? <Divider className="styled-divider" /> : null }
        </div>
      </div>

      {/* {item.type !== "optional_extras" ? (
        <div className="add-extras-button-container">
          <Button
            className="add-extras-button"
            startIcon={<AddCircleIcon fontSize="inherit" />}
            onClick={() => setOptionalExtrasFor(item.id)}
            component={Link}
            to={`/optional-extras/${item.id}`}
          >
            Add Extras
          </Button>
        </div>
      ) : null} */}
    </StyledBookingSummaryListItem>
  );
}
