import React, { useEffect, useState, useContext } from "react";
import { MainContext } from "../contexts/MainContext";
import { useSnackbar } from "react-simple-snackbar";
import { snackbarOptions, snackbarOptionsHigher } from "../theme";

import copy from "copy-to-clipboard";

import { isIOS, isMobileSafari, isMobile } from "react-device-detect";

const InstallPWADev = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);
  const { hiddenBottomNav } = useContext(MainContext);
  const [openSnackbar] = useSnackbar(
    hiddenBottomNav === true ? snackbarOptions : snackbarOptionsHigher
  );

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      console.log("we are being triggered :D");
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = (evt) => {
    evt.preventDefault();
    if (!promptInstall) {
      if (!isMobile) {
        openSnackbar(
          "Please visit this site on your mobile phone to install the app."
        );
      } else if (isMobile && isIOS && !isMobileSafari) {
        copy("app.gth.com.au");
        openSnackbar(
          "Please visit this site in Safari browser to install the app. (URL has been copied to your clipboard)"
        );
      } else if (isMobile && isMobileSafari) {
        openSnackbar(
          `Open the Share Menu and then tap 'Add to Home Screen' to install the app.`
        );
      }
      return; //ends function here if !promptInstall
    } else if (!isMobile) {
      openSnackbar(
        "Please visit this site on your mobile phone to install the mobile app."
      );
    }
    promptInstall.prompt();
  };

  // if (!supportsPWA) {
  //   return null;
  // }

  return (
    <div>
      <h3>isMobile: {isMobile.toString()}</h3>
      <h3>isIOS: {isIOS.toString()}</h3>
      <h3>isMobileSafari: {isMobileSafari.toString()}</h3>
      <h3>Supports PWA: {supportsPWA.toString()}</h3>
      <button
        className="install-button"
        id="setup_button"
        aria-label="Install app"
        title="Install app"
        onClick={onClick}
      >
        Install
      </button>
    </div>
  );
};

export default InstallPWADev;
