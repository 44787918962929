import React, { useState, useEffect, useContext } from "react";
import { MainContext } from "../contexts/MainContext.js";
import {
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
  useHistory
} from "react-router-dom";
import CartItemCard from "../components/CartItemCard";
import OptionalExtrasModal from "./OptionalExtrasModal.js";

import { useCart } from "react-use-cart";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import Spacer from "../components/Spacer";
import Button from "@mui/material/Button";
import ButtonLarge from "../components/buttons/ButtonLarge";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import styled from "styled-components";

const PageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: left;
  font-family: ${(props) => props.theme.fontPrimary};
  color: ${(props) => props.theme.textDark};
  /* background-color: ${(props) => props.theme.backgroundGrey50}; */
  background-color: ${(props) => props.theme.backgroundGrey50};
  transition: ${(props) => props.theme.allTransition};
  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .add-extras-button-container{
    margin: 0 auto;
    border-radius: 4px;
  }
  .add-extras-button .MuiButton-label{
    margin: 8px 16px;
    font-size: 0.75rem;
    font-weight: 700;
    border-radius: 4px;
  }
`;

export default function CartItemsGroup(props) {
  const { currentCartItemId, setCurrentCartItemId } = useContext(MainContext);
  const [modelOpen, setModelOpen] = useState(false);
  let { path, url } = useRouteMatch();
  const { subcategory, filter } = useParams();
  const history = useHistory();
  const {
    isEmpty,
    cartTotal,
    totalUniqueItems,
    items,
    updateItemQuantity,
    removeItem,
    emptyCart
  } = useCart();

  // const CheckForExtras = (cartItem) => {
  //   return items.map((item) => (item.type === "optional_extras" ? item : null));
  // };

  const AddExtras = () => {
    setCurrentCartItemId(props.item.id);
    setModelOpen(true);
  };

  // const AddExtras = () => {
  //   setCurrentCartItemId(props.item.id);
  //   history.push(`/optional_extras/${props.item.id}`);
  // };

  return (
    <PageContainer>
      <div className="content">
        {/* Main Product Card */}
        <CartItemCard item={props.item} />
        {/* Map Extras */}
        {/* {items.map((item) => (
          <CartItemCard item={item} />
        ))} */}

        {items.map((item) =>
          item.type === "optional_extras" && item.childOf === props.item.id ? (
            <CartItemCard item={item} />
          ) : null
        )}
        <div className="add-extras-button-container">
          <Button
            className="add-extras-button"
            startIcon={<AddCircleIcon fontSize="inherit" />}
            // onClick={() => setOptionalExtrasFor(item.id)}
            // component={Link}
            onClick={AddExtras}
            // to={`/optional_extras/${props.item.id}`}
          >
            Add Extras
          </Button>
        </div>
        <OptionalExtrasModal
          open={modelOpen}
          setOpen={setModelOpen}
          modelId={props.item.id}
        />
        {/* <Button
          className="add-extras-button"
          onClick={() => console.log(items)}
        >
          Log Cart
        </Button> */}
      </div>
    </PageContainer>
  );
}
