import React, { useContext, useState, useEffect } from "react";
import { MainContext } from "../contexts/MainContext.js";
import { useLocation, useHistory } from "react-router-dom";


import TopNavDesktop from "./TopNavDesktop";
import TopNavDesktopLandingPage from "./TopNavDesktopLandingPage";
import TopNavApp from "./TopNavApp";

import styled, { ThemeProvider, StyledEngineProvider } from "styled-components";
import { GlobalStyles } from "../global";
import { theme } from "../theme";

export default function TopNav(props) {
  const [isLandingPageLocal, setIsLandingPageLocal] = useState(false);
  const { isDesktop } = useContext(MainContext);
  const { isLandingPage } = useContext(MainContext);

  useEffect(() => {
    isLandingPage ? setIsLandingPageLocal(true) : setIsLandingPageLocal(false);
  },[isLandingPage]);

  function TopNavDesktopSwitch(props) {
    return (
      <div>
        {isLandingPageLocal ?
          <TopNavDesktopLandingPage routes={props.routes} isLandingPage={isLandingPage}/>
          :
          <TopNavDesktop routes={props.routes} isLandingPage={isLandingPage}/>
        }
      </div>
    );
  }

  return (
    <div>
      {isDesktop ? (
        <TopNavDesktopSwitch routes={props.routes} />
      ) : (
        <TopNavApp routes={props.routes} />
      )}
    </div>
  );
}


