import React, { useState, useContext, useEffect } from "react";
import { MainContext } from "../contexts/MainContext.js";
import { UserContext } from "../contexts/UserContext.js";
import { useCart } from "react-use-cart";
import useGetProducts from "../components/utilities/useGetProducts";
import {
  Link,
  Route,
  useParams,
  useRouteMatch,
  useLocation,
  useHistory
} from "react-router-dom";
import parse from "html-react-parser";

import { motion } from "framer-motion";
import { pageVariantsSiblings, pageTransition } from "../transitions";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OnCardButton from "../components/buttons/OnCardButton";
import Button from "@mui/material/Button";
import SpecificationsTable from "../components/SpecificationsTable.js";
import FixedCTA from "../components/buttons/FixedCTA";
import Spacer from "../components/Spacer";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import ButtonLarge from "../components/buttons/ButtonLarge.js";
import DocumentationTabs from "../components/DocumentationTabs.js";
import BreadcrumbsComponent from "../components/BreadcrumbsComponent.js";
import ProductImageGallery from "../components/ProductImageGallery.js";

import { useAuthState } from "react-firebase-hooks/auth";
import firebase, { auth } from "../firebase";
import { Helmet } from "react-helmet";
import { useDocument } from "react-firebase-hooks/firestore";

import styled, { createGlobalStyle } from "styled-components";

const OverrideGlobalStyle = createGlobalStyle`
  .App {
    background-color: ${(props) => props.theme.backgroundLight};
    height: calc(100vh - 72px);
  }
`;

const StyledProductPage = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 24px;
  background-color: ${(props) => props.theme.backgroundLight};
  border-radius: 4px;
  .spd-content {
    max-width: 1140px;
    margin: 0 auto;
  }
  .description-and-image {
    display: flex;
  }
  .model-and-description {
    min-width: 420px;
    /* max-width: 380px; */
  }
  .model-name {
    font-size: 32px;
    font-weight: 600;
    white-space: nowrap;
  }
  .family-name {
    font-size: 16px;
    font-weight: 400;
  }
  .card-row1 {
    display: flex;
    padding: 0 8px;
  }
  .family-name {
    margin: 8px 0 16px 0;
    text-transform: capitalize;
  }
  .model-family {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 0 0 16px;
  }
  .product-image {
    height: auto;
    width: 100%;
    max-width: 750px;
    padding: 0 24px;
  }
  .gallery-container {
    padding: 0 24px;
  }
  .card-title {
    font-weight: 600;
    color: ${(props) => props.theme.textDark};
    text-transform: capitalize;
    height: fit-content;
  }
  .card-family {
  }
  .featured-specs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px;
  }
  .featured-spec {
    margin: 0 0 16px 0;
    display: flex;
    flex-direction: column;
  }
  .heading-2 {
    margin: 16px 0;
    font-size: 1.125rem;
    font-weight: 700;
  }
  .key-spec-title {
    font-size: 0.875rem;
    font-weight: 400;
    white-space: nowrap;
  }
  .key-spec-value {
    font-size: 0.875rem;
    font-weight: 600;
  }
  .description {
    margin: 16px 0;
    text-transform: none;
    font-size: 1rem;
  }
  .description ul {
    padding-inline-start: 1rem;
  }
  .specs-and-documentation {
    display: flex;
    width: 100%;
    max-width: 1000px;
    justify-content: space-between;
    margin: 24px 0 0 0;
  }
  .specs-container {
    min-width: 420px;
  }
  .specs-table {
  }
  .tabs-section-container {
    min-width: 420px;
  }
  .MuiIconButton-root {
    color: ${(props) => props.theme.textDark};
  }
  .card-buttons {
  }
  .MuiListItem-gutters {
    padding: 8px 0;
  }
  .MuiListItemText-primary {
    font-family: Titillium Web;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: ${(props) => props.theme.textDark};
    text-align: left;
    text-transform: capitalize;
    height: fit-content;
  }
  .safety-accordion {
    margin: 24px 0;
    padding: 0;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }
  .safety-accordion .MuiButtonBase-root {
    padding: 0;
  }
  .MuiAccordion-root:before {
    content: "";
    opacity: 0;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.12);
  }
  .loading {
    height: calc(100vh - 112px);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 24px;
    background-color: ${(props) => props.theme.backgroundLight};
  }
  .MuiCircularProgress-root {
    margin: 0 8px;
    width: 24px !important;
    height: 24px !important;
  }
  .loader {
    color: ${(props) => props.theme.grey900};
  }
`;

const linkResolver = (doc) => {
  // Pretty URLs for known types
  if (doc.type === "boom_lifts") return `/boom_lifts/${doc.uid}`;
  // Fallback for other types, in case new custom types get created
  return `/${doc.id}`;
};

export default function SingleProductPageDesktop(props) {
  const { currentProduct, setAppBackgroundGrey } = useContext(MainContext);
  const { userData } = useContext(UserContext);
  let history = useHistory();
  const { items, addItem } = useCart();
  const [keySpec1, setKeySpec1] = useState("");
  const [keySpec1Value, setKeySpec1Value] = useState("");
  const [keySpec2, setKeySpec2] = useState("");
  const [keySpec2Value, setKeySpec2Value] = useState("");
  const [keySpec3, setKeySpec3] = useState("");
  const [keySpec3Value, setKeySpec3Value] = useState("");
  const location = useLocation();
  const { category, subcategory, model } = useParams();
  let { path, url } = useRouteMatch();
  const [user] = useAuthState(auth);

  const { isLoading, isError, data, error } = useGetProducts(
    category,
    subcategory,
    model,
    currentProduct
  );

  const ChooseKeySpecs = () => {
    if (data) {
      switch (data.data.product_family) {
        case "Telehandler":
          setKeySpec1("Lift Capacity");
          setKeySpec1Value(`${data.data.maximum_lift_capacity}kgs`);
          setKeySpec2("Lift Height");
          setKeySpec2Value(`${data.data.maximum_lift_height}m`);
          setKeySpec3("Overall Weight");
          setKeySpec3Value(`${data.data.overall_weight_with_forks}kgs`);
          break;
        case "Boom Lifts":
          setKeySpec1("Platform Height");
          setKeySpec1Value(`${data.data.platform_height}m`);
          setKeySpec2("Capacity");
          setKeySpec2Value(`${data.data.platform_capacity}kgs`);
          setKeySpec3("Overall Weight");
          setKeySpec3Value(`${data.data.overall_weight}kgs`);
          break;
        case "Scissor Lifts":
          //Max Platform Height, Max Platform Capacity, Weight
          setKeySpec1("Platform Height");
          setKeySpec1Value(`${data.data.platform_height}m`);
          setKeySpec2("Capacity");
          setKeySpec2Value(`${data.data.platform_capacity}kg`);
          setKeySpec3("Overall Weight");
          setKeySpec3Value(`${data.data.overall_weight}kgs`);
          break;
        case "Vertical Lifts":
          //Max Platform Height, Max Platform Capacity, Weight
          setKeySpec1("Platform Height");
          setKeySpec1Value(`${data.data.platform_height}m`);
          setKeySpec2("Capacity");
          setKeySpec2Value(`${data.data.platform_capacity}kgs`);
          setKeySpec3("Overall Weight");
          setKeySpec3Value(`${data.data.overall_weight}kgs`);
          break;
        case "Forklifts":
          //Max Platform Height, Max Platform Capacity, Weight
          setKeySpec1("Lift Capacity");
          setKeySpec1Value(`${data.data.maximum_lift_capacity}kgs`);
          setKeySpec2("Lift Height");
          setKeySpec2Value(`${data.data.maximum_lift_height}m`);
          setKeySpec3("Overall Weight");
          setKeySpec3Value(`${data.data.overall_weight_with_forks}kgs`);
          break;
        default:
      }
    } else {
      return;
    }
  };

  const AddToCart = (doc) => {
    window.dataLayer.push({ event: "add_to_quote_click" });
    const cartItem = {
      id: doc.uid,
      idCMS: doc.id,
      type: doc.type,
      price: 0,
      data: doc.data,
      childOf: subcategory
    };
    addItem(cartItem);
  };

  useEffect(() => {
    ChooseKeySpecs();
  }, [data]);

  return (
    <motion.div
      className="sibling-animation-div"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariantsSiblings}
      transition={pageTransition}
    >
      <OverrideGlobalStyle />
      <StyledProductPage className="single-product-container-desktop">
        {isLoading ? (
          <div className="loading">
            <CircularProgress className="loader" />
            <h2>Loading</h2>
          </div>
        ) : isError ? (
          <p>Error:{error}</p>
        ) : data.data ? (
          <div className="spd-content">
            <Helmet>
              <title>{`Hire ${data.data.manufacturer} - ${data.data.model[0].text} | ${data.data.product_family} | GTH Equipment`}</title>
            </Helmet>
            <BreadcrumbsComponent
              productFamily={data.data.product_family}
              category={category}
              subcategory={subcategory}
              model={model}
            />
            <div className="description-and-image">
              <div className="model-and-description">
                {data.type !== "optional_extras" ? (
                  <h1 className="model-name">
                    {data.data.manufacturer} - {data.data.model[0].text}
                  </h1>
                ) : (
                  <h1 className="model-name">
                    {data.data.product_title[0].text}
                  </h1>
                )}
                <p className="family-name">{data.data.product_family}</p>
                <Divider className="divider" />
                {data.type !== "optional_extras" ? (
                  <h2 className="heading-2">Key Specs</h2>
                ) : null}
                {data.type !== "optional_extras" ? (
                  <div className="featured-specs">
                    <div className="featured-spec">
                      <h2 className="key-spec-title">{keySpec1}</h2>
                      <p className="key-spec-value">{keySpec1Value}</p>
                    </div>
                    <div className="featured-spec">
                      <h2 className="key-spec-title">{keySpec2}</h2>
                      <p className="key-spec-value">{keySpec2Value}</p>
                    </div>
                    <div className="featured-spec">
                      <h2 className="key-spec-title">{keySpec3}</h2>
                      <p className="key-spec-value">{keySpec3Value}</p>
                    </div>
                  </div>
                ) : null}
                <Divider className="divider" />
                <p className="description">
                  {data.data.product_description[0]
                    ? parse(data.data.product_description[0].text)
                    : null}
                </p>
                {/* <button onClick={() => console.log(data.data)}>log data</button>
                <button onClick={() => console.log(user)}>log user</button>
                <button onClick={() => console.log(userData)}>log userData</button>
                <button onClick={() => console.log(userData.customerType)}>userData.customerType</button>
                <div>
                    <h2 className="pricing-title">Pricing</h2>
                    {user && userData ? 
                    <div>
                    {userData.customerType == "wholesale" ?
                    <div>
                    <p className="price">Daily: ${data.data.daily_wholesale}</p>
                    <p className="price">Weekly: ${data.data.weekly_wholesale}</p>
                    </div>
                    :
                    <div>
                    <p className="price">Daily: ${data.data.daily_retail}</p>
                    <p className="price">Weekly: ${data.data.weekly_retail}</p>
                    </div>
                    }
                    </div>
                    : 
                    <p className="price" onClick={() => history.push("/account/signin")}>Login for Pricing</p>
                    }
                </div> */}
                <ButtonLarge
                  varient="primary"
                  text="Add to Quote"
                  type="AddToCart"
                  doc={data}
                  items={items}
                  onClick={() => AddToCart(data)}
                  margin="16px 0 0 0"
                  width="auto"
                />
                {data.type !== "optional_extras" ? null : (
                  <div>
                    <Spacer height="36px" />
                    <h2 className="heading-2">Documentation</h2>
                    <DocumentationTabs />
                  </div>
                )}
              </div>
              {data.data.gallery && data.data.gallery.length > 0 ? (
                <div className="gallery-container">
                  <ProductImageGallery gallery={data.data.gallery[0]} />
                </div>
              ) : (
                <img
                  src={data.data.product_image.url}
                  alt={model}
                  className="product-image"
                />
              )}
            </div>
            {data.type !== "optional_extras" ? (
              <div className="specs-and-documentation">
                <div className="specs-container">
                  <h2 className="heading-2">Specifications</h2>
                  <SpecificationsTable
                    className="specs-table"
                    data={data.data}
                    subcategory={data.type}
                  />
                </div>
                <div className="tabs-section-container">
                  <h2 className="heading-2">Documentation</h2>
                  <DocumentationTabs data={data.data} />
                </div>
              </div>
            ) : null}

            {/* <Spacer /> */}
            {/* <FixedCTA text="Add to Booking" onClick={() => AddToCart(data)} /> */}
          </div>
        ) : (
          <div>
            <h2>
              We could not find the product you are looking for, try browsing
              our Equipment section to find something suitable.
            </h2>
            <p>
              Please try refreshing the page or browsing for something else to
              suit your needs.
            </p>
            <p>
              Alternatively call us on 1300 608 608 and we will find something
              to suit your needs.
            </p>
          </div>
        )}
      </StyledProductPage>
    </motion.div>
  );
}
