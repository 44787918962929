import React, { useState, useEffect, useContext } from "react";
import { MainContext } from "../contexts/MainContext";
import {
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import CartItemsGroup from "../components/CartItemsGroup";
import QuickQuoteDetailsSection from "../components/QuickQuoteDetailsSection";
import { useCart } from "react-use-cart";

import { motion } from "framer-motion";
import { pageVariantsTopLevel, pageTransition } from "../transitions";

import Spacer from "../components/Spacer";
import Button from "@mui/material/Button";
import ButtonLarge from "../components/buttons/ButtonLarge";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { Helmet } from "react-helmet";

import styled, { createGlobalStyle } from "styled-components";

const OverrideGlobalStyle = createGlobalStyle`
  .App {
    background-color: ${(props) => props.theme.backgroundGrey50};
  }
`;

const PageContainer = styled.div`
  position: relative;
  width: ${(props) => (props.isDesktop ? "480px" : "100%")};
  height: 100%;
  margin: 0 auto;
  text-align: left;
  font-family: ${(props) => props.theme.fontPrimary};
  color: ${(props) => props.theme.textDark};
  /* background-color: ${(props) => props.theme.backgroundGrey50}; */
  background-color: ${(props) => props.theme.backgroundGrey50};
  transition: ${(props) => props.theme.allTransition};
  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .content-empty-cart {
    height: ${(props) => (props.isDesktop ? "100%" : "calc(100vh - 112px)")};
    padding: 24px;
    background-color: ${(props) => props.theme.backgroundLight};
    border-radius: ${(props) => (props.isDesktop ? "4px" : "none")};
    box-shadow: ${(props) =>
      props.isDesktop ? "0px 2px 10px rgba(0, 0, 0, 0.1)" : "none"};
  }
  .button-container {
    margin: 0 auto;
    padding: 0 24px 24px;
    width: 100%;
  }
  .button-and-spacer-container {
    margin: 0 auto;
    width: 100;
  }
`;

export default function CartPage(props) {
  const { isDesktop } = useContext(MainContext);
  let { path, url } = useRouteMatch();
  const { subcategory, filter } = useParams();
  const {
    isEmpty,
    cartTotal,
    totalUniqueItems,
    items,
    updateItemQuantity,
    removeItem,
    emptyCart,
  } = useCart();

  const CheckForExtras = (cartItem) => {
    return items.map((item) => (item.type === "optional_extras" ? item : null));
  };

  return (
    <motion.div
      className="page-animation-div"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariantsTopLevel}
      transition={pageTransition}
    >
      <Helmet>
        <title>{`Cart | GTH Equipment`}</title>
      </Helmet>
      <OverrideGlobalStyle />
      <PageContainer isDesktop={isDesktop}>
        <div className="content">
          {items.map((item) =>
            item.type !== "optional_extras" ? (
              <CartItemsGroup item={item} />
            ) : null
          )}
          {/* {items.map((item) =>
          item.type !== "optional_extras" ? <CartItemCard item={item} /> : null
        )} */}
        </div>

        {isEmpty ? (
          <div className="content-empty-cart">
            <h2>
              Your cart is empty. <br />
              To checkout, add something from the equipment pages.
            </h2>
            <ButtonLarge
              text="Explore Equipment"
              endIcon={<ArrowForwardIcon />}
              linkTo="/"
            />
          </div>
        ) : null}

        {!isEmpty ? (
          <div className="content">
            <QuickQuoteDetailsSection />
            <Spacer height="56px" />
          </div>
        ) : // <div className="button-container">
        //   <div className="button-and-spacer-container">
        //     {/* <Button className="add-extras-button" onClick={() => emptyCart()}>
        //     Empty Cart
        //   </Button> */}
        //     {/* <ButtonLarge
        //       text="Continue"
        //       endIcon={<ArrowForwardIcon />}
        //       linkTo="/confirm-details"
        //       // width={isDesktop ? "380px" : "100%"}
        //     /> */}
        //     {/* <ButtonLarge
        //       text="Get Quote"
        //       endIcon={<ArrowForwardIcon />}
        //       linkTo="/quick-quote"
        //       // width={isDesktop ? "380px" : "100%"}
        //     /> */}
        //     {/* <Spacer height="56px" /> */}
        //   </div>
        // </div>
        null}
        {/* <ButtonLarge text="Log Cart" onClick={() => console.log(items)} /> */}
      </PageContainer>
    </motion.div>
  );
}
