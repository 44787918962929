import React, { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";


export const MainContext = createContext();

const MainContextProvider = (props) => {
  let location = useLocation();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 780);
  const [isLandingPage, setIsLandingPage] = useState(false);
  const [theme, setTheme] = useState("light");
  const [accountDetails, setAccountDetails] = useState({});
  const [alertDialog, setAlertDialog] = useState({ open: false, error: "" });
  const [hiddenBottomNav, setHiddenBottomNav] = useState(false);
  const [mainMenuOpen, setMainMenuOpen] = useState(false);
  const [topNavTitle, setTopNavTitle] = useState("Page(1)");

  //The below, pertaining to fetching and storing product data, should likely be moved to its own context in the future
  const [productData, setProductData] = useState("");
  const [currentProduct, setCurrentProduct] = useState(null);
  const [optionalExtrasFor, setOptionalExtrasFor] = useState(null);
  const [currentCartItemId, setCurrentCartItemId] = useState(null);

  const Alert = (error) => {
    setAlertDialog({ open: true, error: error });
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const themeToggler = (arg) => {
    theme === "light" ? setTheme("dark") : setTheme("light");
  };

  const updateMedia = () => {
    setDesktop(window.innerWidth > 780);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  //-----isLandingPage Logic -  determine if the current page is part of a landing page flow

  // const CheckUrlForLandingPage = () => {
  // // test cases/strings
  // var str1 = location.pathname;
  // // do the test strings contain these terms?
  // var conditions = ["qq", "quick-quote"];
  // // run the tests against every element in the array
  // var test1 = conditions.some(el => str1.includes(el));
  // // display results
  // console.log(str1, ' isLandingPage? ', test1);
  // setIsLandingPage(test1);
  // }

  // useEffect(() => {
  //   CheckUrlForLandingPage();
  // },[location.pathname]);

  return (
    <MainContext.Provider
      value={{
        isDesktop,
        theme,
        setTheme,
        themeToggler,
        drawerOpen,
        setDrawerOpen,
        toggleDrawer,
        alertDialog,
        setAlertDialog,
        Alert,
        mainMenuOpen,
        setMainMenuOpen,
        hiddenBottomNav,
        setHiddenBottomNav,
        topNavTitle,
        setTopNavTitle,
        productData,
        setProductData,
        currentProduct,
        setCurrentProduct,
        optionalExtrasFor,
        setOptionalExtrasFor,
        currentCartItemId,
        setCurrentCartItemId,
        isLandingPage, 
        setIsLandingPage
      }}
    >
      {props.children}
    </MainContext.Provider>
  );
};

export default MainContextProvider;
