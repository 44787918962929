import React from "react";
import useGetProducts from "../components/utilities/useGetProducts";

import { Route, useRouteMatch } from "react-router-dom";
import ProductsList from "../components/ProductsList";

import "../styles.css";
import styled from "styled-components";

const PageContainer = styled.div`
  position: absolute;
  width: 100%;
  text-align: left;
  font-family: ${(props) => props.theme.fontPrimary};
  color: ${(props) => props.theme.textDark};
  /* background-color: ${(props) => props.theme.backgroundGrey50}; */
  background-color: ${(props) => props.theme.backgroundLight};
  transition: ${(props) => props.theme.allTransition};
  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 24px;
  }
  &.page-enter {
    opacity: 0;
    transform: scale(0.92);
  }
  &.page-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: all 0.2s cubic-bezier(0, 0, 0.2, 1) 0.1s;
  }
  &.page-exit {
    opacity: 1;
  }
  &.page-exit-active {
    opacity: 0;
    animation: opacity 0.1s cubic-bezier(0.4, 0, 1, 1) 0s;
  }
`;

export default function ReactQueryTestPage() {
  let { path, url } = useRouteMatch();

  const category = "telehandlers";
  const subcategory = "All";
  const model = null;

  const { isLoading, isError, data, error } = useGetProducts(
    category,
    subcategory,
    model
  );

  return (
    <PageContainer>
      <div className="content">
        <Route exact path={url}>
          <div>
            {isLoading ? (
              <div>
                <h2>Loading...</h2>
              </div>
            ) : isError ? (
              <p>Error:{error}</p>
            ) : data.results_size > 0 ? (
              <ProductsList productDataQ={data.results} />
            ) : (
              <div>
                <h2>There are no products here at the moment.</h2>
                <p>
                  Please try refreshing the page or browsing for something else
                  to suit your needs.
                </p>
                <p>
                  Alternatively call us on 1300 608 608 and we will find
                  something to suit your needs.
                </p>
              </div>
            )}
          </div>
          <button onClick={() => console.log(data)}>log data</button>
          <button onClick={() => console.log(isError)}>log error</button>
          <button onClick={() => console.log(isLoading)}>log isLoading</button>
          {/* <Button onClick={() => console.log(path)}>Path</Button>
          <Button onClick={() => console.log(url)}>URL</Button>
          <Button onClick={() => console.log(useRouteMatch)}>Match</Button>
          <Button onClick={() => console.log(props)}>Props</Button>
          <Button onClick={() => console.log(props.match.params)}>
            Params
          </Button> */}
        </Route>
        {/* <Route path="/:subcategory/:filter/:model">
            <SingleProductPage />
          </Route>
          <Route path={["/:family/optional-extras"]}>
            <OptionalExtrasPage />
          </Route> */}
      </div>
    </PageContainer>
  );
}
