import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";

import styled from "styled-components";

const StyledAccordion = styled(Accordion)`
  position: relative;
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-width: 320px;
  margin: 0 0 16px 0;
  padding: 24px;
  background-color: ${(props) => props.theme.white};
  border-radius: 4px;
  box-shadow: ${(props) => props.theme.cardBoxShadow};
  transition: 0.15s all ease-out;
  .MuiAccordionSummary-root {
    padding: 0;
  }
  .MuiAccordionSummary-content {
    margin: 0;
  }
  .MuiAccordionDetails-root {
    padding: 0;
  }
  .accordion-preview {
  }
  .short-description {
    margin: 16px 0 0 0;
  }
  .read-more-button-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 8px 0 0 0;
    margin: 0 0 -16px 0;
    opacity: 1;
    transition: 0.1s opacity ease-out;
  }
  .read-more-button {
    position: relative;
    width: fit-content;
  }
  .Mui-expanded .read-more-button-container {
    opacity: 0;
    /* visibility: hidden; */
  }
`;

export default function SubCategoryPageDetails(props) {
  const [shortDescription, setShortDescription] = useState("");
  const [longDescription, setLongDescription] = useState("");

  const descriptionContent = [
    {
      short:
        "When it comes to Telehandlers GTH Equipment offers the largest fleet in Sydney, ranging from 2.5t 6m to 13t 10m. These are all current model Telehandlers equipped with state of the art safety systems & the most current technology."
    }
  ];

  useEffect(() => {
    switch (props.category) {
      case "telehandlers":
        setShortDescription(
          <p className="short-description">
            When it comes to Telehandlers GTH Equipment offers the largest fleet
            in Sydney, ranging from 2.5t 6m to 13t 10m. These are all current
            model Telehandlers equipped with state of the art safety systems &
            the most current technology.
          </p>
        );
        setLongDescription(
          <p className="description-more">
            Telehandlers play a major role across many applications from
            Construction sites to Agricultural works. They are ideal for the
            movement of material by way of Fork Tines, Crane Jib, Bucket or
            Rotating Forks & have the ability to reach straight over objects
            when moving material on the job. We have the following options
            available on our Telehandlers also; Tyre Pressure Sensors,
            Conditional Registration, UHF Radio, Non-Marking Tyres & more.
            <br /> <br /> We stock only the most trusted brands; Haulotte, Merlo
            & Manitou for quality & craftsmanship.
            <br /> <br /> Whether you're a builder, rigger or Farmer these are
            definitely for you. Servicing the greater Sydney Metropolitan area &
            regional suburbs of NSW GTH Equipment are here to help.
          </p>
        );
        break;
      case "boom_lifts":
        setShortDescription(
          <p className="short-description">
            When it comes to Boom Lifts GTH Equipment offers an extensive fleet
            with a lift height ranging from 10m to 42m all of which are current
            model booms with all state of the art safety systems on board. Our
            Boom Lifts come with the new Secondary Basket Safety Systems known
            as (Crush Protection, Secondary Guarding or Crush Bar) which protect
            the operator at heights.
          </p>
        );
        setLongDescription(
          <p className="description-more">
            Boom Lifts play a major role in getting people to heights safely &
            are ideal for reaching straight over objects or up & over
            structures. We have optioned our Boom Lifts to be equipped with on
            board 7 kVA generators so power tools can be used from the work
            platform avoiding the use of power leads.
            <br /> <br /> Our Boom Lifts are equipped with Non-Marking Tyres
            which is perfect for those finished concrete areas & shopping
            centres. We stock most trusted brands; Haulotte, JLG, Nifty & Genie
            for quality & craftsmanship. Basket capacities vary from 227kg to
            450kg.
            <br /> <br /> Whether you're a painter, rigger, roofer, electrician,
            plumber, signage installer, fire fitter, plasterer or construction
            worker these are definitely for you. Servicing the greater Sydney
            Metropolitan area & regional suburbs of NSW GTH Equipment are here
            to help.
          </p>
        );
        break;
      case "scissor_lifts":
        setShortDescription(
          <p className="short-description">
            When it comes to Scissor Lifts GTH Equipment offers an extensive
            fleet ranging from 4.5m to 18m platform heights which are all
            current model scissor lifts with all state of the art safety
            systems. Some of our Scissor Lifts have the optional new Secondary
            Basket Safety Systems which protect the operator at heights from
            being injured.
          </p>
        );
        setLongDescription(
          <p className="description-more">
            Scissor Lifts play a major role in getting people to heights safely,
            our range offers you unmatched performance & versatility boosting
            productivity on the job site.
            <br /> <br /> Our Scissor Lifts are equipped with Non-Marking Tyres
            which are perfect for those finished concrete areas & shopping
            centres. We stock most trusted brands; GMG, Haulotte, JLG & Genie
            for quality & craftsmanship. Basket capacities vary from 200kg to
            1000kg.
            <br /> <br /> Whether you're a painter, rigger, roofer, electrician,
            plumber, signage installer, fire fitter, plasterer or construction
            worker these are definitely for you. Servicing the greater Sydney
            Metropolitan area & regional suburbs of NSW GTH Equipment are here
            to help.
          </p>
        );
        break;
      case "vertical_lifts":
        setShortDescription(
          <p className="short-description">
            When it comes to Vertical Lifts GTH Equipment offers an extensive
            fleet ranging from 3.8m to 5.95m platform heights, which are all
            current model Vertical lifts with state of the art safety systems
            built in.
          </p>
        );
        setLongDescription(
          <p className="description-more">
            Vertical Lifts play a major role in getting people to heights
            safely, our range offers you unmatched performance & versatility
            boosting productivity on the job site.
            <br /> <br /> Our Vertical Lifts are equipped with Non-Marking Tyres
            which are perfect for those finished concrete areas & shopping
            centres. We stock most trusted brands; GMG & Haulotte for quality &
            craftsmanship. Basket capacities vary from 200kg to 230kg.
            <br /> <br /> Whether you're a painter, electrician, plumber,
            signage installer, fire fitter, plasterer or construction worker
            these are definitely for you. Servicing the greater Sydney
            Metropolitan area & regional suburbs of NSW GTH Equipment are here
            to help.
          </p>
        );
        break;
      case "forklifts":
        setShortDescription(
          <p className="short-description">
            When it comes to Forklifts GTH Equipment offers an extensive fleet
            in Sydney, ranging from 2.5t LPG to 7t LPG. These are all current
            model Forklifts equipped with state of the art safety systems &
            technology.
          </p>
        );
        setLongDescription(
          <p className="description-more">
            We have LPG & Diesel alternatives to suit all different
            applications.
            <br /> <br />
            Forklifts play a major role across many applications from
            Construction sites to Industrial works. They are ideal for the
            movement of material by way of Fork Tines, Crane Jib or Rotating
            Forks.
            <br /> <br /> We stock only the most trusted brands; Hyster &
            Manitou for quality & craftsmanship.
            <br /> <br /> Whether you're a builder, rigger, HVAC installer, Fire
            Fitter or Racking Installer these are definitely for you. Servicing
            the greater Sydney Metropolitan area & regional suburbs of NSW GTH
            Equipment are here to help.
          </p>
        );
        break;
      default:
        setShortDescription(<p className="short-description"></p>);
        setLongDescription(<p className="description-more"></p>);
    }
  }, [props.category]);

  return (
    <StyledAccordion>
      <AccordionSummary aria-controls="panel1a-content">
        <div className="accordion-preview">
          <h2>Hire {props.pageTitle} in Sydney</h2>
          {shortDescription}
          <div className="read-more-button-container">
            <Button
              className="read-more-button"
              aria-label="Read More About Hiring Equipment"
            >
              Read More
            </Button>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="page-description-inner">{longDescription}</div>
      </AccordionDetails>
    </StyledAccordion>
  );
}
