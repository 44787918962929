import React, { useState, useContext } from "react";
import { MainContext } from "../../contexts/MainContext";
import { addLocationToDB } from "../../firebase";
import Drawer from "@mui/material/Drawer";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import FixedCTAwSubmit from "../buttons/FixedCTAwSubmit";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { FormControl } from "@mui/material";
import InputBoxed from "../InputBoxed";
import Spacer from "../Spacer";

import styled from "styled-components";

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    height: 100%;
    overflow-x: scroll;
    width: ${(props) => (props.isDesktop ? "480px" : "100%")};
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: ${(props) => props.theme.backgroundLight};
    border-radius: ${(props) => (props.isDesktop ? "8px" : "0")};
  }
  .top-bar-drawer {
    position: relative;
    display: flex;
    justify-content: center;
    height: 56px;
    width: ${(props) => (props.isDesktop ? "480px" : "100%")};
    /* top: 0; */
    background-color: ${(props) => props.theme.backgroundLight};
    box-shadow: 0px 2px 4px #0000000c;
    overflow: visible;
    transition: ${(props) => props.theme.backgroundTransition};
    .close-title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .page-title-top {
      color: ${(props) => props.theme.textDark};
      font-size: 1rem;
      text-transform: capitalize;
      padding: 0 0 0 48px;
    }
    .close-drawer-button {
      color: ${(props) => props.theme.textDark};
    }
    & .MuiTabs-flexContainer {
      justify-content: space-between;
      align-items: center;
      height: 100%;
    }
    & .PrivateTabIndicator-colorSecondary-66 {
      /* background-color: #2c2c28 !important; */
      transition: all 0.25s;
    }
    & .PrivateTabIndicator-colorSecondary-3 {
      color: ${(props) => props.theme.white} !important;
    }
    .MuiTabs-indicator {
      display: none;
    }
    .MuiTab-root {
      background-color: none;
      color: ${(props) => props.theme.textDark};
      transition: all 0.25s ease-out;
    }
    @media only screen and (max-width: 767px) {
      padding: 0px;
    }
    .site-logo-mobile {
      width: 100%;
      max-width: 160px;
      height: auto;
      text-transform: none;
      fill: ${(props) => props.theme.textDark};
      transition: ${(props) => props.theme.allTransition};
      transition: fill 0.25s ease-out;
    }
    .site-logo-mobile .st2 {
      fill: ${(props) => props.theme.textDark};
    }
  }
  .add-location-heading {
    padding: 24px 24px 0;
  }
  .form-container {
    display: flex;
    max-width: 480px;
    width: 100%;
    margin: 0 auto;
    padding: 8px 24px 24px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .MuiFormControl-root {
    width: 100%;
    max-width: 480px;
  }
  .body-text {
    font-size: 14px;
    font-weight: 600;
    font-family: ${(props) => props.theme.fontPrimary};
    color: ${(props) => props.theme.textDark};
    margin: 8px 0 0 0;
  }
  .link-text {
    text-align: right;
    font-size: 14px;
    font-weight: 600;
    font-family: ${(props) => props.theme.fontPrimary};
    color: ${(props) => props.theme.textDark};
    text-decoration: none;
    margin: 16px 0 0 0;
  }
`;

export default function AddLocationDrawer(props) {
  const { isDesktop } = useContext(MainContext);

  const [contactName, setContactName] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactInstructions, setContactInstructions] = useState("");
  const [locationRequirements, setLocationRequirements] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [postcode, setPostcode] = useState("");

  const [submitStatus, setSubmitStatus] = useState(""); //"", "submitting", "success" or "error"

  const ResetState = () => {
    setContactName("");
    setContactPhone("");
    setContactInstructions("");
    setLocationRequirements("");
    setStreetAddress("");
    setCity("");
    setPostcode("");
    setSubmitStatus("");
    props.setOpen(false);
  };

  const SubmitDBEntry = () => {
    var newLocation = {
      contactName: contactName,
      contactPhone: contactPhone,
      contactInstructions: contactInstructions,
      locationRequirements: locationRequirements,
      streetAddress: streetAddress,
      city: city,
      postcode: postcode
    };

    setSubmitStatus("submitting");
    addLocationToDB(newLocation)
      .then(function () {
        setSubmitStatus("success");
        console.log("Document successfully written!");
        setTimeout(() => ResetState(), 600);
      })
      .catch(function (error) {
        setSubmitStatus("error");
        console.log(error);
        console.error("Error writing document: ", error);
        props.setOpen(false);
      });
  };

  return (
    <StyledDrawer
      anchor="bottom"
      open={props.open}
      onClose={""}
      onOpen={""}
      isDesktop={isDesktop}
    >
      <AppBar className="top-bar-drawer" position="static">
        <div className="close-title-container">
          <h1 className="page-title-top">Add New Location</h1>
          <IconButton
            className="close-drawer-button"
            aria-label="close"
            onClick={() => props.setOpen(false)}
            size="large">
            <CloseIcon />
          </IconButton>
        </div>
      </AppBar>
      {/* <h2 className="add-location-heading">Add New Location Below</h2> */}
      <div className="form-container">
        <FormControl required>
          <InputBoxed
            label="Contact Name at Location"
            id="contact-name"
            name="contact-name"
            value={contactName}
            onChange={(e) => setContactName(e.target.value)}
          />
        </FormControl>
        <FormControl required>
          <InputBoxed
            label="Contact Phone at Location"
            id="contact-phone"
            name="contact-phone"
            value={contactPhone}
            onChange={(e) => setContactPhone(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <InputBoxed
            label="Contact Instructions"
            id="contact-instructions"
            name="contact-instructinos"
            value={contactInstructions}
            onChange={(e) => setContactInstructions(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <InputBoxed
            label="Locations Requirements"
            id="location-requirements"
            name="location-requirements"
            value={locationRequirements}
            onChange={(e) => setLocationRequirements(e.target.value)}
          />
        </FormControl>
        <FormControl required>
          <InputBoxed
            label="Street Address"
            id="street-address"
            name="street-address"
            value={streetAddress}
            onChange={(e) => setStreetAddress(e.target.value)}
          />
        </FormControl>
        <FormControl required>
          <InputBoxed
            label="City"
            id="city"
            name="city"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </FormControl>
        <FormControl required>
          <InputBoxed
            label="Postcode"
            id="postcode"
            name="postcode"
            value={postcode}
            onChange={(e) => setPostcode(e.target.value)}
          />
        </FormControl>
        <Spacer height="48px" />
      </div>
      <FixedCTAwSubmit
        text="Save Location"
        submitStatus={submitStatus}
        onClick={() => SubmitDBEntry()}
        width={isDesktop ? "480px" : "100vw"}
      />
    </StyledDrawer>
  );
}
