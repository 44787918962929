import React, { Fragment, useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import ShareIcon from "@mui/icons-material/Share";
import { TrainRounded } from "@mui/icons-material";

const ShareButton = (props) => {
  // const shareUrl = "http://gth.com";
  // const title = "GTH";
  const [nativeShare, setNativeShare] = useState(false);

  function triggerShare() {
    console.log(props.text);
    if ("share" in navigator) {
      navigator.share({
        url: `${props.url}`,
        title: `${props.title}`,
        text: `${props.text}`
      });
    } else {
      alert("Share feature is not available");
      //put some logic here to open share menu (material ui menu) on desktop or all unsupported devices
    }
  }

  useEffect(() => {
    if ("share" in navigator) {
      setNativeShare(true);
    }
  }, []);

  return (
    <Fragment>
      {nativeShare ? (
        <IconButton
          aria-label="share-or-email"
          onClick={() => triggerShare(props)}
          size="large">
          <ShareIcon fontSize="small" />
        </IconButton>
      ) : null}
      {/* Put a material ui menu here with share icons (react-share) */}
    </Fragment>
  );
};

export default ShareButton;
