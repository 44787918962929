import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { useCart } from "react-use-cart";

import styled from "styled-components";

const StyledButton = styled(Button)`
  width: 100%;
  height: 48px;
  background-color: ${(props) =>
    props.isCTA ? props.theme.primary : props.theme.grey100};
  border-radius: 0 0 4px 4px;
  box-shadow: none;
  margin: ${(props) => props.margin};
  padding: 12px auto;
  font-family: ${(props) => props.theme.fontPrimary};
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => (props.isCTA ? props.theme.white : props.theme.grey900)};
  transition: 0.2s all ease-out;
  .MuiButton-label {
    text-decoration: none;
  }
  &:hover {
    background: ${(props) =>
      props.isCTA ? props.theme.primaryDark : props.theme.grey400};
    color: ${(props) =>
      props.isCTA ? props.theme.white : props.theme.grey900};
    box-shadow: none;
  }
  &:active {
    background-color: ${(props) =>
      props.varient === "primary" ? props.theme.primary : props.theme.grey900};
    color: ${(props) => props.theme.white};
    transform: scale(1.05);
    transition: 0.1s all ease-out;
    z-index: 200;
  }
  @media screen and (max-width: 767px) {
    font-size: 14px;
    margin: ${(props) => props.margin};
  }
`; 

export default function OnCardButton(props) {
  const [addToCartButtonText, setAddToCartButtonText] = useState(
    "Add To Quote"
  );
  const { items, addItem, getItem } = useCart();
  const [quantityInCart, setQuantityInCart] = useState(null);

  const AddToCartButtonText = () => {
    const item = getItem(props.doc.uid);
    if (items.some((e) => e.id === props.doc.uid)) {
      setAddToCartButtonText(`View in Cart (${item.quantity})`);
      setQuantityInCart(item.quantity);
    } else {
      setAddToCartButtonText("Add to Quote");
      setQuantityInCart(null);
    }
  };

  useEffect(() => {
    if (items && props.doc) {
      AddToCartButtonText();
    }
  }, [items]);

  return (
    <StyledButton
      className="button-large"
      variant="contained"
      onClick={props.onClick}
      to={props.linkTo}
      margin={props.margin ? props.margin : "12px 0 0 0"}
      endIcon={props.endIcon}
      isCTA={props.isCTA}
      type={props.type}
    >
      {props.type === "AddToCart" ? addToCartButtonText : props.text}
    </StyledButton>
  );
}
