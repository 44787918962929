import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family:'Titillium Web', sans-serif;
    background-color: ${(props) => props.theme.backgroundGrey50};
    transition: ${(props) => props.theme.backgroundTransition};
  }

  *, *::after, *::before {
    box-sizing: border-box;
  }

  h1 {
    font-size: 1.5rem; 
    margin: 0;
    line-height: 1.2;

  }

  h2 {
    font-size: 1.125rem; 
    margin: 0;
    line-height: 1.2;
  }

  h3 {
    font-size: 1rem; 
    margin: 0;
  }

  h4 {
    font-size: 0.875rem; 
    margin: 0;
  }

  p, .MuiTypography-body1 {
    font-size: 0.875rem; 
    margin: 0;
  }

  img {
    /* border-radius: 5px;
    height: auto;
    width: 10rem; */
  }

  div {
    /* text-align: center; */
  }

  small {
    /* display: block; */
  }

  a {
    /* color: ${({ theme }) => theme.primaryHover};
    text-decoration: none; */
  }

  .MuiTabs-root {
    overflow: visible;
  }
  div.MuiTabs-fixed {
    overflow: visible !important;
  }

  .MuiButton-textPrimary {
    color: black;
  }
`;
