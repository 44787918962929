// theme.js
export const theme = {};

export const lightTheme = {
  // Font Styles
  fontPrimary: "'Titillium Web', sans-serif",
  fontSecondary: "Raleway, san-serif",
  textLight: "#FFFFFF",
  textDark: "#232323",
  // Font Sizes
  buttonFontSize: "0.875rem",

  // Colors
  white: "white",
  black: "black",
  primary: "#E11919",
  primaryVariant: "#af2c23",
  primaryLight: "#e95241",
  primaryDark: "#790000",
  primaryHover: "#FEF4F4",
  secondary: "#333",
  secondaryVariant: "#333",
  secondaryLight: "#232323",
  secondaryDark: "#EFFFFA",
  secondaryHover: "#04fbc0",
  grey100: "#F5F5F5",
  grey200: "#E0E0E0",
  grey400: "#ADADAD",
  grey600: "#7A7A7A",
  grey900: "#282828",
  backgroundLight: "#FFFFFF",
  backgroundGrey50: "#F7F7F7",
  backgroundDark: "#292929",

  // Other Variables
  mobile: "576px",
  allTransition: "0.2s all ease-out;",
  colorTransition: "0.2s color ease-out;",
  backgroundTransition: "0.2s background-color ease-out;",
  cardBoxShadow: "0px 2px 4px #0000000c;"
};

export const darkTheme = {
  // Font Styles
  fontPrimary: "'Titillium Web', sans-serif",
  fontSecondary: "Raleway, san-serif",
  textLight: "#232323",
  textDark: "#FFFFFF",
  // Font Sizes
  buttonFontSize: "0.875rem",

  // Colors
  white: "white",
  black: "#232323",
  primary: "#E11919",
  primaryVariant: "#af2c23",
  primaryLight: "#e95241",
  primaryDark: "#790000",
  primaryHover: "#FEF4F4",
  secondary: "#333",
  secondaryVariant: "#333",
  secondaryLight: "#232323",
  secondaryDark: "#EFFFFA",
  secondaryHover: "#04fbc0",
  grey100: "#474747",
  grey200: "#E0E0E0",
  grey400: "#ADADAD",
  grey600: "#474747",
  grey900: "#282828",
  backgroundLight: "#292929",
  backgroundGrey50: "#F7F7F7",
  backgroundDark: "#FFFFFF",

  // Other Variables
  mobile: "576px",
  allTransition: "0.2s all ease-out;",
  colorTransition: "0.2s color ease-out;",
  backgroundTransition: "0.2s background-color ease-out;",
  cardBoxShadow: "0px 2px 4px #0000000c;"
};

export const snackbarOptions = {
  position: "bottom-center",
  style: {
    backgroundColor: "#232323",
    border: "none",
    fontFamily: "'Titillium Web', sans-serif",
    fontSize: "16px",
    fontWeight: "600",
    textAlign: "center",
    boxShadow: "0px 2px 4px #0000000c;",
    zIndex: "1050"
    // textTransform: "uppercase"
  },
  closeStyle: {
    color: "#E11919",
    fontSize: "16px"
  }
};

export const snackbarOptionsHigher = {
  position: "bottom-center",
  style: {
    marginBottom: "55px",
    backgroundColor: "#232323",
    border: "none",
    fontFamily: "'Titillium Web', sans-serif",
    fontSize: "16px",
    fontWeight: "600",
    textAlign: "center",
    boxShadow: "0px 2px 4px #0000000c;",
    zIndex: "1050"
    // textTransform: "uppercase"
  },
  closeStyle: {
    color: "#E11919",
    fontSize: "16px"
  }
};

// export const lightTheme = {
//   fontPrimary: "'Titillium Web', sans-serif",
//   fontSecondary: "Raleway, san-serif",
//   text: "#232323",
//   textInverted: "white",
//   primaryLight: "#232323",
//   primaryHover: "#04fbc0",
//   primaryDark: "#EFFFFA",
//   accentLight: "#04fbc0",
//   accentHover: "#03C99B",
//   accentDark: "#02644D",
//   backgroundLight: "white",
//   backgroundLightTrans: "transparent",
//   backgroundDark: "#F7F7F7",
//   backgroundGrey: "#F2F2F2",
//   backgroundDarkGrey: "#454545",
//   backgroundControlPanels: "#E0E0E0",
//   buttonBG: "#232323",
//   cardBG: "#f2f2f2", //use for background color of cards
//   mobile: "576px",
//   shadow: "#bdbdbd",
//   shadowSelected: "#e0e0e0",
//   backgroundTransition: "0.2s background-color ease-out;"
// };

// export const darkTheme = {
//   fontPrimary: "'Titillium Web', sans-serif",
//   fontSecondary: "",
//   text: "white",
//   textInverted: "#232323",
//   primaryLight: "#232323",
//   primaryHover: "#04fbc0",
//   primaryDark: "#EFFFFA",
//   accentLight: "#04fbc0",
//   accentHover: "#03C99B",
//   accentDark: "#03c99b",
//   backgroundLight: "#292929",
//   backgroundLightTrans: "transparent",
//   backgroundDark: "#F7F7F7",
//   backgroundGrey: "#454545",
//   backgroundDarkGrey: "#454545",
//   backgroundControlPanels: "#3D3D3D",
//   buttonBG: "white",
//   cardBG: "#454545", //use for background color of cards
//   mobile: "576px",
//   shadow: "#292929",
//   shadowSelected: "#333333",
//   backgroundTransition: "0.2s background-color ease-out;"
// };

// primary:
// primaryLight:
// primaryDark:

// secondary:
// secondaryLight:
// secondaryDark:

// neutralDarker:
// neutrayDark:
// neutral:
// neutralLight:
// neutralLighter:
// neutralLightest:

// light-green-100:
// light-green-200:
// light-green-300:
// // etc
// light-green-900:
// light-green-A200:
// light-green-A400:

// deep-purple-100:
// deep-purple-200:
// deep-purple-300:
// // etc
// deep-purple-A900:
