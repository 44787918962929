import React, { useState, useEffect, useContext } from "react";
import { MainContext } from "../contexts/MainContext.js";
import ProductSingleCardDesktop from "./ProductSingleCardDesktop";
import OptionalExtraCardDesktop from "./OptionalExtraCardDesktop";
import Button from "@mui/material/Button";
import { Switch, Route, useParams, useRouteMatch } from "react-router-dom";
import useGetProducts from "./utilities/useGetProducts";
import CircularProgress from "@mui/material/CircularProgress";

import { motion } from "framer-motion";
import {
  pageVariantsTopLevel,
  pageTransition,
  listItem,
  container
} from "../transitions";

import { client, linkResolver, apiEndpoint } from "../prismic-configuration";
import Prismic from "prismic-javascript";

import styled from "styled-components";
import Spacer from "./Spacer.js";

const StyledDiv = styled.div`
  width: 1008px;
  margin: 0 0 0 8px;
  @media screen and (min-width: 1600px) {
    width: 1344px;
  }
  .products-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .loading {
    height: calc(100vh - 112px);
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 48px;
    background-color: ${(props) => props.theme.backgroundGrey50};
  }
  .MuiCircularProgress-root {
    width: 24px !important;
    height: 24px !important;
  }
  .loader {
    margin: 0 8px;
    color: ${(props) => props.theme.grey900};
  }
  .no-products {
    height: calc(100vh - 112px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 24px;
    background-color: ${(props) => props.theme.backgroundGrey50};
  }
  .no-products h2 {
    margin: 0 0 16px;
    text-transform: capitalize;
  }
`;

export default function ProductsListDesktop(props) {
  let { path, url } = useRouteMatch();

  const { isLoading, isError, data, error } = useGetProducts(
    props.category,
    props.subcategory,
    null
  );

  const RemoveDash = (str1) => {
    let temp1 = [str1.replaceAll("-", " ")];
    let temp2 = [temp1[0].replaceAll("_", " ")];
    console.log(temp2);
    return temp2;
  };

  // console.log(useParams());

  return (
    <StyledDiv>
      {/* <button onClick={() => console.log(data)}>data</button>
      <button onClick={() => console.log(props.category)}>category</button>
      <button onClick={() => console.log(props.subcategory)}>
        subcategory
      </button> */}
      {/* <Button onClick={() => console.log(url)}>Log</Button> */}

      {isLoading ? (
        <div className="loading">
          <CircularProgress className="loader" />
          <h2>Loading</h2>
        </div>
      ) : isError ? (
        <div className="no-products">
          <h2>Coming Soon.</h2>
          <p>
            We are still populating the list of all available optional extras.
          </p>
          <p>
            To see any available optional extras for a given piece of equipment,
            please click "Add Extras" on the cart page.
          </p>
          <p>
            Alternatively call us on 1300 608 608 and we will find something to
            suit your needs.
          </p>
        </div>
      ) : props.category !== "optional_extras" && data.results_size > 0 ? (
        // <motion.div
        //   className="sibling-animation-div"
        //   initial="initial"
        //   animate="in"
        //   exit="out"
        //   variants={pageVariantsTopLevel}
        //   transition={pageTransition}
        // >
        //   <div className="products-list">
        //     {data.results.map((item, i) => (
        //       <ProductSingleCardDesktop doc={item} />
        //     ))}
        //     <Spacer />
        //   </div>
        // </motion.div>
        <motion.div
          className="products-list"
          variants={container}
          initial="hidden"
          animate="show"
        >
          {data.results.map((item, i) => (
            <motion.div key={i} variants={listItem}>
              <ProductSingleCardDesktop doc={item} categoryQueryQQ={props.categoryQueryQQ}/>
            </motion.div>
          ))}
          <Spacer />
        </motion.div>
      ) : props.category === "optional_extras" && data.results_size < 0 ? (
        <div className="no-products">
          <h2>Coming Soon.</h2>
          <p>
            We are still populating the list of all available optional extras.
          </p>
          <p>
            To see any available optional extras for a given piece of equipment,
            please click "Add Extras" on the cart page.
          </p>
          <p>
            Alternatively call us on 1300 608 608 and we will find something to
            suit your needs.
          </p>
        </div>
      ) : props.category === "optional_extras" && data.results_size > 0 ? (
        <motion.div
          className="products-list"
          variants={container}
          initial="hidden"
          animate="show"
        >
          {data.results.map((item, i) => (
            <motion.div key={i} variants={listItem}>
              <OptionalExtraCardDesktop
                doc={item}
                subcategory={props.subcategory}
              />
            </motion.div>
          ))}
          <Spacer />
        </motion.div>
      ) : props.subcategory === "optional-extras" ? (
        <div className="no-products">
          <h2>Coming Soon.</h2>
          <p>
            We are still populating the list of all available optional extras.
          </p>
          <p>
            To see any available optional extras for a given piece of equipment,
            please click "Add Extras" on the cart page.
          </p>
          <p>
            Alternatively call us on 1300 608 608 and we will find something to
            suit your needs.
          </p>
        </div>
      ) : props.subcategory === "license-requirements" ? (
        <div className="no-products">
          <h2>{RemoveDash(props.category)} - License Requirements</h2>
          <h3>Coming Soon</h3>
          <p>We are still building this section of the app.</p>
          <p>
            For information on the licensing requirements of a specific product,
            please refer to the relevant regulatory website for your state.
          </p>
          <p>
            Alternatively, call us on 1300 608 608 and we will find something to
            suit your needs.
          </p>
        </div>
      ) : (
        <div className="no-products">
          <h2>There are no products here at the moment.</h2>
          <p>
            Please try refreshing the page or browsing for something else to
            suit your needs.
          </p>
          <p>
            Alternatively call us on 1300 608 608 and we will find something to
            suit your needs.
          </p>
        </div>
      )}
      {/* {props.productDataQ
        ? props.productDataQ.map((item, i) => <ProductSingleCard doc={item} />)
        : null} */}
    </StyledDiv>
  );
}
