import React, { useState, useEffect } from "react";
import FilterToggleButton from "./buttons/FilterToggleButton";
import styled from "styled-components";

const StyledProductFiltersDesktop = styled.div`
  display: flex;
  flex-direction: column;
`;

export default function ProductFiltersDesktop(props) {
  const [filters, setFilters] = useState(null);

  const ChooseFilters = (category) => {
    switch (category) {
      case "telehandlers":
        setFilters([
          { name: "All", to: "all" },
          { name: "Diesel", to: "diesel" }
          // { name: "Optional Extras", to: "optional-extras" },
          // {
          //   name: "License Requirements",
          //   to: "license-requirements"
          // }
        ]);
        break;
      case "boom_lifts":
        setFilters([
          { name: "All", to: "all" },
          { name: "Diesel", to: "diesel" },
          { name: "Electric", to: "electric" },
          { name: "Hybrid", to: "hybrid" },
          { name: "Spider", to: "spider" }
          // { name: "Optional Extras", to: "optional-extras" },
          // {
          //   name: "License Requirements",
          //   to: "license-requirements"
          // }
        ]);
        break;
      case "scissor_lifts":
        setFilters([
          { name: "All", to: "all" },
          { name: "Diesel", to: "diesel" },
          { name: "Electric", to: "electric" },
          { name: "Hybrid", to: "hybrid" },
          { name: "Tracked", to: "tracked" }
          // { name: "Optional Extras", to: "optional-extras" },
          // {
          //   name: "License Requirements",
          //   to: "license-requirements"
          // }
        ]);
        break;
      case "vertical_lifts":
        setFilters([
          { name: "All", to: "all" },
          { name: "Electric", to: "electric" },
          { name: "Push Around", to: "push-around" }
          // {
          //   name: "Optional Extras",
          //   to: "optional-extras"
          // },
          // {
          //   name: "License Requirements",
          //   to: "license-requirements"
          // }
        ]);
        break;
      case "forklifts":
        setFilters([
          { name: "All", to: "all" },
          { name: "Diesel", to: "diesel" },
          { name: "Gas", to: "gas" }
          // {
          //   name: "Optional Extras",
          //   to: "optional-extras"
          // },
          // {
          //   name: "License Requirements",
          //   to: "license-requirements"
          // }
        ]);
        break;
      default:
    }
  };

  useEffect(() => {
    ChooseFilters(props.category);
    console.log("useEffect ran");
  }, [props.category]);

  return (
    <StyledProductFiltersDesktop>
      {filters
        ? filters.map((filter, i) => (
            <FilterToggleButton
              category={props.category}
              subcategory={props.subcategory}
              text={filter.name}
              value={filter.to}
            />
          ))
        : null}
    </StyledProductFiltersDesktop>
  );
}
