import React from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import styled from "styled-components";

const StyledButton = styled(Button)`
  width: fit-content;
  max-width: 380px;
  height: 36px;
  background-color: ${(props) =>
    props.value === props.subcategory
      ? props.theme.grey900
      : props.theme.grey100};
  border: ${(props) => `solid 1px ${props.theme.grey200}`};
  border-radius: 50px;
  box-shadow: none;
  margin: 16px 0 0 0;
  padding: 12px 24px;
  font-family: ${(props) => props.theme.fontPrimary};
  font-size: 14px;
  font-weight: 700;
  color: ${(props) =>
    props.value === props.subcategory
      ? props.theme.white
      : props.theme.grey900};
  white-space: nowrap;
  transform: scale(1);
  transition: 0.2s all ease-out;
  .MuiButton-label {
    text-decoration: none;
  }
  &:hover {
    background: ${(props) =>
      props.value === props.subcategory
        ? props.theme.grey900
        : props.theme.grey400};
    box-shadow: none;
  }
  &:active {
    background-color: ${(props) =>
      props.varient === "primary" ? props.theme.primary : props.theme.grey900};
    color: ${(props) => props.theme.white};
    transform: scale(1.05);
    transition: 0.1s all ease-out;
  }
`;

export default function FilterToggleButton(props) {
  return (
    <StyledButton
      className="button-large"
      variant="contained"
      onClick={props.onClick}
      component={Link}
      to={`/${props.category}/${props.value}`}
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      varient={props.varient} //dark or primary
      value={props.value}
      subcategory={props.subcategory}
    >
      {props.text}
    </StyledButton>
  );
}
