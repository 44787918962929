import React, { createContext, useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import firebase, { auth } from "../firebase";
import { useDocument } from "react-firebase-hooks/firestore";


export const UserContext = createContext();

const UserContextProvider = (props) => {
    const [user] = useAuthState(auth);
    const [userData, setUserData] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [companyABN, setCompanyABN] = useState("");
    const [companyPosition, setCompanyPosition] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [locations, setLocations] = useState("");
    const [customerType, setCustomerType] = useState("");


  //Use this context to set things like enable/disable TopNav, BottomNav, DarkMode etc
  //Maybe isDesktop should move here?

  useEffect(() => {});

  useEffect(() => {
    if (user) {
      const fetchUserData = async () => {
        const userDocRef = firebase.firestore().collection("users").doc(user.uid);
        const snapshot = await userDocRef.get();
        if (snapshot.exists) {
          setUserData(snapshot.data());
          console.log(snapshot.data());
          console.log("snapshot.data()");
        }
      };

      fetchUserData();
    }
  }, [user]);

//   const [value, loading] = useDocument(
//     firebase.firestore().collection("users").doc(user?.uid),
//     {
//       snapshotListenOptions: { includeMetadataChanges: true }
//     }
//   );
  

//   useEffect(() => {
//     if (value) {
//       setUserData(value.data());
//     } else return null;
//   }, [value]);

  useEffect(() => {
    UpdateLocalState();
  }, [userData]);

  const UpdateLocalState = () => {
    if (userData) {
      setName(userData.name);
      setEmail(userData.email);
      setCompanyName(userData.companyName);
      setCompanyABN(userData.companyABN);
      setCompanyPosition(userData.companyPosition);
      setPhoneNumber(userData.phoneNumber);
      setLocations(userData.locations);
      setCustomerType(userData.customerType);
    }
  };


  return (
    <UserContext.Provider
      value={{
        userData
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
