import React from "react";
import TextField from "@mui/material/TextField";

import styled from "styled-components";

const StyledInput = styled(TextField)`
  margin: ${(props) => props.margin};
  .MuiFormLabel-root {
    color: ${(props) => props.theme.grey900};
    font-size: 14px;
    font-weight: 500;
    text-transform: none;
  }
  .MuiOutlinedInput-root {
    border-radius: 0px;
  }
  .Mui-disabled {
    /* color: ${(props) => props.theme.textDark}; */
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.primary} !important;
  }
  .MuiFormHelperText-root {
    margin: 3px 0 -8px 0;
  }
`;

export default function InputBoxedSelect(props) {
  return (
    <StyledInput
      select
      label={props.label}
      placeholder={props.placeholder}
      variant="outlined"
      id={props.id}
      className="boxed-input"
      name={props.name}
      autoComplete="off"
      type={props.type ? props.type : null}
      value={props.value || ""} //This || is used as a solution for a problem where label overlaps value text
      onChange={props.onChange}
      SelectProps={{
        native: true
      }}
      error={props.error}
      helperText={props.helperText}
      margin={props.margin ? props.margin : "16px 0 0 0"}
      disabled={props.disabled ? "disabled" : null}
      multiline={props.multiline ? "multiline" : null}
      rows={props.rows ? props.rows : null}
    >
      {props.options
        ? props.options.map((option, index) => (
            <option key={option.value} value={index}>
              {option.label}
            </option>
          ))
        : null}
    </StyledInput>
  );
}
