import React, { useEffect, useState } from "react";
import { useRouteMatch, useHistory, useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { motion } from "framer-motion";
import { pageVariantsTopLevel, pageTransition } from "../transitions";

import styled from "styled-components";

const PageContainer = styled.div`
position: relative;
display: flex;
flex-direction: column;
width: 100%;
height: 100%;
text-align: left;
font-family: ${(props) => props.theme.fontPrimary};
color: ${(props) => props.theme.textDark};
/* background-color: ${(props) => props.theme.backgroundGrey50}; */
background-color: ${(props) => props.theme.backgroundGrey50};
transition: ${(props) => props.theme.allTransition};
.header-image-container{
  // height: 220px;
  height: 220px;
  background: black;
}
.terms-link{
  color: ${(props) => props.theme.primary};
}
.header-image{
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  opacity: 0.5;
}
.header-image-text{
  position: absolute;
  top: 0px;
  width: 100%;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.textLight};
}
.header-image-text h1{
  font-size: 40px;
  margin: 0 0 0px 0;
  transition: all 0.2s ease-out;
}
.header-image-text h2{
  font-size: 24px;
  transition: all 0.2s ease-out;
}
.products-and-filters{
  display: flex;
  justify-content: center;
  padding: 24px;
}
h2{
  white-space: nowrap;
}
.filters-and-license{
  /* position: -webkit-sticky; 
  position: sticky;
  top: 0; */
}
.filters-and-license-card{  
  position: relative;
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-width: 320px;
  margin: 0 0 16px 0;
  padding: 24px;
  background-color: ${(props) => props.theme.white};
  border-radius: 4px;
  box-shadow: ${(props) => props.theme.cardBoxShadow};
  }
  .license-p{
    margin:16px 0 0 0;
  }
  .read-more-button-container{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 8px 0 0 0;
    margin:0 0 -16px 0;
  }
  .read-more-button{
    position: relative;
    width: fit-content;
  }
`;

const TabsContainer = styled.div`
  & .MuiTabs-flexContainer {
    justify-content: center;
  }
  .MuiAppBar-root {
    background-color: ${(props) => props.theme.white};
    box-shadow: none;
  }
  .MuiTab-root {
    font-family: Titillium Web;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    color: ${(props) => props.theme.textDark};
  }
  .MuiTab-textColorInherit {
    opacity: 1;
  }
  .MuiTabs-indicator,
  .PrivateTabIndicator-root-1 {
    background-color: ${(props) => props.theme.primary};
    height: 3.5px;
  }
`;

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `category-${index}`
  };
}

function RouteMatch(props) {
  const [value, setValue] = useState(0);
  const [categoryState, setCategoryState] = useState("");
  const [showThisComponent, setShowThisComponent] = useState(true);
  let history = useHistory();
  let location = useLocation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const match = useRouteMatch({
    path: "/:category/:subcategory",
    strict: true,
    exact: true
  });

  const GetCategory = () => {
    const paths = location.pathname.split("/");
    const categoryTemp = paths[1];
    FormatPageTitle(categoryTemp);
    DetectNonCategoryPage(categoryTemp);
    return categoryTemp;
  };

  const FormatPageTitle = (categoryTemp) => {
    var string = categoryTemp;
    var string2 = string.replace("_", " ");
    const words = string2.split(" ");
    for (var i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].substring(1);
    }
    const capitalised = words.join(" ");
    setCategoryState(capitalised);
  };

  // below is logic to detect text in the url that would be used stop this header from rendering
  const DetectNonCategoryPage = (categoryTemp) => {
    var string = categoryTemp;
    var string2 = string.replace("-", " ");
    const words = string2.split(" ");
    if (words[0] === "hire") {setShowThisComponent(false)}
  }

  useEffect(() => {
    const category = GetCategory();
    switch (category) {
      case "telehandlers":
        setValue(0);
        break;
      case "boom_lifts":
        setValue(1);
        break;
      case "scissor_lifts":
        setValue(2);
        break;
      case "vertical_lifts":
        setValue(3);
        break;
      case "forklifts":
        setValue(4);
        break;
      default:
        setValue(0);
    }
    console.log(category);
  }, [location]);

  return (
    <PageContainer>
      {match && showThisComponent ? (
        <div>
          <div className="header-image-container">
            <img
              className="header-image"
              alt=""
              src="https://firebasestorage.googleapis.com/v0/b/gth-equipment.appspot.com/o/images%2FHeader%20Images%2FHaulotte-Telehandler-Hire-Medium.jpg?alt=media&token=39f0f4c5-2577-43ea-9b00-b58e8c7af1ab"
            />
            <motion.div
              className="header-image-text"
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariantsTopLevel}
              transition={pageTransition}
            >
              <h1 className="header-title">Hire {categoryState}</h1>
              {/* <h2>Up to <strong>50% off</strong> hire rates for new orders in January</h2>
              <h2>Quote the code "Jan24" when placing your order.</h2>
              <h3><a href="https://gth.com.au/january-2024-sale-terms-and-conditions/" className="terms-link">Terms and Conditions Apply.</a></h3> */}
              <h2>Get a competitive quote now!</h2>
            </motion.div>
          </div>
          <TabsContainer>
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="Documentation Tabs"
              >
                <Tab
                  label="Telehandlers"
                  aria-label="Telehandlers"
                  {...a11yProps(0)}
                  onClick={() => {
                    history.push("/telehandlers/all");
                  }}
                />
                <Tab
                  label="Boom Lifts"
                  aria-label="Boom Lifts"
                  {...a11yProps(1)}
                  onClick={() => {
                    history.push("/boom_lifts/all");
                  }}
                />
                <Tab
                  label="Scissor Lifts"
                  aria-label="Scissor Lifts"
                  {...a11yProps(2)}
                  onClick={() => {
                    history.push("/scissor_lifts/all");
                  }}
                />
                <Tab
                  label="Vertical Man Lifts"
                  aria-label="Vertical Man Lifts"
                  {...a11yProps(3)}
                  onClick={() => {
                    history.push("/vertical_lifts/all");
                  }}
                />
                <Tab
                  label="Forklifts"
                  aria-label="Forklifts"
                  {...a11yProps(4)}
                  onClick={() => {
                    history.push("/forklifts/all");
                  }}
                />
              </Tabs>
            </AppBar>
          </TabsContainer>
        </div>
      ) : null}
    </PageContainer>
  );
}

export default function SubCategoryPageHeader() {
  return RouteMatch();
}
