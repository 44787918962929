import React, {
  Fragment,
  useState,
  useRef,
  useContext,
  useEffect
} from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useOnClickOutside } from "./burgerMenu/useOnClickOutsideHook";
import { MainContext } from "../contexts/MainContext.js";
import IconButton from "@mui/material/IconButton";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import CTAButton from "./buttons/CTAButton";
import Burger from "./burgerMenu/Burger";
import SideMenu from "./burgerMenu/SideMenu";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import HideOnScroll from "./utilities/HideOnScroll";
import CartButton from "./buttons/CartButton";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import Button from "@mui/material/Button";
import { logout } from "../firebase";

import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { BiLogOut } from "react-icons/bi";
import { FaCaretDown, FaCaretRight } from "react-icons/fa";

import styled, { ThemeProvider, StyledEngineProvider } from "styled-components";
import { GlobalStyles } from "../global";
import { theme } from "../theme";
// import SettingsDrawer from "./SettingsDrawer";
import BackButton from "./buttons/BackButton";
import AccountButton from "./buttons/AccountButton";
import RevealPhoneNumberButton from "./buttons/RevealPhoneNumberButton";

const StyledAppBar = styled(AppBar)`
  position: relative;
  background-color: ${(props) => props.theme.backgroundLight};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  /* box-shadow: none|h-offset v-offset blur spread color */
  transition: ${(props) => props.theme.backgroundTransition};
  z-index: 800;
  .top-nav-container{
  height: 72px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  /* position: ${(props) =>
    props.location.pathname === "/" ? "fixed" : "relative"}; */
  /* h-offset v-offset blur spread color  */
  }
  .site-logo-container{
   display: flex;
   width: 100%;
   
    /* max-width: 285px; */
    }

  .site-logo {
    width: 265px;
    max-width: 265px;
    padding:10px; 
  } 
  .menu-icons-container{
    display: flex;
  }

  .nav-menu-item-container{
  }

  .nav-item{
    display: flex;
    /* height: 100%; */
    padding: 13px 20px;
    align-items: center;
    font-family: Titillium Web;
    font-style: normal;
    font-weight: 700; 
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;
    color: ${(props) => props.theme.textDark};
    cursor: pointer;

  }
  .expand-icon{
    margin: 10px 0 10px 10px;
  }
  .sub-nav-menu{
    position: absolute;
    margin:0;
    padding: 0 0 0 8px;
    z-index: 100;
    background-color: ${(props) => props.theme.backgroundLight};
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.2s linear;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1)
    }

  .nav-menu-item-container:hover > ul{
      visibility: visible;
      opacity: 1;
    }

  .sub-nav-menu li{
      list-style: none;
    }

  .sub-menu-item {
    height: 46px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 20px;
    color: ${(props) => props.theme.textDark};
    font-family: Titillium Web;
    font-style: normal;
    font-weight: 700; 
    font-size: 13px;
    line-height: 20px;
    white-space: nowrap;
    text-transform: uppercase;
    text-decoration: none;
    }

  .sub-menu-item:hover{
    color: ${(props) => props.theme.grey600};
    }
  
  .right-icon{
    margin: 10px 0 10px 10px;
  }

  .sub-sub-nav-menu{
    position: absolute;
    margin: -46px 0 0;
    margin-left: calc(100% - 8px);
    padding: 0 0 0 8px;
    z-index: 100;
    background-color: ${(props) => props.theme.backgroundLight};
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.2s linear;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1)
    }

    .sub-menu-item-container:hover > ul{
      visibility: visible;
      opacity: 1;
    }
`;

export default function TopNavDesktopLandingPage(props) {
  const { toggleDrawer, setTopNavTitle } = useContext(MainContext);
  let location = useLocation();
  let history = useHistory();
  const [open, setOpen] = useState(false);
  const node = useRef();
  useOnClickOutside(node, () => setOpen(false));
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderAccountButton = () => {
    if (
      location.pathname !== "/account/signin" &&
      location.pathname !== "/account/signup" &&
      location.pathname !== "/account"
    ) {
      return <AccountButton />;
    } else {
      return null;
    }
  };

  return (
    <div>
      {/* <SettingsDrawer drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} /> */}
      {/* Toggle between Desktop/Tablet Nav and Mobile Nav */}
      <HideOnScroll direction="down">
        <StyledAppBar className="top-nav" position="static" location={location}>
          <div className="top-nav-container">
            <div
              className="site-logo-container"
              // onClick={() => history.push("/")}
              // href="https://gth.com.au"
              aria-label="GTH Equipment - Home"
            >
              <SVG
                className="site-logo"
                src="/svg/GTH-logo-w-text.svg"
                preserveAspectRatio="xMidYMid meet"
              />
            </div>
            <div className="nav-menu-item-container">
              <RevealPhoneNumberButton />
            </div>
          </div>
        </StyledAppBar>
      </HideOnScroll>
    </div>
  );
}

