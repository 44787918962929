import React from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import SVG from "react-inlinesvg";
import styled from "styled-components";

const StyledButton = styled(Button)`
  margin: 8px 0px;
  &.button-large {
    width: 100%;
    max-width: 380px;
    height: 48px;
    background-color: ${(props) => props.theme.white};
    opacity: 1;
    border-radius: 0px;
    border: 1px solid ${(props) => props.theme.grey900};
    box-shadow: none;
    margin: ${(props) => props.margin};
    padding: 12px 24px;
    font-family: ${(props) => props.theme.fontPrimary};
    font-size: ${(props) => props.theme.buttonFontSize};
    font-weight: 700;
    color: ${(props) => props.theme.grey900};
    transition: 0.2s all ease-out;
  }
  &:hover {
    opacity: 0.6;
  }
`;

const StyledSVG = styled(SVG)`
  width: 100%;
  max-width: 24px;
  height: auto;
  fill: ${(props) => props.theme.textDark};
  transition: ${(props) => props.theme.allTransition};
`;

export default function ButtonGoogle(props) {
  return (
    <StyledButton
      className="button-large"
      variant="contained"
      onClick={props.onClick}
      component={props.linkTo ? Link : "null"}
      to={props.linkTo}
      margin={props.margin ? props.margin : "16px 0 0 0"}
      startIcon={
        <StyledSVG
          src="/svg/google-logo.svg"
          preserveAspectRatio="xMidYMid meet"
        />
      }
    >
      {props.text}
    </StyledButton>
  );
}
