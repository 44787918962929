import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { MainContext } from "../../contexts/MainContext";
import {
  Typography,
  Paper,
  Avatar,
  Button,
  FormControl,
  Input,
  InputLabel
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import ButtonLarge from "../buttons/ButtonLarge";
import ButtonGoogle from "../buttons/GoogleButton";
import withStyles from '@mui/styles/withStyles';
import { Link, withRouter } from "react-router-dom";
import InputBoxed from "../InputBoxed";
import TextField from "@mui/material/TextField";
import ToggleLoginSignupDrawer from "../buttons/ToggleLoginSignupDrawer";

import firebase, { login, auth, firestoreDB } from "../../firebase";

import styled from "styled-components";

const SignInContainer = styled.div`
  .form-container {
    display: flex;
    max-width: 380px;
    width: 100%;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .MuiFormControl-root {
    width: 100%;
    max-width: 380px;
  }
  .body-text {
    font-size: 14px;
    font-weight: 600;
    font-family: ${(props) => props.theme.fontPrimary};
    color: ${(props) => props.theme.textDark};
    margin: 8px 0 0 0;
    background-color: ${(props) => props.theme.backgroundLight};
  }
  .forgot-link-cont a {
    text-decoration: none;
  }
  .forgot-link-cont {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  .link-text {
    text-align: right;
    font-size: 14px;
    font-weight: 600;
    font-family: ${(props) => props.theme.fontPrimary};
    color: ${(props) => props.theme.textDark};
    margin: 16px 0 0 0;
    background-color: ${(props) => props.theme.backgroundLight};
  }
  &.page-enter {
    opacity: 0;
    transform: translateX(100vw);
  }
  &.page-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  &.page-exit {
    opacity: 1;
    transform: translateX(0);
  }
  &.page-exit-active {
    opacity: 0;
    transform: translateX(-100vw);
  }
`;

function SignIn(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [error, setError] = useState("");
  const history = useHistory();
  const { Alert, isDesktop } = useContext(MainContext);

  const addUserToFirestore = async (result) => {
    console.log("add to firestore called");
    // console.log(result);

    if (!result.additionalUserInfo.isNewUser) return;

    const { displayName, email, photoURL, uid } = result.user;

    await firestoreDB
      .collection("users")
      .add({
        displayName,
        photoURL,
        id: uid,
        email
      })
      .then(() => {
        console.log("User added to database");
      })
      .catch((error) => {
        console.log("error adding user to the database", error);
      });
  };

  const googleLogin = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth
      .signInWithPopup(provider)
      .then((result) => {
        props.setOpen(false);

        // This gives you a Google Access Token. You can use it to access the Google API.
        //const token = result.credential.accessToken
        // The signed-in user info.
        // console.log(result)
        addUserToFirestore(result);
        // history.push("/account");
      })
      .catch((error) => {
        // Handle Errors here.
        //const errorCode = error.code
        //const errorMessage = error.message
        // The email of the user's account used.
        //const email = error.email
        // The firebase.auth.AuthCredential type that was used.
        //const credential = error.credential
        // ...
        console.log(error);
        console.log("failed");
      });
  };

  return (
    <SignInContainer isDesktop={isDesktop}>
      <h2 className="heading-text">Login or register to proceed:</h2>
      <ToggleLoginSignupDrawer
        active={props.active}
        setActive={props.setActive}
      />
      <form
        className="form-container"
        onSubmit={(e) => e.preventDefault() && false}
      >
        <FormControl required>
          <InputBoxed
            label="Email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="24px 0 0 0"
          />
        </FormControl>
        <FormControl required>
          <InputBoxed
            label="Password"
            id="password"
            name="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormControl>
        <div className="forgot-link-cont">
          <Link to="/account/resetpassword" color="inherit">
            <body className="link-text">Forgotten your password?</body>
          </Link>
        </div>
        <ButtonLarge
          text="Login"
          onClick={() => login()}
          margin=" 24px 0 0 0"
        />
        <body className="body-text">or</body>
        <ButtonGoogle
          text="Login with Google"
          onClick={() => googleLogin()}
          margin=" 8px 0 0 0"
        />
      </form>
    </SignInContainer>
  );

  async function login() {
    props.setOpen(false);
    try {
      // await login(email, password);
      await auth.signInWithEmailAndPassword(email, password);

      // history.push("/account");
    } catch (error) {
      Alert(error.message);
    }
  }
}

export default SignIn;
