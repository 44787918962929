import React, { useEffect, useState, useContext } from "react";
import { MainContext } from "../../contexts/MainContext";

import { useLocation } from "react-router-dom";
import { Slide, useScrollTrigger } from "@mui/material";
const HideOnScroll = (props) => {
  const { hiddenBottomNav, setHiddenBottomNav } = useContext(MainContext);
  const [combinedTrigger, setCombinedTrigger] = useState(false);
  const [bottomTrigger, setBottomTrigger] = useState(false);
  const trigger = useScrollTrigger();
  const location = useLocation();

  useEffect(() => {
    if (!trigger || bottomTrigger) {
      setCombinedTrigger(true);
      // setHiddenBottomNav(true);
    } else {
      setCombinedTrigger(false);
      // setHiddenBottomNav(false);
    }
  }, [trigger, bottomTrigger]);

  return (
    <Slide appear={false} direction={props.direction} in={combinedTrigger}>
      {props.children}
    </Slide>
  );
};

export default HideOnScroll;
