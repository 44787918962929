import React, { useState, useEffect } from "react";
import { createBrowserHistory } from "history";
import FixedCTA from "../components/buttons/FixedCTA";
import DarkModeSwitch from "../components/buttons/DarkModeSwitch.js";
import "../styles.css";
import styled from "styled-components";

const Page = styled.div`
  width: 100%;
  height: 100%;
  text-align: left;
  font-family: ${(props) => props.theme.fontPrimary};
  color: ${(props) => props.theme.textDark};
  /* background-color: ${(props) => props.theme.backgroundGrey50}; */
  background-color: ${(props) => props.theme.backgroundLight};
  transition: ${(props) => props.theme.allTransition};
`;

const PageContainer = styled(Page)`
  position: absolute;
  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 24px;
  }
  .li-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 28px;
  }
`;

export function Home() {
  const [counter, setCounter] = useState(0);
  return (
    <PageContainer>
      <div className="content">
        {/* <Site Logo and Account - or Site Logo and Hamburger/> */}
        <h1>React PWA Template</h1>
        <div>
          This is a boilerplate PWA template built with React.js, functionality
          includes:
          <ul>
            <li></li>
            <li></li>
            <li>{counter}</li>
            <li>
              <button onClick={() => setCounter(counter + 1)}>counter</button>
            </li>
            <li>
              <div className="li-container">
                Dark Mode <DarkModeSwitch margin="0 0 0 12px" />
              </div>
            </li>
          </ul>
          Built by Joshua Mamo
        </div>
      </div>
    </PageContainer>
  );
}

export function Page1() {
  return (
    <PageContainer>
      <div className="content">
        <h1>Page 1</h1>
        <h2>Add some content</h2>
        <FixedCTA text="Book Now" />
      </div>
    </PageContainer>
  );
}

export function Page2() {
  return (
    <PageContainer>
      <div className="content">
        <h1>Page 2</h1>
        <h2>Add some content</h2>
        <body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
          sapien diam, accumsan vel risus vel, fringilla mattis ligula.
          Pellentesque sit amet magna eu felis sodales vehicula. Aenean mollis
          sit amet eros non posuere. Phasellus tortor massa, aliquam nec metus
          eget, vehicula porta purus. Suspendisse tristique consequat lectus,
          vitae hendrerit risus facilisis eget. Class aptent taciti sociosqu ad
          litora torquent per conubia nostra, per inceptos himenaeos. Nulla eu
          gravida orci, sit amet aliquet purus. Duis eleifend nisi lacinia,
          bibendum lacus sit amet, dapibus urna. Aliquam a varius tellus. In
          elementum, felis sed vestibulum fringilla, nulla lectus lobortis est,
          interdum posuere dui mauris nec tortor. Proin ac tellus lacus.
          Maecenas quis lectus feugiat, convallis lacus vel, tempus dui. Aenean
          sit amet dui sed dui tincidunt eleifend ut ut quam. Suspendisse
          vehicula nulla eget mi tristique commodo. Nulla efficitur eu diam at
          ornare. Vestibulum ante ipsum primis in faucibus orci luctus et
          ultrices posuere cubilia curae; Morbi in mauris ut magna convallis
          malesuada. Suspendisse eleifend lacinia vulputate. Aenean luctus erat
          id eros consequat fringilla. Nunc scelerisque tortor vel quam
          fermentum sodales. Nulla bibendum ac arcu eu tempor. Duis gravida enim
          sit amet cursus varius. Nunc sed erat et neque imperdiet dictum non
          vitae urna. Cras in rutrum ex. Proin faucibus diam massa, eu semper
          lectus condimentum quis. Nunc vitae suscipit metus. Proin facilisis
          augue sit amet nibh dictum, vel semper sapien sodales. Aliquam sit
          amet tortor turpis. Fusce viverra, velit ut eleifend facilisis, justo
          turpis porta turpis, commodo faucibus eros purus eu tortor. In in
          ornare nibh, vel ornare mi. Aenean eget lectus venenatis, feugiat
          purus vulputate, ornare urna. Curabitur purus turpis, tincidunt nec
          sagittis in, feugiat at urna. Sed non ipsum egestas orci varius
          placerat. Donec fermentum semper consectetur. Vivamus pellentesque
          enim vel leo interdum vehicula. Curabitur eget diam vitae ex suscipit
          lobortis. Sed lobortis nisl in risus convallis vehicula vel non ex.
          Etiam lacinia mauris in nulla malesuada, et mollis est maximus. Donec
          nulla urna, rutrum id ipsum ut, varius malesuada lorem. Cras eu nunc
          scelerisque, bibendum ex eget, feugiat ante. Curabitur laoreet eros
          sed ipsum venenatis, quis aliquet urna pharetra. Integer nulla diam,
          semper id arcu eget, ultrices feugiat elit. Praesent aliquam ornare
          dui non malesuada. Nullam elementum lobortis convallis. Suspendisse
          semper malesuada nisi, sit amet pretium nibh. Sed a lobortis orci. Ut
          vel volutpat velit. Pellentesque nunc ligula, ullamcorper a ligula
          nec, scelerisque suscipit erat. Donec non blandit eros. Cras
          condimentum, odio et ullamcorper lobortis, mi purus interdum nunc, vel
          finibus lectus elit at metus. Fusce sagittis eleifend aliquet. Duis
          porttitor, lorem ac pulvinar gravida, nulla tellus auctor mauris,
          vitae posuere neque lectus quis enim. Aliquam fringilla ac purus in
          convallis. Class aptent taciti sociosqu ad litora torquent per conubia
          nostra, per inceptos himenaeos. Donec et turpis molestie, ultrices
          velit eget, aliquam eros. Etiam rutrum ultrices interdum. Class aptent
          taciti sociosqu ad litora torquent per conubia nostra, per inceptos
          himenaeos. Nulla varius arcu eu mi luctus maximus. Integer odio ipsum,
          iaculis vel auctor eu, sodales in velit. Aenean laoreet velit vitae
          sem tristique lobortis. Aliquam et odio quis dui feugiat aliquet vitae
          sed nunc.
        </body>
      </div>
    </PageContainer>
  );
}

export function Contact() {
  return (
    <PageContainer>
      <div className="content">
        <h1>Contact</h1>
        <h2>Contact Form</h2>
      </div>
    </PageContainer>
  );
}

export function Account() {
  return (
    <PageContainer>
      <div className="content">
        <h1>Account</h1>
        <h2>Account Details</h2>
      </div>
    </PageContainer>
  );
}
