import React, { useState, useEffect, useContext } from "react";
import { MainContext } from "../contexts/MainContext.js";
import {
  Switch,
  Route,
  Link,
  useLocation,
  useParams,
  useRouteMatch
} from "react-router-dom";
import EquipmentCatCard from "../components/EquipmentCatCard";
import { Element, scroller } from "react-scroll";

import { motion } from "framer-motion";
import { pageVariantsTopLevel, pageTransition } from "../transitions";

import { client, linkResolver, apiEndpoint } from "../prismic-configuration";
import Prismic from "prismic-javascript";

import Button from "@mui/material/Button";
import Spacer from "../components/Spacer";

import { Helmet } from "react-helmet";

import styled from "styled-components";
import "../styles.css";

const PageContainer = styled.div`
  width: 100%;
  text-align: left;
  font-family: ${(props) => props.theme.fontPrimary};
  color: ${(props) => props.theme.textDark};
  /* background-color: ${(props) => props.theme.backgroundGrey50}; */
  background-color: ${(props) => props.theme.backgroundGrey50};
  transition: ${(props) => props.theme.allTransition};
  .content {
    /* height: 100%;
    max-height: calc(100vh - 112px); */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 8px;
  }
  .li-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 28px;
  }
`;

export default function EquipmentHomePage() {
  const { productData, setProductData } = useContext(MainContext);
  const [parentExpanded, setParentExpanded] = React.useState(false);
  const [counter, setCounter] = useState(0);
  let { path, url } = useRouteMatch();

  const location = useLocation();

  //Hide BottomNav on certain pages
  // useEffect(() => {
  //   location.pathname == -"/" ? <Redirect to="/equipment" /> : null;
  // }, []);

  // const MatchFamilytoURL

  const FetchProductData = (cmsCustomType, subcategory) => {
    console.log(cmsCustomType, subcategory);
    if (subcategory != "All") {
      const fetchData = async () => {
        const response = await client.query(
          Prismic.Predicates.at(
            `my.${cmsCustomType}.sub_product_family`,
            `${subcategory}`
          )
        );
        if (response) {
          setProductData(response.results);
        }
      };
      fetchData();
      console.log(productData);
    } else {
      const fetchData = async () => {
        const response = await client.query(
          Prismic.Predicates.at("document.type", cmsCustomType)
        );
        if (response) {
          setProductData(response.results);
        }
      };
      fetchData();
      console.log(productData);
    }

    //   Prismic.Predicates.at("my.boom_lifts.sub_product_family", "Electric")

    // { orderings: "[my.telehandler.maximum_lift_capacity]" }
    //       [ Prismic.Predicates.at('document.type', 'blog_post'),
    //       Prismic.Predicates.at('document.tags', ['featured']) ]
  };

  // const FetchProductData = () => {
  //   const fetchData = async () => {
  //     const response = await client.query(
  //       Prismic.Predicates.at("my.boom_lifts.sub_product_family", "Diesel")
  //     );
  //     if (response) {
  //       setProductData(response.results);
  //     }
  //   };
  //   fetchData();
  //   console.log("productData" + productData);
  // };

  // useEffect((doc) => {
  //   const fetchData = async () => {
  //     const response = await client.query(
  //       Prismic.Predicates.at("document.type", "telehandler")
  //     );
  //     if (response) {
  //       setProductData(response.results);
  //     }
  //   };
  //   fetchData();
  // }, []);

  return (
    <motion.div
      className="page-animation-div"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariantsTopLevel}
      transition={pageTransition}
    >
      <Helmet>
        <title>{`Hire Telehandlers, Boom Lifts, Scissor Lifts and Forklifts | Sydney | GTH Equipment | App`}</title>
        <meta
          name="description"
          content={`Telehandler, Boom Lift, Scissor Lift, Vertical Man Lift and Forklift Hire Sydney`}
        />
      </Helmet>
      <PageContainer className="equipment-category-page">
        <div className="content">
          <Element name="telehandlers">
            <EquipmentCatCard
              title="Telehandlers"
              imgSrc="https://firebasestorage.googleapis.com/v0/b/gth-equipment.appspot.com/o/images%2FCategory%20Images%2FTelehandlers-Hire.jpg?alt=media&token=10197645-6c6b-44fc-a699-b4bb80c25c3f"
              imgAlt="telehandler"
              category="telehandlers"
              cmsCustomType="telehandlers"
              parentExpanded={parentExpanded}
              setParentExpanded={setParentExpanded}
              subcategories={[
                { name: "All", to: "all" },
                { name: "Diesel", to: "diesel" },
                // { name: "Optional Extras", to: "optional-extras" },
                {
                  name: "License Requirements",
                  to: "license-requirements"
                }
              ]}
              fetchProductData={FetchProductData}
              // onClick={() =>
              //   scroller.scrollTo("telehandlers", {
              //     duration: 250,
              //     delay: 0,
              //     smooth: "easeInOutQuart"
              //   })
              // }
            />
          </Element>
          <Element name="boom_lifts">
            <EquipmentCatCard
              title="Boom Lifts"
              imgSrc="https://firebasestorage.googleapis.com/v0/b/gth-equipment.appspot.com/o/images%2FCategory%20Images%2FBoom-Lifts-Hire.jpg?alt=media&token=11913b22-0d30-4396-84fa-be8fa9cd311a"
              imgAlt="boom lift"
              category="boom_lifts"
              cmsCustomType="boom_lifts"
              parentExpanded={parentExpanded}
              setParentExpanded={setParentExpanded}
              subcategories={[
                { name: "All", to: "all" },
                { name: "Diesel", to: "diesel" },
                { name: "Electric", to: "electric" },
                { name: "Hybrid", to: "hybrid" },
                { name: "Spider", to: "spider" },
                // { name: "Optional Extras", to: "optional-extras" },
                {
                  name: "License Requirements",
                  to: "license-requirements"
                }
              ]}
              fetchProductData={FetchProductData}
              // onClick={() =>
              //   scroller.scrollTo("boom_lifts", {
              //     duration: 250,
              //     delay: 0,
              //     smooth: "easeInOutQuart"
              //   })
              // }
            />
          </Element>
          <Element name="scissor_lifts">
            <EquipmentCatCard
              title="Scissor Lifts"
              imgSrc="https://firebasestorage.googleapis.com/v0/b/gth-equipment.appspot.com/o/images%2FCategory%20Images%2FScissor-Lifts-Hire.jpg?alt=media&token=38666f5d-cd77-47b1-8e98-7b143b69e68c"
              imgAlt="scissor lift"
              category="scissor_lifts"
              cmsCustomType="scissor_lifts"
              parentExpanded={parentExpanded}
              setParentExpanded={setParentExpanded}
              subcategories={[
                { name: "All", to: "all" },
                { name: "Diesel", to: "diesel" },
                { name: "Electric", to: "electric" },
                { name: "Hybrid", to: "hybrid" },
                { name: "Tracked", to: "tracked" },
                // { name: "Optional Extras", to: "optional-extras" },
                {
                  name: "License Requirements",
                  to: "license-requirements"
                }
              ]}
              fetchProductData={FetchProductData}
              onClick={() =>
                scroller.scrollTo("scissor_lifts", {
                  duration: 250,
                  delay: 0,
                  smooth: "easeInOutQuart"
                })
              }
            />
          </Element>
          <Element name="vertical_lifts">
            <EquipmentCatCard
              title="Vertical Lifts"
              imgSrc="https://firebasestorage.googleapis.com/v0/b/gth-equipment.appspot.com/o/images%2FCategory%20Images%2FVertical-Man-Lifts-Hire.jpg?alt=media&token=75a81967-84b2-4418-84a8-df5cc9e5ed05"
              imgAlt="vertical lift"
              category="vertical_lifts"
              cmsCustomType="vertical_lifts"
              parentExpanded={parentExpanded}
              setParentExpanded={setParentExpanded}
              sortByField="to be setup"
              subcategories={[
                { name: "All", to: "all" },
                { name: "Electric", to: "electric" },
                { name: "Push Around", to: "push-around" },
                // {
                //   name: "Optional Extras",
                //   to: "optional-extras"
                // },
                {
                  name: "License Requirements",
                  to: "license-requirements"
                }
              ]}
              fetchProductData={FetchProductData}
              onClick={() =>
                scroller.scrollTo("vertical_lifts", {
                  duration: 250,
                  delay: 0,
                  smooth: "easeInOutQuart"
                })
              }
            />
          </Element>
          <Element name="forklifts">
            <EquipmentCatCard
              title="Forklifts"
              imgSrc="https://firebasestorage.googleapis.com/v0/b/gth-equipment.appspot.com/o/images%2FCategory%20Images%2FForklifts-Hire.jpg?alt=media&token=577aabc8-8ac0-4c7a-b3c1-44b20934a20c"
              imgAlt="forklift"
              category="forklifts"
              cmsCustomType="forklifts"
              parentExpanded={parentExpanded}
              setParentExpanded={setParentExpanded}
              subcategories={[
                { name: "All", to: "all" },
                { name: "Diesel", to: "diesel" },
                { name: "Gas", to: "gas" },
                // {
                //   name: "Optional Extras",
                //   to: "optional-extras"
                // },
                {
                  name: "License Requirements",
                  to: "license-requirements"
                }
              ]}
              fetchProductData={FetchProductData}
              onClick={() =>
                scroller.scrollTo("forklifts", {
                  duration: 250,
                  delay: 0,
                  smooth: "easeInOutQuart"
                })
              }
            />
          </Element>
          {/* <div className="li-container">
            Dark Mode <DarkModeSwitch margin="0 0 0 12px" />
          </div>
          Built by Joshua Mamo */}
          <Spacer />
        </div>
      </PageContainer>
    </motion.div>
  );
}
