import React, { Fragment, useState, useEffect, useContext } from "react";
import { MainContext } from "../../contexts/MainContext";
import { Link, useHistory } from "react-router-dom";
import firebase, {
  logout,
  auth,
  updateDBEntry,
  removeLocationFromDB
} from "../../firebase";

import { BiLogOut } from "react-icons/bi";
import { FormControl } from "@mui/material";
import ButtonLarge from "../buttons/ButtonLarge";
import ButtonLargeInverted from "../buttons/ButtonLargeInverted";
import ButtonLargeSubmit from "../buttons/ButtonLargeSubmit";
import InputBoxed from "../InputBoxed";

import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddLocationDrawer from "./AddLocationDrawer";
import InfoIcon from "@mui/icons-material/Info";

import { useAuthState } from "react-firebase-hooks/auth";
import { useDocument } from "react-firebase-hooks/firestore";

import styled from "styled-components";
import { Update } from "@mui/icons-material";

const AccountDetailsContainer = styled.div`
  .form-container {
    display: flex;
    /* max-width: 380px; */
    width: 100%;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .logout-button {
    width: fit-content;
    align-self: center;
    position: relative;
    margin: 16px 0 0 0;
    font-size: 12px;
  }
  .MuiFormControl-root {
    width: 100%;
    max-width: 380px;
  }
  .body-text {
    font-size: 14px;
    font-weight: 600;
    font-family: ${(props) => props.theme.fontPrimary};
    color: ${(props) => props.theme.textDark};
    margin: 8px 0 0 0;
  }
  .link-text {
    text-align: right;
    font-size: 14px;
    font-weight: 600;
    font-family: ${(props) => props.theme.fontPrimary};
    color: ${(props) => props.theme.textDark};
    text-decoration: none;
    margin: 16px 0 0 0;
  }
  .forgot-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  .locations-heading {
    margin: 24px 0 0 0;
  }
  .address-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 24px 0 0 0;
  }
  .delete-icon-button {
    color: ${(props) => props.theme.textDark};
  }
  .add-location-button {
    margin: 8px 0 0 0;
  }
`;

export default function AccountDetails(props) {
  const { Alert } = useContext(MainContext);
  const [userData, setUserData] = useState("");
  const [addLocationOpen, setAddLocationOpen] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(""); //"", "submitting", "success" or "error"

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyABN, setCompanyABN] = useState("");
  const [companyPosition, setCompanyPosition] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [locations, setLocations] = useState("");

  const [user] = useAuthState(auth);
  const history = useHistory();

  const [value, loading, error] = useDocument(
    firebase.firestore().collection("users").doc(user.uid),
    {
      snapshotListenOptions: { includeMetadataChanges: true }
    }
  );

  function Logout() {
    logout();
    history.push("/account/signin");
  }

  useEffect(() => {
    if (value) {
      setUserData(value.data());
    } else return null;
  }, [value]);

  useEffect(() => {
    UpdateLocalState();
  }, [userData]);

  const UpdateLocalState = () => {
    if (userData) {
      setName(userData.name);
      setEmail(userData.email);
      setCompanyName(userData.companyName);
      setCompanyABN(userData.companyABN);
      setCompanyPosition(userData.companyPosition);
      setPhoneNumber(userData.phoneNumber);
      setLocations(userData.locations);
    }
  };

  // useEffect(() => {
  //   value ? setUserData(value.data()) : null;
  // }, [value]);

  // useEffect(() => {
  //   UpdateLocalState();
  // }, [userData]);

  // const UpdateLocalState = () => {
  //   setName(userData.name);
  //   setEmail(userData.email);
  //   setCompanyName(userData.companyName);
  //   setCompanyABN(userData.companyABN);
  //   setCompanyPosition(userData.companyPosition);
  //   setPhoneNumber(userData.phoneNumber);
  // };

  const SubmitDBEntry = () => {
    setSubmitStatus("submitting");
    const tempLocations = locations ? locations : "";
    updateDBEntry(
      name,
      email,
      companyName,
      companyABN,
      companyPosition,
      phoneNumber,
      tempLocations
    )
      .then(function () {
        setSubmitStatus("success");
        console.log("Document successfully written!");
      })
      .catch(function (error) {
        setSubmitStatus("error");
        Alert(error);
        console.error("Error writing document: ", error);
      });
  };

  return (
    <AccountDetailsContainer>
      {user ? (
        <div className="account-container">
          <h2>Edit your account details below:</h2>
          <div>
            {error && <strong>Error: {JSON.stringify(error)}</strong>}
            {loading && <span>Collection: Loading...</span>}
            {value && (
              <div className="form-container">
                <FormControl required>
                  <InputBoxed
                    label="Name"
                    id="name"
                    name="name"
                    type="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormControl>
                <FormControl required>
                  <InputBoxed
                    label="Contact Email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    margin="24px 0 0 0"
                  />
                </FormControl>
                <FormControl required>
                  <InputBoxed
                    label="Company Name"
                    id="company-name"
                    name="company-name"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </FormControl>
                <FormControl required>
                  <InputBoxed
                    label="Company ABN"
                    id="company-ABN"
                    name="company-ABN"
                    value={companyABN}
                    onChange={(e) => setCompanyABN(e.target.value)}
                  />
                </FormControl>
                <FormControl required>
                  <InputBoxed
                    label="Position in Company"
                    id="position"
                    name="position"
                    value={companyPosition}
                    onChange={(e) => setCompanyPosition(e.target.value)}
                    margin="24px 0 0 0"
                  />
                </FormControl>
                <FormControl required>
                  <InputBoxed
                    label="Mobile Number"
                    id="mobile-number"
                    name="mobile-number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </FormControl>
              </div>
            )}
          </div>
          <h2 className="locations-heading">My Locations</h2>
          <p>Addresses and other details about machine delivery locations.</p>
          {locations
            ? locations.map((location, i) => (
                <div className="address-item">
                  <InputBoxed
                    label={`Location #${i + 1}`}
                    id={`location-${i}`}
                    name={`location-${i}`}
                    value={`${location.streetAddress}, ${location.city}, ${location.postcode} `}
                    margin="0"
                    disabled={true}
                  />
                  <IconButton
                    className="delete-icon-button"
                    aria-label="delete-address"
                    onClick={() => removeLocationFromDB(location)}
                    size="large">
                    <DeleteForeverIcon />
                  </IconButton>
                </div>
              ))
            : null}

          <AddLocationDrawer
            open={addLocationOpen}
            setOpen={setAddLocationOpen}
          />

          <Button
            className="add-location-button"
            startIcon={<AddCircleOutlineIcon fontSize="inherit" />}
            onClick={() => setAddLocationOpen(true)}
          >
            Add Location
          </Button>

          <ButtonLargeSubmit
            text="Save Details"
            submitStatus={submitStatus}
            onClick={() => SubmitDBEntry()}
          />
          {/* <Button
            className="logout-button"
            startIcon={<BiLogOut />}
            onClick={Logout}
          >
            Logout
          </Button> */}
          <Button
            className="logout-button"
            startIcon={<BiLogOut />}
            onClick={() => Logout()}
          >
            Logout
          </Button>
          {user && user.uid === "yLBuwX0r2XfhenOOE6M5oynGsB13" ? (
            <div className="developer-options">
              <IconButton
                className="delete-icon-button"
                aria-label="dev-options"
                onClick={() => history.push("/about")}
                size="large">
                <InfoIcon />
              </IconButton>
            </div>
          ) : null}
        </div>
      ) : null}
    </AccountDetailsContainer>
  );
}
