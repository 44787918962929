import React from "react";
import { Link } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Button from "@mui/material/Button";

import styled from "styled-components";

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 480px;
  height: 48px;
  background-color: ${(props) =>
    props.varient === "primary" ? props.theme.primary : props.theme.grey900};
  border-radius: 0px;
  box-shadow: none;
  margin: ${(props) => props.margin};
  padding: 12px 24px;
  font-family: ${(props) => props.theme.fontPrimary};
  font-size: ${(props) => props.theme.buttonFontSize};
  font-weight: 700;
  color: ${(props) => props.theme.white};
  transition: 0.2s all ease-out;
  .MuiButton-label {
    text-decoration: none;
  }
  &:hover {
    background: ${(props) =>
      props.varient === "primary" ? props.theme.grey900 : props.theme.primary};
    box-shadow: none;
  }
  @media screen and (max-width: 767px) {
    font-size: ${(props) => props.theme.buttonFontSize};
    margin: ${(props) => props.margin};
  }
`;

export default function ButtonLargeSubmit(props) {
  const SubmitStatus = () => {
    // return null;
    //"", "submitting", "success" or "error"
    if (props.submitStatus === "") {
      return null;
    } else if (props.submitStatus === "submitting") {
      return (
        <svg
          className="svg-loader"
          version="1.1"
          id="loader-1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="24px"
          height="24px"
          viewBox="0 0 50 50"
        >
          <path
            fill="white"
            d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
          >
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="rotate"
              from="0 25 25"
              to="360 25 25"
              dur="0.6s"
              repeatCount="indefinite"
            />
          </path>
        </svg>
      );
    } else if (props.submitStatus === "success") {
      return <CheckIcon />;
    } else if (props.submitStatus === "error") {
      return <ErrorOutlineIcon />;
    } else return null;
  };

  return (
    <StyledButton
      className="button-large"
      variant="contained"
      onClick={props.onClick}
      component={props.linkTo ? Link : "null"}
      to={props.linkTo}
      margin={props.margin ? props.margin : "16px 0 0 0"}
      endIcon={<SubmitStatus />}
      varient={props.varient} //dark or primary
      // disabled={props.submitStatus === "submitting" || "success" ? true : false}
    >
      {props.text}
    </StyledButton>
  );
}
