import React, { useState, useEffect, useContext } from "react";
import { MainContext } from "../contexts/MainContext.js";
import ProductSingleCard from "./ProductSingleCard";
import OptionalExtraCard from "./OptionalExtraCard";
import Button from "@mui/material/Button";
import { Switch, Route, useParams, useRouteMatch } from "react-router-dom";
import useGetProducts from "./utilities/useGetProducts";
import CircularProgress from "@mui/material/CircularProgress";

import { client, linkResolver, apiEndpoint } from "../prismic-configuration";
import Prismic from "prismic-javascript";

import { motion } from "framer-motion";
import {
  pageVariantsTopLevel,
  pageTransition,
  listItem,
  container
} from "../transitions";

import styled from "styled-components";
import Spacer from "./Spacer.js";

const StyledDiv = styled.div`
  overflow-x: hidden;
  .content {
    height: calc(100vh - 112px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 24px;
  }
  .loading {
    height: ${(props) => (props.isDesktop ? "auto" : "calc(100vh - 112px)")};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 24px;
    background-color: ${(props) =>
      props.category === "optional_extras"
        ? props.theme.backgroundGrey50
        : props.theme.backgroundLight};
  }
  .MuiCircularProgress-root {
    margin: 0 8px;
    width: 24px !important;
    height: 24px !important;
  }
  .loader {
    color: ${(props) => props.theme.grey900};
  }
  .no-products {
    height: ${(props) =>
      props.category === "optional_extras" ? "auto" : "calc(100vh - 112px)"};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 24px;
    background-color: ${(props) =>
      props.category === "optional_extras"
        ? props.theme.backgroundGrey50
        : props.theme.backgroundLight};
  }
  .no-products h2 {
    margin: 0 0 16px;
  }
`;

export default function ProductsList(props) {
  let { path, url } = useRouteMatch();
  const { idCMS, setIdCMS, isDesktop, isLandingPage } = useContext(MainContext);

  const { isLoading, isError, data, error } = useGetProducts(
    props.category,
    props.subcategory,
    null
  );

  // useEffect(() => {
  //   if (props.category === "cart") {
  //   }
  // }, [category]);

  // console.log(useParams());

  return (
    <StyledDiv
      className="products-list"
      isDesktop={isDesktop}
      category={props.category}
    >
      {/* <button onClick={() => console.log(data)}>data</button>
      <button onClick={() => console.log(props.category)}>category</button>
      <button onClick={() => console.log(props.subcategory)}>
        subcategory
      </button> */}
      {/* <Button onClick={() => console.log(url)}>Log</Button> */}

      {isLoading ? (
        <div className="loading">
          <CircularProgress className="loader" />
          <h2>Loading</h2>
        </div>
      ) : isError ? (
        <div className="no-products">
          <h2>Coming Soon.</h2>
          <p>
            We are still populating the list of all available optional extras.
          </p>
          <p>
            To see any available optional extras for a given piece of equipment,
            please click "Add Extras" on the cart page.
          </p>
          <p>
            Alternatively call us on 1300 608 608 and we will find something to
            suit your needs.
          </p>
        </div>
      ) : props.category !== "optional_extras" && data.results_size > 0 ? (
        <motion.div variants={container} initial="hidden" animate="show">
          {data.results.map((item, i) => (
            <motion.div key={i} variants={listItem}>
              <ProductSingleCard doc={item} />
            </motion.div> 
          ))}
          <Spacer />
        </motion.div>
      ) : props.category === "optional_extras" && data.results_size < 0 ? (
        <div className="no-products">
          <h2>Coming Soon.</h2>
          <p>
            We are still populating the list of all available optional extras.
          </p>
          <p>
            To see any available optional extras for a given piece of equipment,
            please click "Add Extras" on the cart page.
          </p>
          <p>
            Alternatively call us on 1300 608 608 and we will find something to
            suit your needs.
          </p>
        </div>
      ) : props.category === "optional_extras" && data.results_size > 0 ? (
        <div>
          {data.results.map((item, i) => (
            <OptionalExtraCard
              doc={item}
              subcategory={props.subcategory}
              modelId={props.modelId}
              closeModal={props.closeModal}
            />
          ))}
          {isDesktop ? null : <Spacer />}
        </div>
      ) : props.subcategory === "license-requirements" ? (
        <div className="no-products">
          <h2>Coming Soon.</h2>
          <p>We are still building this section of the app.</p>
          <p>
            For information on the licensing requirements of a specific product,
            please refer to the relevant regulatory website for your state.
          </p>
          <p>
            Alternatively, call us on 1300 608 608 and we will find something to
            suit your needs.
          </p>
        </div>
      ) : (
        <div className="no-products">
          <h2>There are no products here at the moment.</h2>
          <p>
            Please try refreshing the page or browsing for something else to
            suit your needs.
          </p>
          <p>
            Alternatively call us on 1300 608 608 and we will find something to
            suit your needs.
          </p>
        </div>
      )}

      {/* {props.productDataQ
        ? props.productDataQ.map((item, i) => <ProductSingleCard doc={item} />)
        : null} */}
    </StyledDiv>
  );
}
