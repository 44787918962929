import React, { useState, useContext, useEffect } from "react";
import ReactDOMServer from "react-dom/server";
import { MainContext } from "../contexts/MainContext.js";
import { useCart } from "react-use-cart";
import useGetProducts from "../components/utilities/useGetProducts";
import {
  Link,
  Route,
  useParams,
  useRouteMatch,
  useLocation
} from "react-router-dom";
import parse from "html-react-parser";
import ShareButton from "../components/buttons/ShareButton";
import DocumentationTable from "../components/DocumentationTable";
import { Element, scroller } from "react-scroll";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OnCardButton from "../components/buttons/OnCardButton";
import Button from "@mui/material/Button";
import SpecificationsTable from "../components/SpecificationsTable.js";
import FixedCTA from "../components/buttons/FixedCTA";
import Spacer from "../components/Spacer";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import GetAppIcon from "@mui/icons-material/GetApp";
import EmailIcon from "@mui/icons-material/Email";

import { client, linkResolver, apiEndpoint } from "../prismic-configuration";
import Prismic from "prismic-javascript";

import { Helmet } from "react-helmet";

import styled from "styled-components";
import ButtonLarge from "../components/buttons/ButtonLarge.js";

const StyledProductPage = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  background-color: ${(props) => props.theme.backgroundLight};
  border-radius: 4px;
  box-shadow: ${(props) => props.theme.cardBoxShadow};
  & h1 {
    margin: 0 0 8px 0;
  }
  & h2 {
    margin: 0 0 10px 0;
  }
  .card-row1 {
    display: flex;
    padding: 0 8px;
  }
  .family-name {
    text-transform: capitalize;
  }
  .model-family {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 0 0 16px;
  }
  .product-image {
    height: auto;
    width: 100%;
  }
  .card-title {
    font-weight: 600;
    color: ${(props) => props.theme.textDark};
    text-transform: capitalize;
    height: fit-content;
  }
  .card-family {
  }
  .featured-specs {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0 8px;
  }
  .featured-spec {
    display: flex;
    flex-direction: column;
  }
  .description ul {
    padding-inline-start: 1rem;
  }
  .documentation-links {
    padding: 0;
    /* list-style: none; */
  }
  .documentation-links li a,
  .documentation-links li p {
    font-family: Titillium Web;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: ${(props) => props.theme.textDark};
    text-align: left;
    /* text-decoration: none; */
  }
  .MuiIconButton-root {
    color: ${(props) => props.theme.textDark};
  }
  .card-buttons {
  }
  .MuiListItem-gutters {
    padding: 8px 0;
  }
  .MuiListItemText-primary {
    font-family: Titillium Web;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: ${(props) => props.theme.textDark};
    text-align: left;
    text-transform: capitalize;
    height: fit-content;
  }
  .documentation-accordion {
    margin: 24px 0;
    padding: 0;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }
  .documentation-accordion .MuiAccordionDetails-root {
    padding: 0;
    display: flex;
    flex-direction: column;
  }
  .documentation-accordion .MuiButtonBase-root {
    padding: 0;
  }
  .MuiAccordion-root:before {
    content: "";
    opacity: 0;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.12);
  }
  .loading {
    height: calc(100vh - 112px);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 24px;
    background-color: ${(props) => props.theme.backgroundLight};
  }
  .MuiCircularProgress-root {
    margin: 0 8px;
    width: 24px !important;
    height: 24px !important;
  }
  .loader {
    color: ${(props) => props.theme.grey900};
  }
`;

export default function SingleProductPage(props) {
  const { currentProduct } = useContext(MainContext);
  const location = useLocation();
  const { category, subcategory, model } = useParams();
  let { path, url } = useRouteMatch();
  const { addItem } = useCart();

  const { isLoading, isError, data, error } = useGetProducts(
    category,
    subcategory,
    model,
    currentProduct
  );

  const AddToCart = (doc) => {
    window.dataLayer.push({ event: "add_to_quote_click" });
    console.log(doc);
    const cartItem = {
      id: doc.uid,
      idCMS: doc.id,
      type: doc.type,
      price: 0,
      data: doc.data,
      childOf: subcategory
    };
    addItem(cartItem);
  };

  return (
    <StyledProductPage className="product-card">
      {isLoading ? (
        <div className="loading">
          <CircularProgress className="loader" />
          <h2>Loading</h2>
        </div>
      ) : isError ? (
        <p>Error:{error}</p>
      ) : data.data ? (
        <div>
          <Helmet>
            <title>{`Hire ${data.data.manufacturer} - ${data.data.model[0].text} | ${data.data.product_family} | GTH Equipment`}</title>
          </Helmet>
          <img
            src={data.data.product_image.url}
            alt={model}
            className="product-image"
          />
          {/* <button onClick={() => console.log(data)}>log data</button> */}
          <h1>
            {data.data.manufacturer} {data.data.model[0].text}
          </h1>
          <h2 className="family-name">{data.data.product_family}</h2>
          <p className="description">
            {data.data.product_description[0]
              ? parse(data.data.product_description[0].text)
              : null}
          </p>
          <SpecificationsTable data={data.data} subcategory={data.type} />
          <ButtonLarge
            varient="primary"
            text="Add to Quote"
            onClick={() => AddToCart(data)}
            margin="24px 0 0 0"
          />
          {/* <button
            onClick={() => {
              console.log(data);
            }}
          >
            Log
          </button> */}
          <Accordion
            className="documentation-accordion"
            onClick={() =>
              scroller.scrollTo("documentation_table", {
                duration: 250,
                delay: 0,
                smooth: "easeInOutQuart"
              })
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <h2>Documentation</h2>
            </AccordionSummary>
            <AccordionDetails>
              <Element name="documentation_table">
                <DocumentationTable data={data.data} />
              </Element>
            </AccordionDetails>
          </Accordion>
          <Spacer />
          {/* <FixedCTA text="Add to Booking" onClick={() => AddToCart(data)} /> */}
        </div>
      ) : (
        <div>
          <h2>
            We could not find the product you are looking for, try browsing our
            Equipment section to find something suitable.
          </h2>
          <p>
            Please try refreshing the page or browsing for something else to
            suit your needs.
          </p>
          <p>
            Alternatively call us on 1300 608 608 and we will find something to
            suit your needs.
          </p>
        </div>
      )}
    </StyledProductPage>
  );
}
