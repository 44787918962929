import React, { useState, useContext, useEffect } from "react";
import { MainContext } from "../contexts/MainContext.js";
import { useCart } from "react-use-cart";

import { useRouteMatch, useHistory } from "react-router-dom";

import OnCardButton from "./buttons/OnCardButton";

import styled from "styled-components";

const StyledProductCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0px 8px 16px 8px;
  height: 320px;
  width: 320px;
  background-color: ${(props) => props.theme.backgroundLight};
  border-radius: 4px;
  box-shadow: ${(props) => props.theme.cardBoxShadow};
  @media screen and (max-width: 1400px) {
    height: 310px;
    width: 310px;
  }
  .card-row1 {
    display: flex;
    justify-content: center;
  }
  .model-family {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 0 0 0px;
    padding: 0 16px 8px;
  }
  .product-image {
    height: auto;
    max-height: 160px;
    padding: 16px;
  }
  .card-model {
    font-weight: 600;
    color: ${(props) => props.theme.textDark};
    text-transform: capitalize;
    height: fit-content;
  }
  .card-manufacturer {
  }
  .oe-description {
    padding: 8px 16px;
    /* max-height: 100px; */
    text-overflow: ellipsis;
    height: 80px;
  }
  .featured-specs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 16px;
  }
  .featured-spec {
    display: flex;
    flex-direction: column;
  }
  .MuiIconButton-root {
    color: ${(props) => props.theme.textDark};
  }
  .card-buttons {
  }
  .MuiListItem-gutters {
    padding: 8px 0;
  }
  .MuiListItemText-primary {
    font-family: Titillium Web;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: ${(props) => props.theme.textDark};
    text-align: left;
    text-transform: capitalize;
    height: fit-content;
  }
  .key-spec-title {
    font-size: 0.75rem;
    font-weight: 400;
  }
  .key-spec-value {
    font-weight: 600;
  }
`;

export default function ProductSingleCard({ doc, subcategory }) {
  let history = useHistory();
  const { items, addItem } = useCart();

  const { url, path } = useRouteMatch();

  const AddToCart = (doc) => {
    const cartItem = {
      id: doc.uid,
      // linkId: doc.model,
      type: doc.type,
      price: 0,
      data: doc.data,
      childOf: subcategory //due to the nested routes of react router, subcategory here is actually the model
    };
    addItem(cartItem);
  };

  const TruncateString = function (str, length, ending) {
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  };

  return (
    <StyledProductCard className="product-card">
      <div className="card-row1">
        <img
          className="product-image"
          src={doc.data.product_image.url}
          alt={doc.data.product_title[0].text}
        />
      </div>
      <div className="model-family">
        <h2 className="card-title">{doc.data.product_title[0].text}</h2>
        <p className="card-family">{doc.data.product_family}</p>
      </div>
      <p className="oe-description">
        {doc.data.product_description[0]
          ? TruncateString(doc.data.product_description[0].text, 100)
          : null}
      </p>
      {/* <OnCardButton text="Log" onClick={() => console.log(doc.data)} /> */}
      <div className="card-buttons">
        <OnCardButton
          text="Specifications"
          linkTo={`/optional_extras/${subcategory}/${doc.uid}`}
        />
        <OnCardButton
          type="AddToCart"
          doc={doc}
          items={items}
          isCTA={true}
          onClick={() => AddToCart(doc)}
        />
        {/* <OnCardButton
          text="Log"
          isCTA={true}
          onClick={() => console.log(subcategory)}
        /> */}
      </div>
    </StyledProductCard>
  );
}
