import React from "react";
import ReactDOMServer from "react-dom/server";
import dateFormat from "dateformat";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

import RequestQuoteEmail from "./emailTemplates/RequestQuoteEmail";
import RequestQuickQuoteEmail from "./emailTemplates/RequestQuickQuoteEmail";
import RequestOffHireEmail from "./emailTemplates/RequestOffHireEmail";

export const App = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

export const auth = firebase.auth();
export const firestoreDB = firebase.firestore();
export const storage = firebase.storage();
export default firebase;

export const login = (email, password) => {
  return auth.signInWithEmailAndPassword(email, password);
};

export const logout = () => {
  console.log("logged out");
  return auth.signOut();
};

export const register = async (name, email, password) => {
  console.log("register function called");
  await auth.createUserWithEmailAndPassword(email, password);
  return auth.currentUser.updateProfile({
    displayName: name,
  });
};

export const createUserMeta = (
  name,
  email,
  companyName,
  companyABN,
  companyPosition,
  phoneNumber,
  accountCreatedThroughGoogle
) => {
  const id = auth.currentUser.uid;

  if (!auth.currentUser) {
    return alert("Not authorized");
  }

  return firestoreDB.collection("users").doc(auth.currentUser.uid).set({
    name,
    email,
    id,
    companyName,
    companyABN,
    companyPosition,
    phoneNumber,
    accountCreatedThroughGoogle,
  });
};

//Add or update a users database entry

export const updateDBEntry = (
  name,
  email,
  companyName,
  companyABN,
  companyPosition,
  phoneNumber,
  locations
) => {
  if (!auth.currentUser) {
    return alert("Not authorized");
  }

  var docDataUpdate = {
    name: name,
    email: email,
    companyName: companyName,
    companyABN: companyABN,
    companyPosition: companyPosition,
    phoneNumber: phoneNumber,
    locations: locations,
  };
  console.log("updateDBEntry called");
  return firestoreDB
    .collection("users")
    .doc(auth.currentUser.uid)
    .set(docDataUpdate, { merge: true });
};

export const addLocationToDB = (location) => {
  if (!auth.currentUser) {
    return alert("Not authorized");
  }

  var docDataUpdate = {
    locations: location,
  };

  console.log("updateDBEntry called");
  return firestoreDB
    .collection("users")
    .doc(auth.currentUser.uid)
    .update({ locations: firebase.firestore.FieldValue.arrayUnion(location) });
};

export const removeLocationFromDB = (location) => {
  if (!auth.currentUser) {
    return alert("Not authorized");
  }

  var docDataUpdate = {
    locations: location,
  };

  console.log("updateDBEntry called");
  return firestoreDB
    .collection("users")
    .doc(auth.currentUser.uid)
    .update({ locations: firebase.firestore.FieldValue.arrayRemove(location) });
};

//must add cart data to booking entry
export const createNewBooking = (
  companyName,
  companyABN,
  purchaseOrder,
  contactName,
  contactPosition,
  contactPhone,
  contactEmail,
  deliveryLocation,
  deliveryInstructions,
  deliveryDate,
  deliveryTime,
  offHireDate,
  formattedCartContents
) => {
  if (!auth.currentUser) {
    return alert("Not authorized");
  }

  //call createBookingEmail to make a db entry to trigger Firebase Trigger Email
  //I should find a way to simply trigger Firebase Trigger Email using this function and data
  createBookingEmail(
    companyName,
    companyABN,
    purchaseOrder,
    contactName,
    contactPosition,
    contactPhone,
    contactEmail,
    deliveryLocation,
    deliveryInstructions,
    deliveryDate,
    deliveryTime,
    offHireDate,
    formattedCartContents
  );

  //using .add to have firebase generate an id
  return firestoreDB
    .collection("users")
    .doc(auth.currentUser.uid)
    .collection("bookings")
    .add({
      companyName,
      companyABN,
      purchaseOrder,
      contactName,
      contactPosition,
      contactPhone,
      contactEmail,
      deliveryLocation,
      deliveryInstructions,
      deliveryDate,
      deliveryTime,
      offHireDate,
      bookingContents: formattedCartContents,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
};

export const createQuickQuote = (
  companyName,
  contactName,
  contactPhone,
  contactEmail,
  deliveryPostcode,
  deliveryDate,
  offHireDate,
  formattedCartContents
) => {
  // if (!auth.currentUser) {
  //   return alert("Not authorized");
  // }

  //call createBookingEmail to make a db entry to trigger Firebase Trigger Email
  //I should find a way to simply trigger Firebase Trigger Email using this function and data
  createQuickQuoteEmail(
    companyName,
    contactName,
    contactPhone,
    contactEmail,
    deliveryPostcode,
    deliveryDate,
    offHireDate,
    formattedCartContents
  );

  //using .add to have firebase generate an id
  return firestoreDB.collection("quick-quotes").add({
    companyName,
    contactName,
    contactPhone,
    contactEmail,
    deliveryPostcode,
    deliveryDate,
    offHireDate,
    bookingContents: formattedCartContents,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  });
};

export const createOffHire = (
  companyName,
  contactName,
  contactMobile,
  pickupLocation,
  productFamily,
  assetNumber,
  pickupInstructions,
  requestedOffHireDate
) => {
  if (!auth.currentUser) {
    return alert("Not authorized");
  }

  createOffHireEmail(
    companyName,
    contactName,
    contactMobile,
    pickupLocation,
    productFamily,
    assetNumber,
    pickupInstructions,
    requestedOffHireDate
  );

  //using .aad to have firebase generate an id
  return firestoreDB
    .collection("users")
    .doc(auth.currentUser.uid)
    .collection("off-hires")
    .add({
      companyName,
      contactName,
      contactMobile,
      pickupLocation,
      productFamily,
      assetNumber,
      pickupInstructions,
      requestedOffHireDate,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
};

//-------- This function is used to create Booking email entries in Firestore to be used by Firebase Trigger Email extension
export const createBookingEmail = (
  companyName,
  companyABN,
  purchaseOrder,
  contactName,
  contactPosition,
  contactPhone,
  contactEmail,
  deliveryLocation,
  deliveryInstructions,
  deliveryDate,
  deliveryTime,
  offHireDate,
  formattedCartContents
) => {
  if (!auth.currentUser) {
    return alert("Not authorized");
  }

  const now = new Date();
  const formattedDateAndTime = dateFormat(
    now,
    "dddd, dS mmmm yyyy, h:MM:ss TT"
  );
  const formattedDeliveryDate = dateFormat(deliveryDate, "dS mmmm yyyy");
  const formattedOffHireDate = dateFormat(offHireDate, "dS mmmm yyyy");

  console.log(formattedDeliveryDate);

  const htmlContents = ReactDOMServer.renderToStaticMarkup(
    <RequestQuoteEmail
      companyName={companyName}
      companyABN={companyABN}
      purchaseOrder={purchaseOrder}
      contactName={contactName}
      contactPosition={contactPosition}
      contactPhone={contactPhone}
      contactEmail={contactEmail}
      deliveryLocation={deliveryLocation}
      deliveryInstructions={deliveryInstructions}
      deliveryDate={formattedDeliveryDate}
      deliveryTime={deliveryTime}
      offHireDate={formattedOffHireDate}
      formattedCartContents={formattedCartContents}
      formattedDateAndTime={formattedDateAndTime}
    />
  );

  return firestoreDB
    .collection("mail")
    .add({
      submittedAt: firebase.firestore.FieldValue.serverTimestamp(),
      to: ["joshmamo@gmail.com", "leads@gth.com.au"],
      message: {
        subject: `New Lead - ${contactName} - ${contactPhone} - ${dateFormat(
          now,
          "dddd, dS mmmm yyyy, h:MM:ss TT"
        )}`,
        html: `${htmlContents}`,
      },
    })
    .then(() => console.log("Queued email for delivery!"));
};

//-------- This function is used to create Quick Quote email entries in Firestore to be used by Firebase Trigger Email extension
export const createQuickQuoteEmail = (
  companyName,
  contactName,
  contactPhone,
  contactEmail,
  deliveryPostcode,
  deliveryDate,
  offHireDate,
  formattedCartContents
) => {
  // if (!auth.currentUser) {
  //   return alert("Not authorized");
  // }

  const now = new Date();
  const formattedDateAndTime = dateFormat(
    now,
    "dddd, dS mmmm yyyy, h:MM:ss TT"
  );
  const formattedDeliveryDate = dateFormat(deliveryDate, "dS mmmm yyyy");
  const formattedOffHireDate = dateFormat(offHireDate, "dS mmmm yyyy");

  console.log(formattedDeliveryDate);

  const htmlContents = ReactDOMServer.renderToStaticMarkup(
    <RequestQuickQuoteEmail
      companyName={companyName}
      contactName={contactName}
      contactPhone={contactPhone}
      contactEmail={contactEmail}
      deliveryPostcode={deliveryPostcode}
      deliveryDate={formattedDeliveryDate}
      offHireDate={formattedOffHireDate}
      formattedCartContents={formattedCartContents}
      formattedDateAndTime={formattedDateAndTime}
    />
  );

  return firestoreDB
    .collection("mail")
    .add({
      submittedAt: firebase.firestore.FieldValue.serverTimestamp(),
      to: ["joshmamo@gmail.com", "leads@gth.com.au"],
      message: {
        subject: `New Lead - ${contactName} - ${contactPhone} - ${dateFormat(
          now,
          "dddd, dS mmmm yyyy, h:MM:ss TT"
        )}`,
        html: `${htmlContents}`,
      },
    })
    .then(() => console.log("Queued email for delivery!"));
};

//---------- This function is used to create Off-Hire email entries in Firestore to be used by Firebase Trigger Email extension
export const createOffHireEmail = (
  companyName,
  contactName,
  contactMobile,
  pickupLocation,
  productFamily,
  assetNumber,
  pickupInstructions,
  requestedOffHireDate
) => {
  if (!auth.currentUser) {
    return alert("Not authorized");
  }

  const now = new Date();
  const formattedDateAndTime = dateFormat(
    now,
    "dddd, dS mmmm yyyy, h:MM:ss TT"
  );
  const formattedRequestedOffHireDate = dateFormat(
    requestedOffHireDate,
    "dS mmmm yyyy"
  );

  const htmlContents = ReactDOMServer.renderToStaticMarkup(
    <RequestOffHireEmail
      companyName={companyName}
      contactName={contactName}
      contactMobile={contactMobile}
      pickupLocation={pickupLocation}
      productFamily={productFamily}
      assetNumber={assetNumber}
      pickupInstructions={pickupInstructions}
      requestedOffHireDate={formattedRequestedOffHireDate}
      formattedDateAndTime={formattedDateAndTime}
    />
  );

  return firestoreDB
    .collection("mail")
    .add({
      to: ["joshmamo@gmail.com", "offhires@gth.com.au"],
      message: {
        subject: `Off-Hire Request - ${contactName} - ${contactMobile} - ${dateFormat(
          now,
          "dddd, dS mmmm yyyy, h:MM:ss TT"
        )}`,
        html: `${htmlContents}`,
      },
    })
    .then(() => console.log("Queued email for delivery!"));
};
