import React, {
  Fragment,
  useState,
  useRef,
  useContext,
  useEffect,
} from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useOnClickOutside } from "./burgerMenu/useOnClickOutsideHook";
import { MainContext } from "../contexts/MainContext.js";
import IconButton from "@mui/material/IconButton";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import CTAButton from "./buttons/CTAButton";
import Burger from "./burgerMenu/Burger";
import SideMenu from "./burgerMenu/SideMenu";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import HideOnScroll from "./utilities/HideOnScroll";
import CartButton from "./buttons/CartButton";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import Button from "@mui/material/Button";
import { logout } from "../firebase";

import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { BiLogOut } from "react-icons/bi";
import { FaCaretDown, FaCaretRight } from "react-icons/fa";

import { styled } from "@mui/material/styles";
// import styled from '@emotion/styled'

import { lightTheme } from "../theme";
// import SettingsDrawer from "./SettingsDrawer";
import BackButton from "./buttons/BackButton";
import AccountButton from "./buttons/AccountButton";

const StyledAppBar = styled(AppBar)(
  () => `
  position: fixed;
  background-color: ${lightTheme.backgroundLight} !important;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  /* box-shadow: none|h-offset v-offset blur spread color */
  transition: ${lightTheme.backgroundTransition};
  z-index: 800;
  .top-nav-container {
    height: 72px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    /* position: ${(props) =>
    props.location.pathname === "/" ? "fixed" : "relative"}; */
    /* h-offset v-offset blur spread color  */
  }
  .site-logo-container {
    display: flex;
    width: 100%;
    cursor: pointer;
    /* max-width: 285px; */
  }

  .site-logo {
    width: 265px;
    max-width: 265px;
    padding: 10px;
  }
  .menu-icons-container {
    display: flex;
  }

  .nav-menu-item-container {
  }

  .nav-item {
    display: flex;
    /* height: 100%; */
    padding: 13px 20px;
    align-items: center;
    font-family: Titillium Web;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;
    color: ${lightTheme.textDark};
    cursor: pointer;
  }
  .nav-item-phone {
    display: flex;
    /* height: 100%; */
    padding: 13px 20px;
    align-items: center;
    font-family: Titillium Web;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;
    color: ${lightTheme.textDark};
    cursor: pointer;
  }
  .expand-icon {
    margin: 10px 0 10px 10px;
  }
  .sub-nav-menu {
    position: absolute;
    margin: 0;
    padding: 0 0 0 8px;
    z-index: 100;
    background-color: ${lightTheme.backgroundLight};
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.2s linear;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  }

  .nav-menu-item-container:hover > ul {
    visibility: visible;
    opacity: 1;
  }

  .sub-nav-menu li {
    list-style: none;
  }

  .sub-menu-item {
    height: 46px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 20px;
    color: ${lightTheme.textDark};
    font-family: Titillium Web;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    white-space: nowrap;
    text-transform: uppercase;
    text-decoration: none;
  }

  .sub-menu-item:hover {
    color: ${lightTheme.grey600};
  }

  .right-icon {
    margin: 10px 0 10px 10px;
  }

  .sub-sub-nav-menu {
    position: absolute;
    margin: -46px 0 0;
    margin-left: calc(100% - 8px);
    padding: 0 0 0 8px;
    z-index: 100;
    background-color: ${lightTheme.backgroundLight};
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.2s linear;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  }

  .sub-menu-item-container:hover > ul {
    visibility: visible;
    opacity: 1;
  }
`,);

export default function TopNavDesktop(props) {
  const { toggleDrawer, setTopNavTitle } = useContext(MainContext);
  let location = useLocation();
  let history = useHistory();
  const [open, setOpen] = useState(false);
  const node = useRef();
  useOnClickOutside(node, () => setOpen(false));
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderAccountButton = () => {
    if (
      location.pathname !== "/account/signin" &&
      location.pathname !== "/account/signup" &&
      location.pathname !== "/account"
    ) {
      return <AccountButton />;
    } else {
      return null;
    }
  };

  return (
    <div>
      {/* <SettingsDrawer drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} /> */}
      {/* Toggle between Desktop/Tablet Nav and Mobile Nav */}
      {/* <HideOnScroll direction="down"> */}
      <StyledAppBar className="top-nav" position="fixed" location={location}>
        <div className="top-nav-container">
          <a
            className="site-logo-container"
            // onClick={() => history.push("/")}
            href="https://gth.com.au"
            aria-label="GTH Equipment - Home"
          >
            <SVG
              className="site-logo"
              src="/svg/GTH-logo-w-text.svg"
              preserveAspectRatio="xMidYMid meet"
            />
          </a>

          {/* Hire Equipment Menu Item with sub items */}

          <div className="nav-menu-item-container">
            {/* Hire Equipment - Menu Item and Sub Menu Items */}
            <span className="nav-item">
              Hire Equipment <FaCaretDown className="expand-icon" />
            </span>

            <ul className="sub-nav-menu">
              {/* Telehandlers - Menu Item and Sub Menu Items */}
              <li className="sub-menu-item-container">
                <Link className="sub-menu-item" to="/telehandlers/all">
                  Telehandlers
                </Link>
              </li>

              {/* Boom Lifts - Menu Item and Sub Menu Items */}
              <li className="sub-menu-item-container">
                <Link className="sub-menu-item" to="/boom_lifts/all">
                  Boom Lifts
                </Link>
              </li>

              {/* Scissor Lifts - Menu Item and Sub Menu Items */}
              <li className="sub-menu-item-container">
                <Link className="sub-menu-item" to="/scissor_lifts/all">
                  Scissor Lifts
                </Link>
              </li>

              {/* Vartical Man Lifts - Menu Item and Sub Menu Items */}
              <li className="sub-menu-item-container">
                <Link className="sub-menu-item" to="/vertical_lifts/all">
                  Vertical Man Lifts
                </Link>
              </li>

              {/* Forklifts - Menu Item and Sub Menu Items */}
              <li className="sub-menu-item-container">
                <Link className="sub-menu-item" to="/forklifts/all">
                  Forklifts
                </Link>
              </li>
            </ul>
          </div>

          {/* End of Hire Equipment Menu Item with sub items */}

          {/* Self Service Menu Item with sub items */}

          <div className="nav-menu-item-container">
            {/* Self Service - Menu Item and Sub Menu Items */}
            <span className="nav-item">
              Self Service<FaCaretDown className="expand-icon" />
            </span>

            <ul className="sub-nav-menu">
              {/* Off-Hire Request */}
              <li className="sub-menu-item-container">
                <Link className="sub-menu-item" to={{
                  pathname:
                    "https://gth.com.au/off-hire-request/",
                }}
                  target="_black"
                  rel="noreferrer"
                >
                  Off-Hire Request
                </Link>
              </li>

              {/* Stand Down Request */}
              <li className="sub-menu-item-container">
                <Link className="sub-menu-item" to={{
                  pathname:
                    "https://gth.com.au/stand-down-request/",
                }}
                  target="_black"
                  rel="noreferrer"
                >
                  Stand Down Request
                </Link>
              </li>

              {/* Credit Request */}
              <li className="sub-menu-item-container">
                <Link className="sub-menu-item" to={{
                  pathname:
                    "https://gth.com.au/customer-credit-request/",
                }}
                  target="_black"
                  rel="noreferrer"
                >
                  Credit Request
                </Link>
              </li>
            </ul>
          </div>

          {/* End of Self Service Menu Item with sub items */}

          <Link
            className="nav-item"
            to={{
              pathname:
                "https://www.machines4u.com.au/directory/GTH-Equipment/406/",
            }}
            target="_blank"
            rel="noreferrer"
          >
            Buy Equipment
          </Link>

          {/* Buy Equipment - Menu Item and Sub Menu Items */}
          {/* <div className="nav-menu-item-container">
              <span className="nav-item">
                Buy Equipment
                <FaCaretDown className="expand-icon" />
              </span>
              <ul className="sub-nav-menu">
                <li className="sub-menu-item-container">
                  <a
                    className="sub-menu-item"
                    href="https://gth.com.au/buy-new-equipment/"
                  >
                    New Equipment
                  </a>
                </li>
                <li className="sub-menu-item-container">
                  <a
                    className="sub-menu-item"
                    href="https://gth.com.au/buy-used-equipment/"
                  >
                    Used Equipment
                  </a>
                </li>
                <li className="sub-menu-item-container">
                  <a
                    className="sub-menu-item"
                    href="https://gth.com.au/buy-new-attachments/"
                  >
                    New Attachments
                  </a>
                </li>
                <li className="sub-menu-item-container">
                  <a
                    className="sub-menu-item"
                    href="https://gth.com.au/buy-used-attachments/"
                  >
                    Used Attachments
                  </a>
                </li>
              </ul>
            </div> */}
          {/* End Of - Buy equipment menu  */}

          {/* <a className="nav-item" href="https://gth.com.au/about/">
              About
            </a> */}
          <a className="nav-item" href="https://gth.com.au/contact-us/">
            Contact
          </a>
          <div className="menu-icons-container">
            <AccountButton />
            <CartButton />
          </div>
          {/* <a className="nav-item" href="https://gth.com.au/contact-us/">
            9056 9988
          </a> */}
          <a href="tel: 1300 608 608" id="text" className="nav-item-phone" >1300 608 608</a>
        </div>
      </StyledAppBar>
      {/* </HideOnScroll> */}
    </div>
  );
}

// import React from 'react';
// import { makeStyles } from '@mui/material/styles';
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
// import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
// import AccountCircle from '@mui/icons-material/AccountCircle';
// import Switch from '@mui/material/Switch';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import FormGroup from '@mui/material/FormGroup';
// import MenuItem from '@mui/material/MenuItem';
// import Menu from '@mui/material/Menu';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//   },
//   menuButton: {
//     marginRight: theme.spacing(2),
//   },
//   title: {
//     flexGrow: 1,
//   },
// }));

// export default function MenuAppBar() {
//   const classes = useStyles();
//   const [auth, setAuth] = React.useState(true);
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const open = Boolean(anchorEl);

//   const handleChange = (event) => {
//     setAuth(event.target.checked);
//   };

//   const handleMenu = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   return (
//     <div className={classes.root}>
//       <FormGroup>
//         <FormControlLabel
//           control={<Switch checked={auth} onChange={handleChange} aria-label="login switch" />}
//           label={auth ? 'Logout' : 'Login'}
//         />
//       </FormGroup>
//       <AppBar position="static">
//         <Toolbar>
//           <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
//             <MenuIcon />
//           </IconButton>
//           <Typography variant="h6" className={classes.title}>
//             Photos
//           </Typography>
//           {auth && (
//             <div>
//               <IconButton
//                 aria-label="account of current user"
//                 aria-controls="menu-appbar"
//                 aria-haspopup="true"
//                 onClick={handleMenu}
//                 color="inherit"
//               >
//                 <AccountCircle />
//               </IconButton>
//               <Menu
//                 id="menu-appbar"
//                 anchorEl={anchorEl}
//                 anchorOrigin={{
//                   vertical: 'top',
//                   horizontal: 'right',
//                 }}
//                 keepMounted
//                 transformOrigin={{
//                   vertical: 'top',
//                   horizontal: 'right',
//                 }}
//                 open={open}
//                 onClose={handleClose}
//               >
//                 <MenuItem onClick={handleClose}>Profile</MenuItem>
//                 <MenuItem onClick={handleClose}>My account</MenuItem>
//               </Menu>
//             </div>
//           )}
//         </Toolbar>
//       </AppBar>
//     </div>
//   );
// }
