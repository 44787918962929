import React from "react";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useCart } from "react-use-cart";

import styled from "styled-components";

const StyledCartIconButton = styled(IconButton)`
  color: ${(props) => props.theme.textDark};
  margin: 6px 10px 0 0;
  .MuiBadge-badge {
    background-color: ${(props) => props.theme.primary};
    color: white;
    z-index: 500;
  }
`;

export default function CartButton() {
  const { totalItems } = useCart();

  return (
    <StyledCartIconButton aria-label="Cart" component={Link} to="/cart">
      <Badge badgeContent={totalItems}>
        <ShoppingCartIcon />
      </Badge>
    </StyledCartIconButton>
  );
}
