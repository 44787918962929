import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import GetAppIcon from "@mui/icons-material/GetApp";
import EmailIcon from "@mui/icons-material/Email";
import ShareButton from "../components/buttons/ShareButton";

import styled from "styled-components";

const StyledTableContainer = styled(TableContainer)`
  .MuiTableCell-root {
    padding: 16px 0;
  }
  .table-field a {
    font-family: Titillium Web;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: ${(props) => props.theme.textDark};
  }
  .table-value {
    font-family: Titillium Web;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: ${(props) => props.theme.textDark};
  }
  .email-button {
    padding: 0 0 0 12px;
  }
`;

function createData(field, value) {
  return { field, value };
}

export default function DocumentationTable(props) {
  const [rows, setRows] = useState([]);
  //How am I going to map all the relevant fields, for all 5 families?
  // Maybe I just create rowsTelehandlers, rowsBoomLifts etc?

  const populateRows = () => {
    setRows([
      createData(
        "Brochure",
        props.data.brochure ? props.data.brochure.url : null
      ),
      createData(
        "Operator's Manual",
        props.data.operator_manual ? props.data.operator_manual.url : null
      ),
      createData(
        "Operator's Manual - Firebase",
        props.data.operator_manual_firebase
          ? props.data.operator_manual_firebase.url
          : null
      ),
      createData(
        "Range of Motion Chart",
        props.data.range_of_motion_chart
          ? props.data.range_of_motion_chart.url
          : null
      ),
      createData(
        "Range of Motion Chart - Firebase",
        props.data.range_of_motion_chart_url
          ? props.data.range_of_motion_chart_url.url
          : null
      ),
      createData(
        "Rated Capacity Chart",
        props.data.rated_capacity_chart
          ? props.data.rated_capacity_chart.url
          : null
      ),
      createData(
        "Design Registration",
        props.data.design_registration
          ? props.data.design_registration.url
          : null
      ),
      createData(
        "Risk Assessment (Manufacturer's)",
        props.data.risk_assessment ? props.data.risk_assessment.url : null
      ),
      createData(
        "Risk Assessment (GTH)",
        props.data.risk_assessment_gth
          ? props.data.risk_assessment_gth.url
          : null
      ),
      createData("SWMS", props.data.swms ? props.data.swms.url : null)
    ]);
  };

  useEffect(() => {
    populateRows();
  }, []);

  return (
    <StyledTableContainer className="specs-table">
      <Table aria-label="simple table">
        <TableBody>
          {rows.map((row) =>
            row.value ? (
              <TableRow key={row.field}>
                <TableCell className="table-field" component="th" scope="row">
                  <a target="_blank" rel="noopener noreferrer" href={row.value}>
                    {row.field}
                  </a>
                </TableCell>
                <TableCell className="table-value" align="right">
                  <div className="share-button-container">
                    <ShareButton
                      title={`${props.data.manufacturer} ${props.data.model[0].text} - ${row.field}`}
                      text={`${props.data.manufacturer} ${props.data.model[0].text} - ${row.field}`}
                      url={row.value}
                    />
                    <a
                      className="email-button"
                      href={`mailto:?subject=${props.data.manufacturer} ${props.data.model[0].text} - ${row.field}&body=View document at: ${row.value}`}
                      title="Share by Email"
                    >
                      <IconButton aria-label="Email Range of Motion Chart" size="large">
                        <EmailIcon fontSize="small" />
                      </IconButton>
                    </a>
                  </div>
                </TableCell>
              </TableRow>
            ) : null
          )}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
}
