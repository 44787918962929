import React, { useState, useEffect } from "react";
import firebase, { auth, createTestEmail } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDocument } from "react-firebase-hooks/firestore";
import { version } from "../version";

import packageJson from "../../package.json";
import { getBuildDate } from "../components/utilities/getBuildDate";
import dateFormat from "dateformat";

import InstallPWADev from "../components/InstallPWADev";
import InstallPWAButton from "../components/buttons/InstallPWAButton.js";
import DarkModeSwitch from "../components/buttons/DarkModeSwitch.js";
import Button from "@mui/material/Button";
import ButtonLargeSubmit from "../components/buttons/ButtonLargeSubmit";
import Spacer from "../components/Spacer";

import "../styles.css";
import styled from "styled-components";

const PageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: left;
  font-family: ${(props) => props.theme.fontPrimary};
  color: ${(props) => props.theme.textDark};
  /* background-color: ${(props) => props.theme.backgroundGrey50}; */
  background-color: ${(props) => props.theme.backgroundLight};
  transition: ${(props) => props.theme.allTransition};
  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 24px;
  }
  .li-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 28px;
  }
  .dev-button{
    margin: 8px;
  }
  .darkmode-container{
    display: flex;
    align-items: center;
    margin: 16px 0;
  }
  .content h1{
    margin: 0 0 16px 0;
  }
`;

export default function AboutThisAppPage() {
  const [user] = useAuthState(auth);
  const [submitStatus, setSubmitStatus] = useState(""); //"", "submitting", "success" or "error"

  const GetLocalDate = () => {
    var now = new Date();
    var formattedNow = dateFormat(now, "dddd, mmmm dS, yyyy, h:MM:ss TT");
    console.log(formattedNow);
  };

  return (
    <PageContainer>
      <div className="content">
        <h1>About This App</h1>
        <p>Build date: {getBuildDate(packageJson.buildDate)}</p>
        <p>Version: {version}</p>
        <p className="darkmode-container">
          DarkMode -
          <DarkModeSwitch />
        </p>
        <Spacer height="24px" />
        {user && user.uid === "yLBuwX0r2XfhenOOE6M5oynGsB13" ? (
          <div>
            <h2>Development Tests</h2>
            <InstallPWADev />
            <Button
              className="dev-button"
              variant="contained"
              onClick={() => console.log(user.uid)}
            >
              Log User.UID
            </Button>
            <ButtonLargeSubmit
              text="Test Button"
              submitStatus={submitStatus}
              onClick={() => GetLocalDate()}
            />
            {/* <ButtonLargeSubmit
              text="Test Button"
              submitStatus={submitStatus}
              onClick={() => console.log("button")}
            /> */}
            {/* <ButtonLargeSubmit
              text="Test Email - Booking"
              submitStatus={submitStatus}
              onClick={() => SubmitTestEmail("booking")}
            />
            <ButtonLargeSubmit
              text="Test Email - Off-Hire"
              submitStatus={submitStatus}
              onClick={() => SubmitTestEmail("offHire")}
            /> */}
          </div>
        ) : (
          <p>Sign in as admin to access this section of the app.</p>
        )}
      </div>
    </PageContainer>
  );
}
