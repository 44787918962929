import React, { useState, useContext, useEffect } from "react";
import { MainContext } from "../contexts/MainContext.js";
import { useCart } from "react-use-cart";

import {
  Link,
  Route,
  useParams,
  useRouteMatch,
  useHistory
} from "react-router-dom";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import QuantityControls from "./QuantityControl";

import styled from "styled-components";

const StyledCartItemCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  margin: 8px 8px 0;
  background-color: ${(props) => props.theme.backgroundLight};
  border-radius: 4px;
  box-shadow: ${(props) => props.theme.cardBoxShadow};
  .card-row1 {
    display: flex;
    padding: 0 8px;
  }
  .model-family {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 0 0 16px;
  }
  .image-container {
    height: 100%;
    max-height: 100px;
    width: 33%;
  }
  .product-image {
    height: auto;
    width: 100%;
    max-height: 100px;
  }
  .card-title {
    font-weight: 600;
    color: ${(props) => props.theme.textDark};
    text-transform: none;
    height: fit-content;
  }
  .card-family {
    text-transform: capitalize;
  }
  .remove-quantity {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 8px 4px;
  }
  .remove-button {
    font-family: ${(props) => props.theme.fontPrimary};
    font-weight: 700;
    font-size: 14px;
  }
  .featured-spec {
    display: flex;
    flex-direction: column;
  }
  .MuiIconButton-root {
    color: ${(props) => props.theme.textDark};
  }
  .product-cart.remove-quantity.MuiButton-label {
    font-family: ${(props) => props.theme.fontPrimary};
  }
  .add-extras-button-container {
    display: flex;
    justify-content: center;
    height: 40px;
  }
  .add-extras-button {
    width: 100%;
    font-family: ${(props) => props.theme.fontPrimary};
    font-weight: 700;
    font-size: 16px;
  }
  .MuiListItem-gutters {
    padding: 8px 0;
  }
  .MuiListItemText-primary {
    font-family: Titillium Web;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: ${(props) => props.theme.textDark};
    text-align: left;
    text-transform: capitalize;
    height: fit-content;
  }
`;

export default function CartItemCard({
  keySpec1,
  keySpec2,
  keySpec3,
  doc,
  item
}) {
  const { singleProduct, setSingleProduct, setOptionalExtrasFor } = useContext(
    MainContext
  );
  const {
    isEmpty,
    cartTotal,
    totalUniqueItems,
    items,
    updateItemQuantity,
    removeItem,
    emptyCart,
    addItem,
    totalItems
  } = useCart();
  const [extras, setExtras] = useState("");
  let history = useHistory();
  const { url, path } = useRouteMatch();

  const SetProduct = () => {
    setSingleProduct(doc);
    console.log(doc);
  };

  useEffect(() => {
    setExtras(items.map((i) => (i.linkId === item.id ? i : "")));
  }, []);

  const RemoveUnderscore = (str1) => {
    return [str1.replace("_", " ")];
  };

  const RemoveItemAndChildren = () => {
    removeItem(item.id);
    items.forEach((e) => {
      e.childOf === item.id ? removeItem(e.id) : removeItem(null);
    });
  };

  const CardClick = () => {
    item.type !== "optional_extras"
      ? history.push(`${item.type}/all/${item.id}`)
      : history.push(`${item.type}/${item.childOf}/${item.id}`);
  };

  return (
    <StyledCartItemCard className="product-card">
      <div className="card-row1" onClick={CardClick}>
        {/* <button onClick={() => console.log(item)}>log</button>
        <button onClick={() => console.log(`${item.type}/${item.id}`)}>
          test
        </button> */}
        {/* <button onClick={() => console.log(items)}>log</button> */}
        <div className="image-container">
          <img
            className="product-image"
            src={item.data.product_image.url}
            alt={item.data.product_image.imgAlt}
          />
        </div>
        <div className="model-family">
          <h2 className="card-title">
            {item.data.manufacturer} {item.data.model[0].text}
          </h2>
          <p className="card-family">
            {item.data.product_family
              ? item.data.product_family
              : RemoveUnderscore(item.type)}
          </p>
        </div>
      </div>
      <div className="remove-quantity">
        {/* <Button className="remove-button" onClick={() => console.log(items)}>
          log
        </Button> */}
        <Button className="remove-button" onClick={RemoveItemAndChildren}>
          Remove
        </Button>

        <QuantityControls
          quantity={item.quantity}
          item={item}
          updateItemQuantity={updateItemQuantity}
        />
      </div>
      {/* {item.type !== "optional_extras" ? (
        <div className="add-extras-button-container">
          <Button
            className="add-extras-button"
            startIcon={<AddCircleIcon fontSize="inherit" />}
            onClick={() => setOptionalExtrasFor(item.id)}
            component={Link}
            to={`/optional-extras/${item.id}`}
          >
            Add Extras
          </Button>
        </div>
      ) : null} */}
    </StyledCartItemCard>
  );
}
