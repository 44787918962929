import React, { useState, useContext, useEffect, useCallback } from "react";
import { MainContext } from "../contexts/MainContext.js";
import { useCart } from "react-use-cart";

import { useRouteMatch, useHistory } from "react-router-dom";

import OnCardButton from "./buttons/OnCardButton";
import OnCardButtonQuickQuote from "./buttons/OnCardButtonQuickQuote";


import styled from "styled-components";

const StyledProductCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0px 8px 16px 8px;
  height: 320px;
  width: 320px;
  background-color: ${(props) => props.theme.backgroundLight};
  border-radius: 4px;
  box-shadow: ${(props) => props.theme.cardBoxShadow};
  @media screen and (max-width: 1400px) {
    height: 310px;
    width: 310px;
  }
  .card-row1 {
    display: flex;
  }
  .model-family {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 0 0 0px;
    padding: 0 16px 8px;
  }
  .img-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .product-image {
    height: auto;
    max-height: 200px;
    padding: 16px;
  }
  .card-model {
    font-weight: 600;
    color: ${(props) => props.theme.textDark};
    text-transform: capitalize;
    height: fit-content;
  }
  .card-manufacturer {
  }
  .featured-specs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 16px;
  }
  .featured-spec {
    display: flex;
    flex-direction: column;
  }
  .MuiIconButton-root {
    color: ${(props) => props.theme.textDark};
  }
  .card-buttons {
  }
  .MuiListItem-gutters {
    padding: 8px 0;
  }
  .MuiListItemText-primary {
    font-family: Titillium Web;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: ${(props) => props.theme.textDark};
    text-align: left;
    text-transform: capitalize;
    height: fit-content;
  }
  .key-spec-title {
    font-size: 0.75rem;
    font-weight: 400;
  }
  .key-spec-value {
    font-weight: 600;
  }
`;

export default function ProductSingleCardDesktop({ doc, categoryQueryQQ}) {
  const { currentProduct, setCurrentProduct, isLandingPage } = useContext(MainContext);
  let history = useHistory();
  const { items, addItem, emptyCart } = useCart();
  const [keySpec1, setKeySpec1] = useState("");
  const [keySpec1Value, setKeySpec1Value] = useState("");
  const [keySpec2, setKeySpec2] = useState("");
  const [keySpec2Value, setKeySpec2Value] = useState("");
  const [keySpec3, setKeySpec3] = useState("");
  const [keySpec3Value, setKeySpec3Value] = useState("");

  // const [state, setState] = useState({
  //   imgSrc:
  //     "https://firebasestorage.googleapis.com/v0/b/gth-equipment.appspot.com/o/images%2FProduct%20Images%2FMerlo-P25-6.jpg?alt=media&token=d68b8579-507a-4a42-9bd5-6f631031d5fc",
  //   imgAlt: "merlo-p25.6",
  //   manufacturer: "Merlo",
  //   model: "P25.6",
  //   family: "Telehandler",
  //   keySpec1: "Lift Capacity:",
  //   keySpec1Value: "2,500kgs",
  //   keySpec2: "Lift Height:",
  //   keySpec2Value: "5.9m",
  //   keySpec3: "Overall Weight:",
  //   keySpec3Value: "4,700kgs",
  //   to: "/merlo-p25-6",
  //   bookNow: "cart"
  // });

  const ChooseKeySpecs = () => {
    switch (doc.data.product_family) {
      case "Telehandler":
        setKeySpec1("Lift Capacity");
        setKeySpec1Value(`${doc.data.maximum_lift_capacity}kgs`);
        setKeySpec2("Lift Height");
        setKeySpec2Value(`${doc.data.maximum_lift_height}m`);
        setKeySpec3("Overall Weight");
        setKeySpec3Value(`${doc.data.overall_weight_with_forks}kgs`);
        break;
      case "Boom Lifts":
        setKeySpec1("Platform Height");
        setKeySpec1Value(`${doc.data.platform_height}m`);
        setKeySpec2("Capacity");
        setKeySpec2Value(`${doc.data.platform_capacity}kgs`);
        setKeySpec3("Overall Weight");
        setKeySpec3Value(`${doc.data.overall_weight}kgs`);
        break;
      case "Scissor Lifts":
        //Max Platform Height, Max Platform Capacity, Weight
        setKeySpec1("Platform Height");
        setKeySpec1Value(`${doc.data.platform_height}m`);
        setKeySpec2("Capacity");
        setKeySpec2Value(`${doc.data.platform_capacity}kg`);
        setKeySpec3("Overall Weight");
        setKeySpec3Value(`${doc.data.overall_weight}kgs`);
        break;
      case "Vertical Lifts":
        //Max Platform Height, Max Platform Capacity, Weight
        setKeySpec1("Platform Height");
        setKeySpec1Value(`${doc.data.platform_height}m`);
        setKeySpec2("Capacity");
        setKeySpec2Value(`${doc.data.platform_capacity}kgs`);
        setKeySpec3("Overall Weight");
        setKeySpec3Value(`${doc.data.overall_weight}kgs`);
        break;
      case "Forklifts":
        //Max Platform Height, Max Platform Capacity, Weight
        setKeySpec1("Lift Capacity");
        setKeySpec1Value(`${doc.data.maximum_lift_capacity}kgs`);
        setKeySpec2("Lift Height");
        setKeySpec2Value(`${doc.data.maximum_lift_height}m`);
        setKeySpec3("Overall Weight");
        setKeySpec3Value(`${doc.data.overall_weight_with_forks}kgs`);
        break;
      default:
    }
  };

  useEffect(() => {
    ChooseKeySpecs();
  }, [doc]);

  const { url, path } = useRouteMatch();

  const AddToCart = (doc) => {
    const cartItem = {
      id: doc.uid,
      idCMS: doc.id,
      type: doc.type,
      price: 0,
      data: doc.data
    };
    window.dataLayer.push({ event: "add_to_quote_click" });
    addItem(cartItem);
  };

  const CardClicked = () => {
    console.log("card clicked");
    window.dataLayer.push({ event: "card_or_specifications_button_click" });
    if(!isLandingPage){
      setCurrentProduct(doc);
      history.push(`${url}/${doc.uid}`)}
  };

  const ClearAddNavigate = () => {
    emptyCart(); 
    AddToCart(doc);
    window.dataLayer.push({ event: "get_quote_click" });
    history.push("/quick-quote");
  };

  return (
    <StyledProductCard className="product-card">
      <div className="card-row1" onClick={CardClicked}>
        <div className="img-container">
          <img
            className="product-image"
            src={doc.data.product_image.url}
            alt={doc.data.product_title[0].text}
          />
        </div>
      </div>
      <div className="model-family">
        <h2 className="card-model">
          {doc.data.manufacturer} - {doc.data.model[0].text}
        </h2>
      </div>
      <div className="featured-specs" onClick={CardClicked}>
        <div className="featured-spec">
          <h2 className="key-spec-title">{keySpec1}</h2>
          <p className="key-spec-value">{keySpec1Value}</p>
        </div>
        <div className="featured-spec">
          <h2 className="key-spec-title">{keySpec2}</h2>
          <p className="key-spec-value">{keySpec2Value}</p>
        </div>
        <div className="featured-spec">
          <h2 className="key-spec-title">{keySpec3}</h2>
          <p className="key-spec-value">{keySpec3Value}</p>
        </div>
      </div>
      {/* <OnCardButton text="Log" onClick={() => console.log(doc)} />
      <OnCardButton text="Log cart" onClick={() => console.log(items)} /> */}

 <div className="card-buttons">
 {!categoryQueryQQ ?
        <OnCardButton
          text="Specifications"
          onClick={CardClicked}
          // linkTo={`${url}/${doc.uid}`}
        /> : null }
        {categoryQueryQQ ?
        <OnCardButtonQuickQuote
        text="Get Quote"
        linkTo="/cart"
        doc={doc}
        items={items}
        isCTA={true}
        onClick={() => ClearAddNavigate(doc)}
      /> :
        <OnCardButton
          type="AddToCart"
          doc={doc}
          items={items}
          isCTA={true}
          onClick={() => AddToCart(doc)}
        />}
      </div>
    </StyledProductCard>
  );
}
