import React from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import styled from "styled-components";

const StyledCartIconButton = styled(IconButton)`
  color: ${(props) => props.theme.textDark};
  margin: 0;
`;

export default function CloseButton(props) {
  return (
    <StyledCartIconButton aria-label="close" onClick={props.onClick}>
      <CloseIcon />
    </StyledCartIconButton>
  );
}
