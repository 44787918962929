import React, { useEffect, useState, useContext } from "react";
import { MainContext } from "../contexts/MainContext";
import { useSnackbar } from "react-simple-snackbar";
import { snackbarOptions, snackbarOptionsHigher } from "../theme";
import SVG from "react-inlinesvg";

import Badge from "@mui/material/Badge";
import Spacer from "../components/Spacer";
import IconButton from "@mui/material/IconButton";
import { FiShare } from "react-icons/fi";
import { ImSafari } from "react-icons/im";
import { CgAddR } from "react-icons/cg";
import ButtonLarge from "../components/buttons/ButtonLarge";
import FixedCTA from "../components/buttons/FixedCTA";
import { Helmet } from "react-helmet";

import copy from "copy-to-clipboard";

import {
  isIOS,
  isMobileSafari,
  isMobile,
  isAndroid,
} from "react-device-detect";

import styled, { createGlobalStyle } from "styled-components";

const OverrideGlobalStyle = createGlobalStyle`
  .App {
    background-color: ${(props) => props.theme.white};
    height: calc(100vh - 80px);
  }
  .bottom-nav{
    visibility: hidden;
  }
`;

const PageContainer = styled.div`
  text-align: ${(props) => (props.isMobile ? "left" : "center")};
  height: 100%;
  padding: 24px;
  font-family: ${(props) => props.theme.fontPrimary};
  color: ${(props) => props.theme.textDark};
  /* background-color: ${(props) => props.theme.backgroundGrey50}; */
  background-color: ${(props) => props.theme.backgroundLight};
  transition: ${(props) => props.theme.allTransition};
  p {
    font-size: 1rem;
    font-weight: 400;
  }
  ol {
    padding: 0 0 0 0px;
  }
  h1 {
    margin: ${(props) => (props.isMobile ? "0" : "16px 0 0 0")};
    font-size: ${(props) => (props.isMobile ? "1.5rem" : "2rem")};
  }
  h2 {
    margin: 24px 0 0 0;
    font-size: ${(props) => (props.isMobile ? "1rem" : "1.5rem")};
    font-weight: 600;
  }
  .logos-and-mockup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .ios-android-logo {
    margin: 24px 0 0 0;
    max-height: 90px;
  }
  .install-qr-code {
    margin: 24px 0 0 0;
    width: 200px;
    height: 200px;
  }
  .android-p {
    margin: 18px 0 0 0;
  }
  .list-item-container {
    display: flex;
    align-items: center;
    margin: 4px 0 0 0;
  }
  .list-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .list-icon {
    height: 20px;
    width: 20px;
    margin: 0 0 0 4px;
  }
  .icon-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .app-icon {
    height: 62px;
    width: 62px;
    border-radius: 50px;
  }
  .android-mockup {
    max-width: 400px;
  }
  .android-mockup-mobile {
    width: 100%;
  }
  .MuiBadge-colorPrimary {
    color: ${(props) => props.theme.white};
    background-color: ${(props) => props.theme.grey900};
    top: 50px;
    right: 2px;
  }
  .install-video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 24px 0 0 0;
  }
  .install-video {
    width: 80%;
  }
`;

export default function InstallPage() {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);
  const { hiddenBottomNav } = useContext(MainContext);
  const [openSnackbar] = useSnackbar(
    hiddenBottomNav === true ? snackbarOptions : snackbarOptionsHigher
  );

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      console.log("we are being triggered :D");
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = (evt) => {
    evt.preventDefault();
    if (!promptInstall) {
      if (!isMobile) {
        openSnackbar(
          "Please visit this site on your mobile phone to install the app."
        );
      } else if (isMobile && isIOS && !isMobileSafari) {
        copy("app.gth.com.au");
        openSnackbar(
          "Please visit this site in Safari browser to install the app. (URL has been copied to your clipboard)"
        );
      } else if (isMobile && isMobileSafari) {
        openSnackbar(
          `Open the Share Menu and then tap 'Add to Home Screen' to install the app.`
        );
      }
      return; //ends function here if !promptInstall
    } else if (!isMobile) {
      openSnackbar(
        "Please visit this site on your mobile phone to install the mobile app."
      );
    }
    promptInstall.prompt();
  };

  return (
    <PageContainer isAndroid={isAndroid} isMobile={isMobile}>
      <Helmet>
        <title>{`Install Our App | GTH Equipment`}</title>
      </Helmet>
      <OverrideGlobalStyle />
      <h1>Install our app to access exclusive discounts and benefits.</h1>

      {/* -----------  Desktop Page -----------  */}
      {!isMobile ? (
        <div>
          <h2>Scan the QR Code with your phone to get the app.</h2>
          <div className="qrcode-container">
          <img
              className="install-qr-code"
              src="https://firebasestorage.googleapis.com/v0/b/gth-equipment.appspot.com/o/qr-codes%2Finstall-app-qr-code.png?alt=media&token=2705ca44-3f10-4cf1-bcfc-74d2bbbc0f00"
              alt="Install App QR Code"
            />
          </div>
          <div className="logos-and-mockup">
            <SVG
              className="ios-android-logo"
              src="/svg/ios-android-logos.svg"
              preserveAspectRatio="xMidYMid meet"
            />
            <img
              className="android-mockup"
              src="https://firebasestorage.googleapis.com/v0/b/gth-equipment.appspot.com/o/images%2FDevice%20Mockups%2FGTH-App-Mockup-Android.jpg?alt=media&token=ca81dbc5-7dd3-4e60-b438-c23b2fb8fa54"
              alt="GTH App Icon"
            />
          </div>
        </div>
      ) : null}

      {/* -----------  Android Page -----------  */}
      {isAndroid ? (
        <div>
          <p className="android-p">
            Install our app to access exclusive discounts and benefits.
          </p>
          {/* <div className="icon-button-container">
          <IconButton
            className="icon-button-gth"
            aria-label="Install"
            onClick={onClick}
          >
            <Badge
              className="icon-badge"
              badgeContent={"Install"}
              color="primary"
              overlap="circle"
            >
              <img
                className="app-icon"
                src="https://firebasestorage.googleapis.com/v0/b/gth-equipment.appspot.com/o/icons%2Fapple-touch-icon.png?alt=media&token=78eb12fd-e2e4-4839-9432-44e90a120400"
                alt="GTH App Icon"
              />
            </Badge>
          </IconButton>
        </div> */}
          <img
            className="android-mockup-mobile"
            src="https://firebasestorage.googleapis.com/v0/b/gth-equipment.appspot.com/o/images%2FDevice%20Mockups%2FGTH-App-Install-Mockup-Android.jpg?alt=media&token=2608b2dd-bf4d-4d7d-87fe-7862ef5e069c"
            alt="GTH App Icon"
          />
          <FixedCTA
            text="Install App"
            className="install-button"
            aria-label="Install App"
            onClick={onClick}
            margin=" 8px 0 0 0"
          />
        </div>
      ) : null}

      {/* -----------  iOS Page -----------  */}
      {isIOS ? (
        <div>
          <p>
            <ol>
              <li className="list-item-container">
                1. Open this site in Safari -
                <span className="list-icon-container">
                  <ImSafari className="list-icon" />
                </span>
              </li>
              <li className="list-item-container">
                2. Open the "Share" menu -
                <span className="list-icon-container">
                  <FiShare className="list-icon" />
                </span>
              </li>
              <li className="list-item-container">
                3. Select “Add to Home Screen” -
                <span className="list-icon-container">
                  <CgAddR className="list-icon" />
                </span>
              </li>
            </ol>
          </p>
          <div className="install-video-container">
            <video
              autoPlay
              loop
              muted
              playsInline
              className="install-video"
              src="https://firebasestorage.googleapis.com/v0/b/gth-equipment.appspot.com/o/video%2FGTH%20App%20Install%20Video%20-%20Low.mp4?alt=media&token=b27b3fa4-fd04-41ea-988f-d16544a25f08"
            />
          </div>
          <Spacer />
        </div>
      ) : null}
    </PageContainer>
  );
}
