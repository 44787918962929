import React, { useState, useEffect, useContext, Fragment } from "react";
import {
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";
import { MainContext } from "../contexts/MainContext.js";
import SubCategoryPageDesktop from "./SubCategoryPageDesktop";
import SubCategoryLandingPage from "./SubCategoryLandingPage";
import ProductsList from "../components/ProductsList";
import ProductsListDesktop from "../components/ProductsListDesktop.js";
import SingleProductPage from "./SingleProductPage";
import SingleProductPageDesktop from "./SingleProductPageDesktop";

import { Helmet } from "react-helmet";

import Button from "@mui/material/Button";

import styled from "styled-components";

const PageContainer = styled.div`
  position: absolute;
  width: 100%;
  /* height: 100%; */
  text-align: left;
  font-family: ${(props) => props.theme.fontPrimary};
  color: ${(props) => props.theme.textDark};
  /* background-color: ${(props) => props.theme.backgroundGrey50}; */
  background-color: ${(props) => props.theme.backgroundGrey50};
  transition: ${(props) => props.theme.allTransition};
  .content {
    /* height: calc(100vh - 112px);  */
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .li-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 28px;
  }
`;

export default function SubCategoryPage(props) {
  let { path, url } = useRouteMatch();
  const { category, subcategory, model } = useParams();
  const { isDesktop } = useContext(MainContext);
  const [pageTitle, setPageTitle] = useState("");
  const [categoryQueryQQ, setCategoryQueryQQ] = useState("");

  const FormatPageTitle = () => {
    var string = category;
    var string2 = string.replace("_", " ");
    // console.log("Composed= " + string2);

    const words = string2.split(" ");

    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }

    const capitalised = words.join(" ");
    setPageTitle(capitalised);
  };

  const FormatCategoryQuery = () => {
    //this function breaks apart the url and processes it so that it returns a valid query for the Prismic API
    var string = category;
    var string2 = string.replaceAll("-", " ");
    const words = string2.split(" ");

    const checksforTwoWordQuery = ["boom", "scissor", "vertical"]
    const query = checksforTwoWordQuery.includes(words[1]) ? `${words[1]}_lifts`: words[1];
    setCategoryQueryQQ(query);
  };

  useEffect(() => {
    FormatPageTitle();
    FormatCategoryQuery();
  }, [category]);

  return (
    <PageContainer>
      <Helmet>
        <title>{`Hire ${pageTitle} | Sydney | GTH Equipment`}</title>
        <meta name="description" content={`${pageTitle} Hire Sydney`} />
      </Helmet>
      <div className="sub-category-page-content">
        <Route exact path={url}>
          <div>
            {/* <button onClick={() => console.log(path)}>log</button> */}
            {isDesktop ? (
              <Fragment>
                {/* <button onClick={() => console.log(category)}>Log category</button>
                <button onClick={() => console.log(categoryQuery)}>
                  Log Category Query
                </button> */}
                {/* The below function is used to query prismic with the correct category name when using a non-standard url, specifically, for the quick quote landing pages */}
                {categoryQueryQQ ?  <SubCategoryLandingPage
                  category={categoryQueryQQ}
                  subcategory={subcategory} 
                  categoryQueryQQ={categoryQueryQQ}
                /> : <SubCategoryPageDesktop
                category={category}
                subcategory={subcategory} 
              /> }
               
              </Fragment>
            ) : (
              <Fragment>
              {categoryQueryQQ ?  <ProductsList category={categoryQueryQQ} subcategory={subcategory} /> : <ProductsList
              category={category}
              subcategory={subcategory} 
            /> }
             </Fragment>
            )}
          </div>
          {/* <Button onClick={() => console.log(path)}>Path</Button>
          <Button onClick={() => console.log(url)}>URL</Button>
          <Button onClick={() => console.log(useRouteMatch)}>Match</Button>
          <Button onClick={() => console.log(props)}>Props</Button>
          <Button onClick={() => console.log(props.match.params)}>
            Params
          </Button> */}
        </Route>
        {
          <Route path="/:category/:subcategory/:model">
            {isDesktop ? <SingleProductPageDesktop /> : <SingleProductPage />}
          </Route>
        }
        {/* <Route path={["/:family/optional-extras"]}>
          <OptionalExtrasPage />
        </Route> */}
      </div>
    </PageContainer>
  );
}
