import React from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import styled from "styled-components";

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 380px;
  height: 48px;
  background-color: ${(props) => props.theme.white};
  border: 2px solid ${(props) => props.theme.grey900};
  border-radius: 0px;
  box-shadow: none;
  margin: ${(props) => props.margin};
  padding: 12px 24px;
  font-family: ${(props) => props.theme.fontPrimary};
  font-size: ${(props) => props.theme.buttonFontSize};
  font-weight: 700;
  color: ${(props) => props.theme.grey900};
  transition: 0.2s all ease-out;
  .MuiButton-label {
    text-decoration: none;
  }
  &:hover {
    color: ${(props) => props.theme.grey900};
    background: ${(props) => props.theme.grey200};
    box-shadow: none;
  }
  @media screen and (max-width: 767px) {
    font-size: ${(props) => props.theme.buttonFontSize};
    margin: ${(props) => props.margin};
  }
`;

export default function ButtonLargeInverted(props) {
  return (
    <StyledButton
      className="button-large"
      variant="contained"
      onClick={props.onClick}
      component={props.linkTo ? Link : "null"}
      to={props.linkTo}
      margin={props.margin ? props.margin : "16px 0 0 0"}
      endIcon={props.endIcon}
    >
      {props.text}
    </StyledButton>
  );
}
