import React, { useState, useRef, useContext, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useOnClickOutside } from "./burgerMenu/useOnClickOutsideHook";
import { MainContext } from "../contexts/MainContext.js";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import HideOnScroll from "./utilities/HideOnScroll";
import Button from "@mui/material/Button";
import { logout, auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { BiLogOut } from "react-icons/bi";
import useScrollPercentage from "./utilities/useScrollPercentage";

import { lightTheme } from "../theme";
import { styled } from "@mui/material/styles";
//import styled, { ThemeProvider, StyledEngineProvider } from "styled-components";
import { GlobalStyles } from "../global";
import { theme } from "../theme";
// import SettingsDrawer from "./SettingsDrawer";
import BackButton from "./buttons/BackButton";
import AccountButton from "./buttons/AccountButton";

const StyledMobileAppBar = styled(AppBar)(
  () => `
  position: fixed;
  display: flex;
  justify-content: center;
  height: 56px;
  width: 100vw;
  top: 0;
  background-color: ${lightTheme.backgroundLight};
  box-shadow: 0px 2px 4px #0000000c;
  overflow: visible;
  transition: ${lightTheme.backgroundTransition};
  .back-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .page-title-nav {
    color: ${lightTheme.textDark};
    font-size: 1rem;
    text-transform: capitalize;
  }
  .nav-item-phone {
    display: flex;
    /* height: 100%; */
    padding: 13px 20px;
    align-items: center;
    font-family: Titillium Web;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;
    color: ${lightTheme.textDark};
    cursor: pointer;
  }
  & .MuiTabs-flexContainer {
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
  & .PrivateTabIndicator-colorSecondary-66 {
    /* background-color: #2c2c28 !important; */
    transition: all 0.25s;
  }
  & .PrivateTabIndicator-colorSecondary-3 {
    color: ${lightTheme.white} !important;
  }
  .MuiTabs-indicator {
    display: none;
  }
  .MuiTab-root {
    background-color: none;
    color: ${lightTheme.textDark};
    transition: all 0.25s ease-out;
  }
  @media only screen and (max-width: 767px) {
    padding: 0px;
  }
  .site-logo-mobile {
    width: 100%;
    max-width: 160px;
    height: auto;
    text-transform: none;
    fill: ${lightTheme.textDark};
    transition: ${lightTheme.allTransition};
    transition: fill 0.25s ease-out;
  }
  .site-logo-mobile .st2 {
    fill: ${lightTheme.textDark};
  }
  .logout-button {
    width: fit-content;
    align-self: center;
    position: relative;
    margin: 8px;
    font-size: 12px;
    font-weight: 600;
  }
  `,);

export default function TopNavApp(props) {
  const { topNavTitle, setTopNavTitle, isLandingPage } = useContext(MainContext);
  const [user] = useAuthState(auth);
  let location = useLocation();
  let history = useHistory();
  const [open, setOpen] = useState(false);
  const node = useRef();
  useOnClickOutside(node, () => setOpen(false));
  const [value, setValue] = React.useState(0);
  const [scrollRef, scrollPercentage] = useScrollPercentage();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const RemoveDash = (str1, str2) => {
    console.log(str1, str2);
    let temp1 = [str1.replaceAll("-", " "), str2.replaceAll("-", " ")];
    console.log(temp1);
    let temp2 = [temp1[0].replaceAll("_", " "), temp1[1].replaceAll("_", " ")];
    console.log(temp2);
    let temp3 = [temp2[0].includes("qq") ? temp2[0].replace("qq", " ") : temp2[0], temp2[1]]
    return temp3;
  };

  // The below changes the TopNav Title dependent on the current url
  const ComposedTitle = (arr) => {
    //logic for urls with only one sub-path
    if (arr.length < 3) {
      return RemoveDash(arr[1], "");
    }
    //logic case #2 for urls with two sub-paths
    else if (arr[2] !== "") {
      console.log("case #2");
      var newArr = RemoveDash(arr[1], arr[2]);
      //Omit the subcategory if filter = license requirmemts as the 2 strings push the account button off the page
      if (newArr[1] === "license requirements") {
        return newArr[1];
      } else {
        return newArr[1] + " - " + newArr[0];
      }
      // return string.replace("-", " ");
    }
    //logic case #3 for urls with all as 2nd sub-path
    // else {
    //   console.log("case #3");
    //   newArr = RemoveDash(arr[1], "");
    //   return "All - " + newArr[0];
    //   // return string.replace("-", " ");
    // }
  };

  useEffect(() => {
    var string = location.pathname;
    var arr = string.split("/");
    console.log("arr =" + arr);
    var composed = ComposedTitle(arr);
    setTopNavTitle(composed);
  }, [location.pathname]);

  function Logout() {
    logout();
    history.push("/");
  }

  const renderAccountButton = () => {
    if (
      !isLandingPage && 
      location.pathname !== "/account/signin" &&
      location.pathname !== "/account/signup" &&
      location.pathname !== "/account"
    ) {
      return <AccountButton />;
    } else {
      return null;
    }
  };

  const renderLogoutButton = () => {
    if (location.pathname === "/account") {
      return (
        <Button
          className="logout-button"
          startIcon={<BiLogOut />}
          onClick={() => Logout()}
        >
          Logout
        </Button>
      );
    } else {
      return null;
    }
  };

  return (
    <div>
      {/* <SettingsDrawer drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} /> */}
      {/* Toggle between Desktop/Tablet Nav and Mobile Nav */}
      <HideOnScroll direction="down">
        <StyledMobileAppBar
          className="top-nav-mobile"
          position="static"
          location={location}
        >
          <Tabs
            className="navTabs"
            style={{ overflow: "visible" }}
            value={value}
            onChange={handleChange}
            aria-label="Top Navigation Bar"
          >
            {location.pathname === "/" ||
            location.pathname === "/equipment" ||
            location.pathname === "/install" ? (
              <Tab
                icon={
                  <SVG
                    className="site-logo-mobile"
                    src="/svg/GTH-logo.svg"
                    preserveAspectRatio="xMidYMid meet"
                  />
                }
                component={Link}
                to="/"
                aria-label="GTH Equipment - Home"
              />
            ) : (
              <div className="back-title-container">
                <BackButton />
                <h1 className="page-title-nav">{topNavTitle}</h1>
              </div>
            )}
            {/* <Burger aria-label="Mobile Menu" /> */}
            {/* <IconButton
                  aria-label="Account"
                  component={Link}
                  to="/telehandlers/react-query-test"
                >
                  <ImportExportIcon />
                </IconButton> */}
            {/* {renderAccountButton()}
            {user ? renderLogoutButton() : null} */}
            {/* <CartButton /> */}
            {/* <SideMenu menuItems={props.routes} /> */}
            <a href="tel: 1300 608 608" id="text" className="nav-item-phone" >1300 608 608</a>
          </Tabs>
        </StyledMobileAppBar>
      </HideOnScroll>
    </div>
  );
}
